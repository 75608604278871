import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_EMAIL_UPDATE = 'REQUEST_EMAIL_UPDATE'
export const doREQUEST_EMAIL_UPDATE = createDoRequestAction(REQUEST_EMAIL_UPDATE)

export const requestEmailUpdateActionCreators = {
  doREQUEST_EMAIL_UPDATE,
}

export function* requestEmailUpdate({payload}: any): SagaIterator {
  debug('saga*requestEmailUpdate')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/identity-email-update-request`,
    accessToken,
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)

    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        fromType: REQUEST_EMAIL_UPDATE,
        fromPayload: payload,
        url: '/v1/web/identity-email-update-request',
        ...result,
        error,
      }),
    )
  }

  return yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* requestEmailUpdateSaga() {
  yield takeEvery(REQUEST_EMAIL_UPDATE, requestEmailUpdate)
}
