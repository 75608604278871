import {
  API_URL,
  put,
  request,
  takeEvery,
  debug,
  createDoRequestAction,
  assertTotalSchema,
  v,
  SagaIterator,
  call,
  t,
} from '../../lib'
import {clearDb} from '../db'

import {
  doAUTH_SET,
  doPROFILE_SET,
  doSETTINGS_SET,
  doMFA_REQUIRED_SET,
  doREQUEST_ERROR,
  doREQUEST_COMPLETE,
  doPROJECT_SETTINGS_LIST_SET,
  doFAVORITE_LIST_SET,
} from '../state'

export const REQUEST_IDENTITY_AUTHENTICATE = 'REQUEST_IDENTITY_AUTHENTICATE'
export const doREQUEST_IDENTITY_AUTHENTICATE = createDoRequestAction(REQUEST_IDENTITY_AUTHENTICATE)

// TODO: auto load doRequest* from saga
export const requestIdentityAuthenticateActionCreators = {
  doREQUEST_IDENTITY_AUTHENTICATE,
}

export function* requestIdentityAuthenticate({payload}: any): SagaIterator {
  debug('saga*requestIdentityAuthenticate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        email: v
          .string()
          .email({tlds: {allow: false}})
          .exist(),
        password: v.string().exist(),
      }),
    })
  } catch (error) {
    debug('saga*requestIdentityAuthenticate validation error', error)
    return yield put(
      doREQUEST_ERROR({
        from: REQUEST_IDENTITY_AUTHENTICATE,
        requestId: payload.requestId,
        url: '/v1/web/identity-authenticate',
        error,
      }),
    )
  }

  const {email, password} = payload
  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/identity-authenticate`,
    data: {
      email,
      password,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not success')
    debug('saga*requestIdentityAuthenticate request error', error)

    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        fromType: REQUEST_IDENTITY_AUTHENTICATE,
        fromPayload: payload,
        url: '/v1/web/identity-authenticate',
        ...result,
      }),
    )
  }

  const {
    profile,
    settings,
    projectSettingsList,
    workspaceFavoriteList,
    accessToken,
    refreshToken,
    accessTokenExpiresInSeconds,
    refreshTokenExpiresInSeconds,
  } = result.data.payload
  const {type} = result

  if (type == 'MFA_REQUIRED') {
    yield put(
      doREQUEST_COMPLETE({
        requestId: payload.requestId,
        ...result,
      }),
    )
    return yield put(
      doMFA_REQUIRED_SET({
        requestId: payload.requestId,
        ...result?.data.payload,
      }),
    )
  }

  yield call(clearDb)

  yield put(
    doAUTH_SET({
      accessToken,
      refreshToken,
      accessTokenExpiresInSeconds,
      refreshTokenExpiresInSeconds,
    }),
  )

  yield put(doPROFILE_SET(profile || {}))
  yield put(doSETTINGS_SET(settings))
  yield put(doPROJECT_SETTINGS_LIST_SET(projectSettingsList))
  yield put(doFAVORITE_LIST_SET(workspaceFavoriteList))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* identityAuthenticateSaga() {
  yield takeEvery(REQUEST_IDENTITY_AUTHENTICATE, requestIdentityAuthenticate)
}
