import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {
  selectTheme,
  selectRequestComplete,
  createDispatchActions,
  selectDeveloperToken,
  DeveloperToken,
} from '../../store'

import {useTranslation, RIF} from '../../lib'

import {SaveBar, Button, Legend, ButtonReverse, CreateDeveloperTokenPopup, DeleteDeveloperTokenPopup} from '..'

interface Props {
  setDisplayDeveloperTokenPage: Dispatch<SetStateAction<boolean>>
}

export const DeveloperTokenPage = (props: Props) => {
  const {t} = useTranslation()
  const {color} = selectTheme()
  const {setDisplayDeveloperTokenPage} = props

  const developerTokenState: DeveloperToken[] = selectDeveloperToken()
  const {doREQUEST_DEVELOPER_TOKEN_DELETE, doREQUEST_DEVELOPER_TOKEN_LIST_FETCH}: any = createDispatchActions()

  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)
  const [requestDeveloperTokenDeleteId, setRequestDeveloperTokenDeleteId] = useState(null)
  const requestDeveloperTokenDeleteComplete = selectRequestComplete(requestDeveloperTokenDeleteId)

  const [displayCreateDeveloperTokenPopup, setDisplayCreateDeveloperTokenPopup] = useState(false)
  const [displayDeleteDeveloperTokenPopup, setDisplayDeleteDeveloperTokenPopup] = useState(false)
  const [deletingDeveloperToken, setDeletingDeveloperToken] = useState('')
  const [deletingDeveloperTokenName, setDeletingDeveloperTokenName] = useState('')
  const [developerTokenList, setDeveloperTokenList] = useState<DeveloperToken[]>([])
  const [secretCache, setSecretCache] = useState<string>('')

  useEffect(() => {
    if (developerTokenList.length) return
    doREQUEST_DEVELOPER_TOKEN_LIST_FETCH({
      setRequestId,
      payload: {
        page: 0,
      },
    })
  }, [])

  useEffect(() => {
    if (!requestComplete) return
    if (!requestComplete.success) return

    // if secretCache is not undefined means there is a new token
    if (secretCache) {
      const developerTokenListWithSecret = [...developerTokenState].map((developerToken: DeveloperToken) => {
        // if token is already in the list just return it
        const existedToken = developerTokenList.find((element: DeveloperToken) => element.id === developerToken.id)
        if (existedToken) return existedToken
        // if not, means the token is the latest token so attach the bearer token cache to it
        return {...developerToken, secret: secretCache}
      })
      setDeveloperTokenList([...developerTokenListWithSecret].reverse())
    } else {
      setDeveloperTokenList([...developerTokenState].reverse())
    }
    setSecretCache('')
  }, [requestComplete])

  useEffect(() => {
    if (!secretCache) return

    // fetch full list of developer token after create new token
    doREQUEST_DEVELOPER_TOKEN_LIST_FETCH({
      setRequestId,
      payload: {
        page: 0,
      },
    })
  }, [secretCache])

  useEffect(() => {
    if (!requestDeveloperTokenDeleteComplete) return
    if (!requestDeveloperTokenDeleteComplete.success) return
    setDisplayDeleteDeveloperTokenPopup(false)
    const deletedTokenId = deletingDeveloperToken
    const newDeveloperTokenList = [...developerTokenList].filter(
      (developerToken: DeveloperToken) => developerToken.id !== deletedTokenId,
    )
    setDeveloperTokenList(newDeveloperTokenList)
  }, [requestDeveloperTokenDeleteComplete])

  const deleteDeveloperToken = () => {
    doREQUEST_DEVELOPER_TOKEN_DELETE({
      setRequestId: setRequestDeveloperTokenDeleteId,
      payload: {
        developerTokenId: deletingDeveloperToken,
      },
    })
  }

  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        background: color.background,
        zIndex: 103,
        padding: '60px',
      }}
    >
      <SaveBar {...{closeAction: setDisplayDeveloperTokenPage, hideButton: true}} />
      <div css={{width: '46.4%', margin: '50px auto 0'}}>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '25px',
          }}
        >
          <Legend>Developer Keys</Legend>
          <Button children='Generate Key' onClick={() => setDisplayCreateDeveloperTokenPopup(true)} />
        </div>
        {developerTokenList.map((developerToken: DeveloperToken) => (
          <DeveloperTokenCard
            {...{
              key: developerToken.id,
              setDisplayDeleteDeveloperTokenPopup,
              setDeletingDeveloperToken,
              setDeletingDeveloperTokenName,
              developerToken,
            }}
          />
        ))}
      </div>

      {RIF(
        displayCreateDeveloperTokenPopup,
        <CreateDeveloperTokenPopup
          {...{
            setDisplayCreateDeveloperTokenPopup,
            setSecretCache,
          }}
        />,
      )}

      {RIF(
        displayDeleteDeveloperTokenPopup,
        <DeleteDeveloperTokenPopup
          {...{
            setDisplayDeleteDeveloperTokenPopup,
            deleteDeveloperToken,
            deletingDeveloperTokenName,
          }}
        />,
      )}
    </div>
  )
}

interface DeveloperTokenCardProps {
  setDisplayDeleteDeveloperTokenPopup: Dispatch<SetStateAction<boolean>>
  setDeletingDeveloperToken: Dispatch<SetStateAction<string>>
  setDeletingDeveloperTokenName: Dispatch<SetStateAction<string>>
  developerToken: DeveloperToken
  key: string
}

const DeveloperTokenCard = (props: DeveloperTokenCardProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {
    setDisplayDeleteDeveloperTokenPopup,
    developerToken,
    setDeletingDeveloperToken,
    setDeletingDeveloperTokenName,
  } = props

  const handleClickDeleteButton = () => {
    setDisplayDeleteDeveloperTokenPopup(true)
    setDeletingDeveloperToken(developerToken.id)
    setDeletingDeveloperTokenName(developerToken.name)
  }
  return (
    <div
      css={{
        width: '100%',
        padding: '30px',
        borderRadius: '5px',
        boxShadow: '0px 4px 12px rgba(213, 213, 213, 0.25)',
        marginBottom: '25px',
        background: color.white,
      }}
    >
      <p
        css={{
          fontSize: fontSize.h5,
          fontWeight: fontWeight.thick,
          marginBottom: '8px',
        }}
      >
        {developerToken.name}
      </p>
      <p
        css={{
          fontWeight: fontWeight.medium,
          marginBottom: '23px',
        }}
      >
        {developerToken.secret ? developerToken.secret : '[Hidden]'}
      </p>
      <ButtonReverse children='Delete Key' onClick={handleClickDeleteButton} />
    </div>
  )
}
