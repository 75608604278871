import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doMETHOD_UPDATE_TASK, doQUESTIONNAIRES_SET, doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_V2_TASK_QUESTIONNAIRE_UPDATE = 'REQUEST_V2_TASK_QUESTIONNAIRE_UPDATE'
export const doREQUEST_V2_TASK_QUESTIONNAIRE_UPDATE = createDoRequestAction(REQUEST_V2_TASK_QUESTIONNAIRE_UPDATE)

export const requestV2TaskQuestionnaireUpdateActionCreators = {
  doREQUEST_V2_TASK_QUESTIONNAIRE_UPDATE,
}

interface Payload {
  payload: {
    taskId: string
    requestId: string
    scheduleRepeat: boolean
    scheduleDescription: string
    color: string
    name: string
    description: string
    reminderList?: Record<string, string>[]
  }
}

export function* requestV2TaskQuestionnaireUpdate({payload}: Payload): SagaIterator {
  debug('saga*requestTaskQuestionnaireUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        taskId: v.string().uuid().exist(),
        requestId: v.string().uuid().exist(),
        scheduleRepeat: v.boolean().optional(),
        scheduleDescription: v.string().optional(),
        color: v.string().optional(),
        reminderList: v.array().optional(),
        name: v.string().optional(),
        description: v.string().optional()
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {
    scheduleRepeat,
    scheduleDescription,
    taskId,
    color,
    reminderList,
    name,
    description
  } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v2/web/task-questionnaire-update`,
    accessToken,
    data: {
      scheduleRepeat,
      scheduleDescription,
      taskId,
      color,
      reminderList,
      name,
      description
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_V2_TASK_QUESTIONNAIRE_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v2/web/task-questionnaire-update',
        ...result,
        error,
      }),
    )
  }

  delete result.payload.enabled
  yield put(doMETHOD_UPDATE_TASK(result.payload))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* v2TaskQuestionnaireUpdateSaga() {
  yield takeEvery(REQUEST_V2_TASK_QUESTIONNAIRE_UPDATE as any, requestV2TaskQuestionnaireUpdate)
}
