import {CorrelationResultItem} from '../../../../shared/analysis'
import {LatexConverter} from '../model/converter'

export const IndividualCorrelationLatexConverter: LatexConverter<CorrelationResultItem> = (input) => {
  const r = input.data.corr_coef ?? NaN
  const p = input.data.p_value ?? NaN
  const isStatisticallySignificant = p <= 0.05

  const rTex = `r=${r}`
  const pTex = `p=${p}`
  const statisticallySignificantTex = ';\\ p \\le 0.05'
  return [rTex, pTex]
    .map((line) => (isStatisticallySignificant ? line + statisticallySignificantTex : line))
    .map((line) => '\\(' + line + '\\)')
}
