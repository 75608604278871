import {compact} from 'lodash'
import {of, from, concat} from 'ix/asynciterable'
import {flatMap} from 'ix/asynciterable/operators'
import {makeZip, InputWithoutMeta, InputWithSizeMeta} from 'client-zip'
import streamSaver from 'streamsaver'

import {FileMerger, ItemGrouper} from '../tabular_data'
import {
  DataDownloadRequestPayload,
  DataDownloadResponsePayload,
  DataDownloadResponsePayloadObject,
  DataDownloaderItem,
} from './model'
import {baseRequest} from '../request'
import {API_URL} from '../../lib'
import {doAUTH_SET} from '../../store'
import {RefreshTokenAction} from '../auth/refresh_token_action'

export interface DataDownloaderArgs {
  payload: DataDownloadRequestPayload
  outputFilename: string
}

interface DataDownloaderAuth {
  accessToken: string
  refreshToken: string
  accessTokenExpiresInSeconds: number
  refreshTokenExpiresInSeconds: number
}

export interface DataDownloaderConfig {
  auth: DataDownloaderAuth
}

export class DataDownloader {
  constructor(config: DataDownloaderConfig) {
    const {auth} = config
    this.auth = auth
    streamSaver.mitm = '/static/mitm.html'
  }

  private auth: DataDownloaderAuth

  private get placeholderInput(): InputWithSizeMeta {
    return {
      input: '',
      name: 'Powered by Labfront.txt',
    }
  }

  async run(args: DataDownloaderArgs): Promise<void> {
    const {payload, outputFilename} = args
    const inputIterable = concat(
      of(this.placeholderInput),
      from(this.pagesIterable(payload)).pipe(flatMap((inputs) => this.filesIterable(inputs))),
    )
    const zipStream = makeZip(inputIterable)
    const saveStream = streamSaver.createWriteStream(outputFilename)
    return zipStream.pipeTo(saveStream).catch((error) => {
      console.error(error)
      throw error
    })
  }

  private async *pagesIterable(data: DataDownloadRequestPayload): AsyncIterable<DataDownloaderItem[]> {
    let continuation: string | undefined = undefined
    do {
      const payload: DataDownloadResponsePayload = await this.requestDownload({
        ...data,
        continuation,
      })
      continuation = payload.continuation
      if (payload.objects.length) {
        yield payload.objects.map(({remotePath, displayPath, ...object}: DataDownloadResponsePayloadObject) => ({
          ...object,
          key: remotePath,
          name: displayPath,
        }))
      }
    } while (continuation)
  }

  private async *filesIterable(inputs: DataDownloaderItem[]): AsyncIterable<InputWithoutMeta> {
    const grouper = new ItemGrouper()
    const merger = new FileMerger()

    for (const group of grouper.group(inputs)) {
      yield await Promise.all(
        group.map(async ({url, ...metadata}) => {
          const response = await fetch(url)
          if (response.status >= 200 && response.status <= 299) {
            if (response.body) {
              return {
                ...metadata,
                input: response.body,
              }
            }
          } else {
            throw new Error(`fetch ${url} failed: ${response}`)
          }
        }),
      ).then((files) => merger.merge(compact(files)))
    }
  }

  private async requestDownload(data: DataDownloadRequestPayload): Promise<DataDownloadResponsePayload> {
    const doRequest = () =>
      baseRequest({
        method: 'post',
        url: `${API_URL}/v2/web/project-data-download-metadata-fetch`,
        data,
        accessToken: this.auth.accessToken,
      })

    let response = await doRequest()
    if (!response.success) {
      if (response.statusCode === 401) {
        await this.refreshToken()
        response = await doRequest()
        if (!response.success) {
          throw response.error
        }
      } else {
        throw response.error
      }
    }

    return response.payload as DataDownloadResponsePayload
  }

  private async refreshToken() {
    const response = await RefreshTokenAction.instance.execute(this.auth.refreshToken)
    if (!response.success) {
      throw response.error
    }
    const {accessToken, refreshToken, accessTokenExpiresInSeconds, refreshTokenExpiresInSeconds} =
      response.payload as DataDownloaderAuth
    this.auth = {
      accessToken,
      refreshToken,
      accessTokenExpiresInSeconds,
      refreshTokenExpiresInSeconds,
    }
    doAUTH_SET({
      accessToken,
      refreshToken,
      accessTokenExpiresInSeconds,
      refreshTokenExpiresInSeconds,
    })
  }
}
