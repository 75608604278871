import {useState, useEffect, Dispatch, SetStateAction} from 'react'

import {createDispatchActions, selectRequestComplete, selectBatchData} from '../../store'

import {PopupSimple} from '..'

export interface ProjectSimpleDeletePopPropsT {
  setDisplayDeletePopup: Dispatch<SetStateAction<boolean>>
  projectName: string
  projectId: string
}

export const ProjectSimpleDeletePop = (props: ProjectSimpleDeletePopPropsT) => {
  const {setDisplayDeletePopup, projectName, projectId} = props

  const {doREQUEST_PROJECT_STATUS_UPDATE}: any = createDispatchActions()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)
  const batchData = selectBatchData()
  const batchId = batchData && Object.values(batchData)?.find((item) => item.projectId === projectId)?.id

  const handleDeleteProject = () => {
    doREQUEST_PROJECT_STATUS_UPDATE({
      setRequestId,
      payload: {
        projectId,
        status: 'deleted',
        batchId,
      },
    })
  }

  useEffect(() => {
    if (!requestComplete) return
    setDisplayDeletePopup(false)
  }, [requestComplete])

  return (
    <div
      onClick={(e) => e.preventDefault()}
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '100',
      }}
    >
      <PopupSimple
        {...props}
        {...{
          method: 'Delete',
          name: 'Project',
          description: `Are you sure you want to delete project named ${projectName}?`,
          buttonText: 'Delete Project',
          buttonType: 'reverse',
          buttonColor: 'warning',
          closeAction: setDisplayDeletePopup,
          doAction: handleDeleteProject,
        }}
      />
    </div>
  )
}
