import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  SagaIterator,
  assertPartialSchema,
  delay,
  call,
} from '../../lib'
import {insertAnalysisDataToDb} from '../db/db_data_setter'
import {
  doREQUEST_ERROR,
  doREQUEST_COMPLETE,
  doPARTICIPANT_ANALYSIS_DATA_SET,
  doPARTICIPANT_ANALYSIS_DATA_SET_EMPTY,
} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PARTICIPANT_ANALYSIS_DATA_FETCH = 'REQUEST_PARTICIPANT_ANALYSIS_DATA_FETCH'
export const doREQUEST_PARTICIPANT_ANALYSIS_DATA_FETCH = createDoRequestAction(REQUEST_PARTICIPANT_ANALYSIS_DATA_FETCH)

export const requestParticipantAnalysisDataFetchActionCreators = {
  doREQUEST_PARTICIPANT_ANALYSIS_DATA_FETCH: doREQUEST_PARTICIPANT_ANALYSIS_DATA_FETCH,
}

export function* requestParticipantAnalysisDataFetch({payload}: any): SagaIterator {
  debug('saga*requestParticipantAnalysisDataFetch')

  try {
    assertPartialSchema({
      payload,
      schema: v.object({
        participantId: v.string().uuid().required(),
        yymmddIndex: v.number().required().example(231011),
        garminDeviceTaskConfig: v.object().required(),
        dataType: v.string().required(),
        projection: v.object().optional().allow(null),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {participantId, yymmddIndex, dataType, projection, garminDeviceTaskConfig} = payload

  const accessToken = yield call(getToken)

  let result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/participant-analysis-data-fetch`,
    accessToken,
    data: {
      participantId,
      yymmddIndex,
      dataType,
      projection,
    },
  })

  if (!result.success || result.error) {
    // 404 indicate no data source to analysis, it's a correct fail
    if (result.statusCode === 404) {
      yield put(
        doREQUEST_COMPLETE({
          requestId: payload.requestId,
          ...result.payload,
        }),
      )

      return yield put(
        doPARTICIPANT_ANALYSIS_DATA_SET_EMPTY({
          participantId,
          yymmddIndex,
          dataType,
        }),
      )
    } else {
      const error = result.error || new Error('request did not succeed')
      debug(error)
      return yield put(
        doREQUEST_ERROR({
          fromType: REQUEST_PARTICIPANT_ANALYSIS_DATA_FETCH,
          fromPayload: payload,
          requestId: payload.requestId,
          url: '/v1/web/participant-analysis-data-fetch',
          ...result,
          error,
        }),
      )
    }
  }

  let retry = 0
  while (result.statusCode === 204 && retry < 30) {
    retry++
    console.log(`retry fetch analysis data:${retry}`)
    yield delay(1000)
    result = yield request({
      method: 'post',
      url: `${API_URL}/v1/web/participant-analysis-data-fetch`,
      accessToken,
      data: {
        participantId,
        yymmddIndex,
        dataType,
        projection,
      },
    })
  }

  if (result.statusCode === 200) {
    const dbSetReuslt = yield call(async () => {
      return await insertAnalysisDataToDb(garminDeviceTaskConfig, result.payload)
    })
    
    yield put(
      doPARTICIPANT_ANALYSIS_DATA_SET({
        dbOperationResultList: dbSetReuslt,
      }),
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result.payload,
    }),
  )
}

export function* visualizeParticipantAnalysisDataFetchSaga() {
  yield takeEvery(REQUEST_PARTICIPANT_ANALYSIS_DATA_FETCH, requestParticipantAnalysisDataFetch)
}
