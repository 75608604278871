import {API_URL, v, put, debug, request, takeEvery, createDoRequestAction, assertTotalSchema, call} from '../../lib'

import {SagaIterator} from 'redux-saga'

import {doWORKSPACE_NAME_SET, doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_WORKSPACE_UPDATE = 'REQUEST_WORKSPACE_UPDATE'
export const doREQUEST_WORKSPACE_UPDATE = createDoRequestAction(REQUEST_WORKSPACE_UPDATE)

export const requestWorkspaceUpdateActionCreators = {
  doREQUEST_WORKSPACE_UPDATE,
}

interface Payload {
  payload: {
    requestId: string
    workspaceId: string
    name: string
  }
}

export function* requestWorkspaceUpdate({payload}: Payload): SagaIterator {
  debug('saga*requestWorkspaceUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        workspaceId: v.string().uuid().exist(),
        name: v.string().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {name, workspaceId} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/workspace-update`,
    accessToken,
    data: {
      workspaceId,
      name,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_WORKSPACE_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/workspace-update',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doWORKSPACE_NAME_SET({
      id: workspaceId,
      name,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* workspaceUpdateSaga() {
  yield takeEvery(REQUEST_WORKSPACE_UPDATE as any, requestWorkspaceUpdate)
}
