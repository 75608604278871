import {useState, useEffect, Dispatch, SetStateAction} from 'react'

import {v, debug, useForm} from '../../lib'

import {createDispatchActions, selectRequestComplete} from '../../store'

import {PopupSimple, Input, LabelBox} from '..'

const schema = v.object({
  name: v.string().max(50).required(),
})

interface Props {
  setDisplayCreateDeveloperTokenPopup: Dispatch<SetStateAction<boolean>>
  setSecretCache: Dispatch<SetStateAction<string>>
}
export const CreateDeveloperTokenPopup = (props: Props) => {
  const {setDisplayCreateDeveloperTokenPopup, setSecretCache} = props

  const {doREQUEST_DEVELOPER_TOKEN_CREATE}: any = createDispatchActions()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)  

  const [tokenName, setTokenName] = useState('')
  const {register, handleSubmit} = useForm({schema})

  const handleChange = (value: string) => {
    setTokenName(value)
  }

  const onSubmit = (data: Record<string, string>) => {
    debug('CreateDeveloperTokenForm.onSubmit', {data})
    doREQUEST_DEVELOPER_TOKEN_CREATE({
      setRequestId,
      payload: {
        ...data,
      },
    })
  }

  const onError = (error: any) => {
    debug('CreateDeveloperTokenForm.onError', {error})
  }

  useEffect(() => {
    if (!requestComplete) return
    if (!requestComplete?.success) return
    setSecretCache(requestComplete.payload.payload.bearerToken)
    setDisplayCreateDeveloperTokenPopup(false)
  }, [requestComplete])

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      data-testid='create_developer_token_popup'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 104,
      }}
    >
      <PopupSimple
        method='Name'
        name='Your Developer Key'
        description='Note that you&#39;ll only be able to view the hash once. Hash is not stored on our side so it is your responsibility to keep track of it. If you forget your hash, we cannot recover it for you. You&#39;ll need to generate a new key.'
        buttonColor='primary'
        buttonText='Save'
        closeAction={setDisplayCreateDeveloperTokenPopup}
        btnDisableAction={!tokenName}
        html={
          <LabelBox
            {...{
              label: 'Developer Key',
              leftChracters: 50 - tokenName.length,
            }}
          >
            <Input
              {...register('name')}
              placeholder='Write a short and easy to identify name for your developer key'
              value={tokenName}
              maxLength='50'
              onChange={(e: any) => handleChange(e.target.value)}
              css={{marginBottom: '50px'}}
            />
          </LabelBox>
        }
      />
    </form>
  )
}
