import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {v, debug, useForm} from '../../lib'
import {selectTheme, createDispatchActions, selectRequestComplete} from '../../store'
import {Button, Legend, Input, LabelBox, ProfileSettingsProcessString} from '..'

const schema = v.object({
  email: v.string().required(),
})

export interface NewEmailInputPagePropsT {
  verifyCode: string
  setProfileSettingsProcess: Dispatch<SetStateAction<ProfileSettingsProcessString>>
  setIncorrectCode: Dispatch<SetStateAction<boolean>>
}

export const NewEmailInputPage = (props: NewEmailInputPagePropsT) => {
  const {color, pad} = selectTheme()

  const {verifyCode, setProfileSettingsProcess, setIncorrectCode} = props

  const {doEMAIL_UPDATE_SUBMIT}: any = createDispatchActions()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)

  const [hasEmailRequiredError, setEmailRequiredError] = useState(false)
  const [hasEmailValidError, setEmailValidError] = useState(false)
  const [hasEmailConflictError, setEmailConflictError] = useState(false)

  const {register, handleSubmit, watch} = useForm({schema})

  const onSubmit = (data: any) => {
    debug('newEmailInput.onSubmit', data)
    doEMAIL_UPDATE_SUBMIT({
      setRequestId,
      payload: {
        verificationCode: verifyCode,
        email: data?.email,
      },
    })
  }

  const onError = (error: any) => {
    setEmailRequiredError(error?.email?.type == 'string.empty' ? true : false)
    setEmailValidError(error?.email?.type == 'string.email' ? true : false)
  }

  const backToVerifyPage = () => {
    setIncorrectCode(true)
    setProfileSettingsProcess('verify_code')
  }

  useEffect(() => {
    if (!requestComplete) return
    if (!requestComplete.success && requestComplete.payload.statusCode === 410) return setEmailConflictError(true)
    if (!requestComplete.success) return backToVerifyPage()
    setProfileSettingsProcess('email_changed')
  }, [requestComplete])

  return (
    <div
      data-testid='new_email_input_page'
      css={{
        width: 'fit-content',
        margin: '20vh auto',
        padding: '50px',
        position: 'relative',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit, onError)} css={{width: '480px'}}>
        <div>
          <Legend>Enter Your New Email</Legend>
          <div css={{marginTop: '20px'}}>
            <LabelBox
              {...{
                label: 'New Email',
                warningMsg: hasEmailRequiredError
                  ? 'Email Required'
                  : hasEmailValidError
                  ? 'Must be a valid email'
                  : hasEmailConflictError
                  ? 'An account with this email already exists'
                  : '',
              }}
            >
              <Input
                data-testid='new_email_input'
                {...register('email')}
                css={{marginBottom: pad.large}}
                type='email'
                placeholder='name@email.com'
              />
            </LabelBox>
          </div>
        </div>

        <div css={{display: 'flex', alignItems: 'center'}}>
          <Button
            type='submit'
            css={{
              marginRight: pad.medium,
              background: watch('email')?.length > 0 ? color.primary : color.grey_160,
            }}
          >
            Done
          </Button>
        </div>
      </form>
    </div>
  )
}
