import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  SagaIterator,
  assertPartialSchema,
  call,
  select,
  t,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doHANDLE_PARTICIPANT_DAILY_DATA_DB_RESULT, dateDataState} from '../state'
import {insertDailyDataToDb} from '../db/db_data_setter'
import {SummaryType} from '../../shared/mongo'
import { getToken } from './token_fetcher'

export const REQUEST_PARTICIPANT_GARMIN_DIRECT_DAILY_SUMMARY = 'REQUEST_PARTICIPANT_GARMIN_DIRECT_DAILY_SUMMARY'
export const doREQUEST_PARTICIPANT_GARMIN_DIRECT_DAILY_SUMMARY = createDoRequestAction(
  REQUEST_PARTICIPANT_GARMIN_DIRECT_DAILY_SUMMARY,
)

export const requestParticipantGarminDirectDailySummaryActionCreators = {
  doREQUEST_PARTICIPANT_GARMIN_DIRECT_DAILY_SUMMARY,
}

export function* requestParticipantGarminDirectDailySummary({payload}: any): SagaIterator {
  debug('saga*requestParticipantGarminDirectDailySummary')

  assertPartialSchema({
    payload,
    schema: v.object({
      participantId: v.string().uuid().required(),
      yymmddIndexStart: v.number().required().example(231011),
      yymmddIndexEnd: v.number().required().example(231011),
    }),
  })

  const {participantId, yymmddIndexStart, yymmddIndexEnd} = payload

  const accessToken = yield call(getToken)

  const participantDailyData = yield select((state) => state.participantDailyData)
  const currentParticipantDailyData = participantDailyData[participantId]

  const jobIdListResult = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/participant-date-range-summary-update-request`,
    accessToken,
    data: {
      participantId,
      yymmddIndexStart,
      yymmddIndexEnd,
      type: SummaryType.GarminDeviceDaily,
    },
  })

  if (!jobIdListResult.success || jobIdListResult.error) {
    const error = jobIdListResult.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_GARMIN_DIRECT_DAILY_SUMMARY,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/participant-date-range-summary-update-request',
        ...jobIdListResult,
        error,
      }),
    )
  }

  const {yymmddJobList} = jobIdListResult.payload

  if (yymmddJobList.length > 0) {
    console.log('yymmddJobList')
    console.log(yymmddJobList)
    // handle job progress
    // should wait here
  }

  let requestYymmddIndexList = t.toYYMMDDRange(yymmddIndexStart, yymmddIndexEnd)

  const updatedYymmddIndexList =
    yymmddJobList.length > 0 ? yymmddJobList.map((item: {yymmddIndex: number; jobId: string}) => item.yymmddIndex) : []

  if (currentParticipantDailyData?.['GarminDirectDailySummary']) {
    const skipYymmddIndexList: number[] = []
    for (const [yymmddIndexStr, hasData] of Object.entries(currentParticipantDailyData['GarminDirectDailySummary'])) {
      const yymmddIndex = +yymmddIndexStr
      if (!updatedYymmddIndexList.includes(yymmddIndex)) {
        skipYymmddIndexList.push(yymmddIndex)
      }
    }
    requestYymmddIndexList = requestYymmddIndexList.filter((yymmddIndex) => {
      return !skipYymmddIndexList.includes(yymmddIndex)
    })
  }

  if (requestYymmddIndexList.length > 0) {
    const summaryResult = yield request({
      method: 'post',
      url: `${API_URL}/v1/web/visualizer-garmin-direct-daily-summary-data-fetch`,
      accessToken,
      data: {
        participantId,
        yymmddIndexList: requestYymmddIndexList,
        projection: {
          participantId: 1,
          yymmddIndex: 1,
          dataType: 1,
          summary: 1,
        },
      },
    })

    const dbOperationResultList = yield call(async () => {
      return await insertDailyDataToDb(participantId, requestYymmddIndexList, summaryResult.payload)
    })

    console.log(summaryResult.payload)
    console.log(dbOperationResultList)

    yield put(
      doHANDLE_PARTICIPANT_DAILY_DATA_DB_RESULT({
        dbOperationResultList,
      }),
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
    }),
  )
}

export function* ParticipantGarminDirectDailySummarySaga() {
  yield takeEvery(REQUEST_PARTICIPANT_GARMIN_DIRECT_DAILY_SUMMARY, requestParticipantGarminDirectDailySummary)
}
