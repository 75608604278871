import {
  API_URL,
  SagaIterator,
  assertTotalSchema,
  call,
  createDoRequestAction,
  debug,
  put,
  request,
  takeEvery,
  v,
} from '../../lib'
import {doBATCH_PARTICIPANT_TAG_LIST_REMOVE, doREQUEST_COMPLETE, doREQUEST_ERROR} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PARTICIPANT_TAG_REMOVE = 'REQUEST_PARTICIPANT_TAG_REMOVE'
export const doREQUEST_PARTICIPANT_TAG_REMOVE = createDoRequestAction(REQUEST_PARTICIPANT_TAG_REMOVE)

export const requetsParticipantTagRemoveActionCreators = {
  doREQUEST_PARTICIPANT_TAG_REMOVE,
}

export function* requestParticipantTagRemove({payload}: any): SagaIterator {
  debug('saga*requestParticipantTagRemove')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        batchId: v.string().uuid().exist(),
        participantId: v.string().uuid().exist(),
        tagIds: v.array().items(v.string().uuid().exist()).exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {participantId, batchId, tagIds} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'POST',
    url: `${API_URL}/v1/web/participant-tag-remove`,
    accessToken,
    data: {participantId, tagIds},
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_TAG_REMOVE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/participant-tag-remove',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doBATCH_PARTICIPANT_TAG_LIST_REMOVE({
      batchId,
      participantId,
      tagIds: result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* participantTagRemoveSaga() {
  yield takeEvery(REQUEST_PARTICIPANT_TAG_REMOVE, requestParticipantTagRemove)
}
