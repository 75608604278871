import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH = 'REQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH'
export const doREQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH = createDoRequestAction(
  REQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH,
)

export const requestProjectArchiveDownloadMetadataFetchActionCreators = {
  doREQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH,
}

export function* requestProjectArchiveDownloadMetadataFetch({payload}: any): SagaIterator {
  debug('saga*requestProjectArchiveDownloadMetadataFetch')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {projectId} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/project-archive-download-metadata-fetch`,
    accessToken,
    data: {
      projectId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-archive-download-metadata-fetch',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH,
      fromPayload: payload,
      requestId: payload.requestId,
      url: '/v1/web/project-archive-download-metadata-fetch',
      ...result,
    }),
  )
}

export function* ProjectArchiveDownloadMetadataFetchSaga() {
  yield takeEvery(REQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH as any, requestProjectArchiveDownloadMetadataFetch)
}
