import { QueryKey } from '../mongo/localdate_analysis_data'
import {
  CorrelationConfig,
  HRVConfig,
  SleepScoreConfig,
  SleepWakeConfig,
} from './request'

export interface AnalysisServiceResponse<T = unknown> {
  metadata: T
  [key: string]: unknown
}

export interface CorrelationResultCoordinate {
  row: string
  column: string
}

export interface CorrelationResultItemData {
  corr_coef: number | null
  p_value: number | null
  mean: number | null
  std: number | null
  sem: number | null
}

export enum CorrelationResultAnalysisType {
  Correlation = 'correlation',
  Distribution = 'distribution',
}

export interface CorrelationResultSubItem {
  analysis_type: CorrelationResultAnalysisType
  graph: string
}

export interface CorrelationResultItem {
  coord: CorrelationResultCoordinate
  graph?: string
  data: CorrelationResultItemData
  sub_items?: CorrelationResultSubItem[]
}

export interface CorrelationResult {
  items: CorrelationResultItem[][]
}

export interface IndividualCorrelationResponse extends AnalysisServiceResponse {
  result: CorrelationResult
  config: CorrelationConfig
}

export interface GroupCorrelationResponse extends AnalysisServiceResponse {
  result: CorrelationResult
  config: CorrelationConfig
}

export interface SleepResponse extends AnalysisServiceResponse<QueryKey> {
  sleep_score: SleepScoreResult
  sleep_wake?: SleepWakeResult
}

export interface SleepScoreResult {
  sleep_score: SleepScoreData[]
  config: SleepScoreConfig
}

export interface SleepScoreData {
  t: number
  sc: number | null
}

export interface SleepWakeResult {
  sleep_wake: SleepWakeData[]
  config: SleepWakeConfig
}

export interface SleepWakeData {
  t: number
  sw: boolean
}

export interface HRVResponse extends AnalysisServiceResponse<QueryKey> {
  hrv: HRVResult
}

export interface HRVResult {
  hrv: HRVData[]
  config: HRVConfig
}

export interface HRVData {
  t: number
  count: number
  hrv?: HRVDataItem
}

export interface HRVDataItem {
  mrr: number | null
  sdrr: number | null
  rmssd: number | null
  prr50: number | null
  tp: number | null
  vlf: number | null
  lf: number | null
  hf: number | null
  lfhf: number | null
}
