import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {
  doREQUEST_ERROR, 
  doREQUEST_COMPLETE,
  doFAVORITE_DELETE,
} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_WORKSPACE_FAVORITE_DELETE = 'REQUEST_WORKSPACE_FAVORITE_DELETE'
export const doREQUEST_WORKSPACE_FAVORITE_DELETE = createDoRequestAction(REQUEST_WORKSPACE_FAVORITE_DELETE)

export const requestWorkspaceFavoriteDeleteActionCreators = {
  doREQUEST_WORKSPACE_FAVORITE_DELETE,
}

interface Payload {
  type: string
  payload: {    
    requestId: string
    workspaceFavoriteId: string
  }
}

export function* requestWorkspaceFavoriteDelete({payload}: Payload): SagaIterator {
  debug('saga*requestWorkspaceFavoriteDelete')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        workspaceFavoriteId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {
    workspaceFavoriteId,
  } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/workspace-favorite-delete`,
    accessToken,
    data: {
      workspaceFavoriteId
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_WORKSPACE_FAVORITE_DELETE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/workspace-favorite-delete',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doFAVORITE_DELETE({
      workspaceFavoriteId,
    })
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* workspaceFavoriteDeleteSaga() {
  yield takeEvery(REQUEST_WORKSPACE_FAVORITE_DELETE, requestWorkspaceFavoriteDelete)
}
