import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'
import {doCOLLABORATOR_SET, doREQUEST_COMPLETE, doREQUEST_ERROR} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_WORKSPACE_COLLABORATOR_CREATE = 'REQUEST_WORKSPACE_COLLABORATOR_CREATE'
export const doREQUEST_WORKSPACE_COLLABORATOR_CREATE = createDoRequestAction(REQUEST_WORKSPACE_COLLABORATOR_CREATE)

export const requestWorkspaceCollaboratorCreateActionCreators = {
  doREQUEST_WORKSPACE_COLLABORATOR_CREATE,
}

export function* requestWorkspaceCollaboratorCreate({payload}: any): SagaIterator {
  debug('saga*requestWorkspaceCollaboratorCreate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        email: v.string().email({tlds: {allow: false}}).exist(),
        workspaceId: v.string().uuid().exist(),
        type: v.string().valid('admin', 'editor', 'viewer', 'project_collaborator').exist(),
        projectCollaboratorList: v.array().optional().items(v.object({
          projectId: v.string().exist(),
          type: v.string().valid('projectAdmin', 'projectEditor', 'projectViewer').exist(),
        })),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {email, workspaceId, type, projectCollaboratorList} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/workspace-collaborator-create`,
    accessToken,
    data: {
      email,
      workspaceId,
      type,
      projectCollaboratorList,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_WORKSPACE_COLLABORATOR_CREATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/workspace-collaborator-create',
        ...result,
        error,
      }),
    )
  }

  yield put(doCOLLABORATOR_SET({...result.payload}))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* workspaceCollaboratorCreateSaga() {
  yield takeEvery(REQUEST_WORKSPACE_COLLABORATOR_CREATE, requestWorkspaceCollaboratorCreate)
}
