import {useState, useEffect, useMemo} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {RIF, _, sortBy, useCurrentProjectState, useCurrentWorkspaceState} from '../../lib'
import CronParser from 'cron-parser'
import {parse, format} from 'date-fns'

import {
  selectTheme,
  selectBatchData,
  createDispatchActions,
  selectRequestComplete,
  selectAdherenceData,
  selectMethod,
} from '../../store'
import {
  ParticipantGreyIcon,
  GarminDeviceImg,
  MovesenseImg,
  DexcomImg,
  ParticipantTaskIcon,
  TimerBlackIcon,
  SurveyBlackIcon,
  DeviceIcon,
  RepeatIcon,
  BellIcon,
  SmileIcon,
  LabfrontIcon,
  DexcomIcon,
  GarminConnectIcon,
  InviteParticipantBackground,
  MessageIcon,
  EditIcon,
} from '../../asset/image'
import {
  ProjectSwitchBar,
  // ProjectSettings,
  Button,
  ProjectSummaryPage,
  Tooltip,
  ButtonReverse,
  convertMovesenseDescription,
  convertGarminStreamDescription,
  TaskAdherenceCard,
  GarminConnectAdherenceCard,
  DexcomAdherenceCard,
  AdherenceOverviewDataType,
  ProjectAdherenceOverviewProp,
  GarminDirectDataAdherenceCard,
  SendNotificationParticipantSelector,
  PopupSendNotification,
  PopupSendNotificationSucceed,
  ToolTipHover,
  DataDownloadPage,
} from '..'
import {TaskStateType, TaskType, TaskReminder, getTaskContentName} from '../../model'
import {AttemptRequestParticipantStatus} from './request_project_data_hook'
import {IBatch, IParticipant} from '../../shared/db'

export const OverviewPage = () => {
  const {color, fontWeight, fontSize, pad, colorTranslator} = selectTheme()

  const {doREQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION}: any = createDispatchActions()
  const navigate = useNavigate()
  const { projectId, project } = useCurrentProjectState()
  const batchId = _.head(project?.batchList)?.id
  const batchData = selectBatchData() as Record<string, IBatch>
  const batch = batchId ? batchData[batchId] : undefined
  const participantList = batch?.participantList ?? []
  const [activatedParticipantList, setActivatedParticipantList] = useState<IParticipant[]>([])
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)

  const [searchParams, setSearchParams] = useSearchParams()
  // const projectSettingsPage = searchParams.get('action') === 'settings'
  const projectSummaryPage = searchParams.get('action') === 'summary'

  const [onboardingStatus, setOnboardingStatus] = useState<{
    activated: string[]
    setupGarminConnect: string[]
    setupLabfrontDirect: string[]
    setupDexcom: string[]
  }>({
    activated: [],
    setupGarminConnect: [],
    setupLabfrontDirect: [],
    setupDexcom: [],
  })
  const [displaySummaryPage, setDisplaySummaryPage] = useState(false)
  const [renderDataDownloadPage, setRenderDataDownloadPage] = useState(false)

  // for project overview
  const method = selectMethod()
  const garminConnectEnabled: boolean = method?.garminConnectEnable
  const dexcomDataEnabled: boolean = method.dexcomIntegrationId ? true : false
  const garminDeviceTask: TaskStateType | undefined = _.find(method?.taskList, {type: TaskType.GarminDevice})
  const movesenseDeviceEnabled: boolean = method?.movesenseDeviceEnable
  const garminDeviceEnable: boolean = garminDeviceTask ? true : false
  const projectAdherence = projectId ? selectAdherenceData()?.[projectId] : undefined
  const taskList: TaskStateType[] =
    method?.taskList
      ?.filter((item: TaskStateType) => item.type !== TaskType.GarminDevice && item.enabled)
      ?.sort(sortBy('index')) ?? []
  const [renderParticipantSelector, setRenderParticipantSelector] = useState<boolean>(false)
  const [participantSelectorDatatype, setParticipantSelectorDatatype] = useState<AdherenceOverviewDataType>(
    AdherenceOverviewDataType.JoinedProject,
  )
  const [participantSelectorHeader, setParticipantSelectorHeader] = useState<string>('')
  const [participantSelectorDate, setParticipantSelectorDate] = useState<string>('')
  const [sendingList, setSendingList] = useState<string[]>([])
  const [amountOfDisableNotification, setAmountOfDisableNotification] = useState<number>(0)
  const [displayPopupSendNotification, setDisplayPopupSendNotification] = useState<boolean>(false)
  const [displayPopupSendNotificationSucceed, setDisplayPopupSendNotificationSucceed] = useState<boolean>(false)
  const [pushNotificationTitle, setPushNotificationTitle] = useState<string>('')
  const [pushNotificationContent, setPushNotificationContent] = useState<string>('')
  const [requestSendPushNotificationId, setRequestSendPushNotificationId] = useState(null)
  const requestSendPushNotificationComplete = selectRequestComplete(requestSendPushNotificationId)
  const defaultGarminDeviceSpec: Record<string, boolean> = {
    bbiEnable: false,
    stepsEnable: false,
    stressEnable: false,
    pulseOxEnable: false,
    heartRateEnable: false,
    respirationEnable: false,
    actigraphyEnable: false,
  }
  const [garminDeviceSpec, setGarminDeviceSpec] = useState<Record<string, boolean>>(defaultGarminDeviceSpec)
  const [hoveringParticipantCountBtn, setHoveringParticipantCountBtn] = useState(false)
  const [hoveringGarminConnectCountBtn, setHoveringGarminConnectCountBtn] = useState(false)
  const [hoveringGarminDirectCountBtn, setHoveringGarminDirectCountBtn] = useState(false)
  const [hoveringDexcomSetupCountBtn, setHoveringDexcomSetupCountBtn] = useState(false)

  AttemptRequestParticipantStatus({
    requestAdherence: {durationBeforeToday: 6},
  })

  useEffect(() => {
    if (!method.id) return
    if (garminDeviceEnable) {
      const newSpec = {...defaultGarminDeviceSpec}
      const garminDeviceTask = _.find(method.taskList, ['type', 'garmin_device'])
      if (!garminDeviceTask) return
      for (const key of Object.keys(defaultGarminDeviceSpec)) {
        if (garminDeviceTask.garminDevice[key]) newSpec[key] = true
      }
      setGarminDeviceSpec(newSpec)
    }
  }, [method])
  // TODO: fix, in dashboard page, cant redirect to other page without reload (navigate(0))
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    // if (projectSettingsPage) return setDisplaySettingsPage(true)
    if (projectSummaryPage) return setDisplaySummaryPage(true)
  }, [])

  useEffect(() => {
    const setupLabfrontDirect: string[] = []
    if (projectAdherence?.adherenceList?.length) {
      projectAdherence.adherenceList.forEach((adherence) => {
        if (adherence.status.deviceType && adherence.participantId) {
          return setupLabfrontDirect.push(adherence.participantId)
        }
      })
    }
    setOnboardingStatus((prevState) => ({...prevState, setupLabfrontDirect}))
  }, [projectAdherence])

  useEffect(() => {
    if (!participantList.length) return
    const activatedList = participantList.filter((participant) => participant.activated)
    setActivatedParticipantList(activatedList)
    setLoadingComplete(true)
    const garminConnectSetupList = participantList
      .filter((participant) => participant.garminConnectAccountId)
      .map((p) => p.id)
    const dexcomSetupList = participantList.filter((participant) => participant.dexcomAccountId).map((p) => p.id)
    if (
      activatedList.length === onboardingStatus.activated.length &&
      garminConnectSetupList.length === onboardingStatus.setupGarminConnect.length
    )
      return
    setOnboardingStatus((prevState) => ({
      ...prevState,
      activated: activatedList.map((p) => p.id),
      setupGarminConnect: garminConnectSetupList,
      setupDexcom: dexcomSetupList,
    }))
  }, [participantList])

  // const [displaySettingsPage, setDisplaySettingsPage] = useState(false)

  useEffect(() => {
    // if (!displaySettingsPage) return setSearchParams('')
    if (!displaySummaryPage) return setSearchParams('')
  }, [displaySummaryPage])

  useEffect(() => {
    if (!sendingList.length) return
    let c = 0
    sendingList.map((pid) => {
      const participant = _.find(participantList, ['id', pid])
      if (!participant?.notificationEnabled) c++
    })
    setAmountOfDisableNotification(c)
  }, [sendingList])

  const [dataCollectedParticipantList, setDataCollectedParticipantList] = useState<string[]>([])
  const [noDataYetParticipantList, setNoDataYetParticipantList] = useState<string[]>([])
  const openParticipantList = (
    dataType: AdherenceOverviewDataType,
    yymmddIndex?: number,
    subDataType?: {
      label: string
      value: string
    },
  ) => {
    if (displayPopupSendNotification) return
    const digestMap = projectAdherence?.projectDataDigest?.participantDataDigestMap
    if (!digestMap) return
    const dataCollectedParticipantList: string[] = []
    const tempNoDataYetParticipantList: string[] = activatedParticipantList.map((participant) => participant.id)
    switch (dataType) {
      case AdherenceOverviewDataType.JoinedProject:
        activatedParticipantList.forEach((e) => dataCollectedParticipantList.push(e.id))
        break
      case AdherenceOverviewDataType.setupGarminConnect:
        onboardingStatus.setupGarminConnect.forEach((pid) => {
          dataCollectedParticipantList.push(pid)
          _.remove(tempNoDataYetParticipantList, (npid) => npid === pid)
        })
        break
      case AdherenceOverviewDataType.setupGarminDirect:
        onboardingStatus.setupLabfrontDirect.forEach((pid) => {
          dataCollectedParticipantList.push(pid)
          _.remove(tempNoDataYetParticipantList, (npid) => npid === pid)
        })
        break
      case AdherenceOverviewDataType.setupDexcom:
        onboardingStatus.setupDexcom.forEach((pid) => {
          dataCollectedParticipantList.push(pid)
          _.remove(tempNoDataYetParticipantList, (npid) => npid === pid)
        })
        break
    }
    for (const [participantId, digest] of Object.entries(digestMap)) {
      switch (dataType) {
        case AdherenceOverviewDataType.GarminConnect:
        case AdherenceOverviewDataType.DexcomEGV:
          if ((!yymmddIndex && digest[dataType]?.length) || (yymmddIndex && digest[dataType]?.includes(yymmddIndex))) {
            dataCollectedParticipantList.push(participantId)
            _.remove(tempNoDataYetParticipantList, (pid) => pid === participantId)
            continue
          }
          break
        case AdherenceOverviewDataType.LabfronTask:
        case AdherenceOverviewDataType.GarminDirect: {
          const data = digest[dataType] as Record<string, number[]> | undefined
          if (!data) {
            // tempNoDataYetParticipantList.push(participantId)
            continue
          }
          let hasData = false
          if (!subDataType) {
            for (const subDataTypeDigest of Object.values(data)) {
              if (subDataTypeDigest.length) {
                hasData = true
                break
              }
            }
          } else {
            if (data?.[subDataType.value]?.includes(yymmddIndex as number)) {
              hasData = true
            }
          }
          if (hasData) {
            dataCollectedParticipantList.push(participantId)
            _.remove(tempNoDataYetParticipantList, (pid) => pid === participantId)
            continue
          }
          break
        }
      }
    }
    switch (dataType) {
      case AdherenceOverviewDataType.JoinedProject:
        setParticipantSelectorHeader('Joined This Project')
        break
      case AdherenceOverviewDataType.setupGarminConnect:
        setParticipantSelectorHeader('Completed Garmin Connect Setup')
        break
      case AdherenceOverviewDataType.setupGarminDirect:
        setParticipantSelectorHeader('Completed Garmin Direct Setup')
        break
      case AdherenceOverviewDataType.setupDexcom:
        setParticipantSelectorHeader('Completed Dexcom Setup')
        break
      case AdherenceOverviewDataType.GarminConnect:
        setParticipantSelectorHeader('Garmin Connect Data Collection')
        break
      case AdherenceOverviewDataType.GarminDirect:
        setParticipantSelectorHeader(`Garmin Direct Data - ${subDataType?.label}`)
        break
      case AdherenceOverviewDataType.LabfronTask:
        setParticipantSelectorHeader(`Tasks - ${subDataType?.label}`)
        break
      case AdherenceOverviewDataType.DexcomEGV:
        setParticipantSelectorHeader('Dexcom Data Collection')
    }
    if (yymmddIndex) {
      setParticipantSelectorDate(format(parse(yymmddIndex.toString(), 'yyMMdd', new Date()), 'dd MMM, yyyy'))
    } else {
      setParticipantSelectorDate(format(new Date(), 'dd MMM, yyyy'))
    }
    setDataCollectedParticipantList(dataCollectedParticipantList)
    setNoDataYetParticipantList(tempNoDataYetParticipantList)
    setParticipantSelectorDatatype(dataType)
    setRenderParticipantSelector(true)
  }

  const messageAllParticipant = () => {
    setSendingList(participantList.map((participant) => participant.id))
    setDisplayPopupSendNotification(true)
  }

  const participantCounter = () => {
    return (
      <>
        <div
          css={{
            width: '100%',
          }}
        >
          <div
            css={{
              width: '100%',
              borderRadius: '5px',
              backgroundColor: color.white,
              padding: '24px 24px 16px 24px',
              marginBottom: '24px',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <img width={20} src={ParticipantGreyIcon} />
              <p
                css={{
                  margin: '0 8px',
                  fontWeight: fontWeight.thick,
                  fontSize: fontSize.h6,
                }}
              >
                Participant Onboarding Status
              </p>
              <Tooltip content='Participants need to enter their invite code on the app to join' />
            </div>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  flex: 1,
                  minWidth: '20%',
                  padding: '0 8px',
                  position: 'relative',
                }}
              >
                <button
                  onMouseEnter={() => {
                    setHoveringParticipantCountBtn(true)
                  }}
                  onMouseLeave={() => {
                    setHoveringParticipantCountBtn(false)
                  }}
                  onClick={() => openParticipantList(AdherenceOverviewDataType.JoinedProject)}
                  css={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '5px',
                    padding: '8px',
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    border: 'none',
                    display: 'flex',
                    ':hover': {
                      backgroundColor: color.hover,
                    },
                  }}
                >
                  <div
                    css={{
                      width: '28px',
                      height: '28px',
                      borderRadius: '5px',
                      backgroundColor: color.taskGreen,
                      margin: '6px',
                      padding: '4px',
                    }}
                  >
                    <img height='20' src={SmileIcon} />
                  </div>
                  <div css={{marginLeft: '8px'}}>
                    <div css={{display: 'flex', alignItems: 'center', marginBottom: '4px'}}>
                      <p
                        css={{
                          fontSize: fontSize.h3,
                          marginRight: '8px',
                        }}
                      >
                        {onboardingStatus.activated.length}
                      </p>
                      <p
                        css={{
                          fontSize: fontSize.h6,
                          color: color.textIcon.light,
                        }}
                      >{`/ ${participantList.length}`}</p>
                    </div>
                    <p css={{fontSize: fontSize.h7, color: color.textIcon.secondary}}>Joined this project</p>
                  </div>
                </button>
                {RIF(
                  hoveringParticipantCountBtn,
                  <ToolTipHover
                    {...{
                      title: 'View participants list',
                      hoverTopPosition: '-70%',
                      pseudoTopPosition: '90%',
                      pseudoRightPosition: '50%',
                    }}
                  />,
                )}
              </div>
              {RIF(
                garminConnectEnabled,
                <div
                  css={{
                    borderLeft: `1px solid ${color.border._80}`,
                    flex: 1,
                    display: 'flex',
                    minWidth: '20%',
                    padding: '0 8px',
                    position: 'relative',
                  }}
                >
                  <button
                    onMouseEnter={() => {
                      setHoveringGarminConnectCountBtn(true)
                    }}
                    onMouseLeave={() => {
                      setHoveringGarminConnectCountBtn(false)
                    }}
                    onClick={() => openParticipantList(AdherenceOverviewDataType.setupGarminConnect)}
                    css={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '5px',
                      padding: '8px',
                      backgroundColor: 'transparent',
                      cursor: 'pointer',
                      border: 'none',
                      display: 'flex',
                      ':hover': {
                        backgroundColor: color.hover,
                      },
                    }}
                  >
                    <img
                      height='28'
                      css={{
                        borderRadius: '5px',
                        margin: '6px',
                      }}
                      src={GarminConnectIcon}
                    />
                    <div css={{marginLeft: '8px'}}>
                      <div css={{display: 'flex', alignItems: 'center', marginBottom: '4px'}}>
                        <p
                          css={{
                            fontSize: fontSize.h3,
                            marginRight: '8px',
                          }}
                        >
                          {onboardingStatus.setupGarminConnect.length}
                        </p>
                        <p
                          css={{
                            fontSize: fontSize.h6,
                            color: color.textIcon.light,
                          }}
                        >{`/ ${activatedParticipantList.length}`}</p>
                      </div>
                      <p css={{fontSize: fontSize.h7, color: color.textIcon.secondary}}>
                        Completed Garmin Connect setup
                      </p>
                    </div>
                  </button>
                  {RIF(
                    hoveringGarminConnectCountBtn,
                    <ToolTipHover
                      {...{
                        title: 'View participants list',
                        hoverTopPosition: '-70%',
                        pseudoTopPosition: '90%',
                        pseudoRightPosition: '50%',
                      }}
                    />,
                  )}
                </div>,
              )}
              {RIF(
                garminDeviceEnable,
                <div
                  css={{
                    borderLeft: `1px solid ${color.border._80}`,
                    flex: 1,
                    display: 'flex',
                    minWidth: '20%',
                    padding: '0 8px',
                    position: 'relative',
                  }}
                >
                  <button
                    onMouseEnter={() => {
                      setHoveringGarminDirectCountBtn(true)
                    }}
                    onMouseLeave={() => {
                      setHoveringGarminDirectCountBtn(false)
                    }}
                    onClick={() => openParticipantList(AdherenceOverviewDataType.setupGarminDirect)}
                    css={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '5px',
                      padding: '8px',
                      backgroundColor: 'transparent',
                      cursor: 'pointer',
                      border: 'none',
                      display: 'flex',
                      ':hover': {
                        backgroundColor: color.hover,
                      },
                    }}
                  >
                    <img
                      height='28'
                      css={{
                        borderRadius: '5px',
                        margin: '6px',
                      }}
                      src={LabfrontIcon}
                    />
                    <div css={{marginLeft: '8px'}}>
                      <div css={{display: 'flex', alignItems: 'center', marginBottom: '4px'}}>
                        <p
                          css={{
                            fontSize: fontSize.h3,
                            marginRight: '8px',
                          }}
                        >
                          {onboardingStatus.setupLabfrontDirect.length}
                        </p>
                        <p
                          css={{
                            fontSize: fontSize.h6,
                            color: color.textIcon.light,
                          }}
                        >{`/ ${activatedParticipantList.length}`}</p>
                      </div>
                      <p css={{fontSize: fontSize.h7, color: color.textIcon.secondary}}>
                        Completed Labfront Direct setup
                      </p>
                    </div>
                  </button>
                  {RIF(
                    hoveringGarminDirectCountBtn,
                    <ToolTipHover
                      {...{
                        title: 'View participants list',
                        hoverTopPosition: '-70%',
                        pseudoTopPosition: '90%',
                        pseudoRightPosition: '50%',
                      }}
                    />,
                  )}
                </div>,
              )}
              {RIF(
                dexcomDataEnabled,
                <div
                  css={{
                    borderLeft: `1px solid ${color.border._80}`,
                    flex: 1,
                    display: 'flex',
                    minWidth: '20%',
                    padding: '0 8px',
                    position: 'relative',
                  }}
                >
                  <button
                    onMouseEnter={() => {
                      setHoveringDexcomSetupCountBtn(true)
                    }}
                    onMouseLeave={() => {
                      setHoveringDexcomSetupCountBtn(false)
                    }}
                    onClick={() => openParticipantList(AdherenceOverviewDataType.setupDexcom)}
                    css={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '5px',
                      padding: '8px',
                      backgroundColor: 'transparent',
                      cursor: 'pointer',
                      border: 'none',
                      display: 'flex',
                      ':hover': {
                        backgroundColor: color.hover,
                      },
                    }}
                  >
                    <img
                      height='28'
                      css={{
                        borderRadius: '5px',
                        margin: '6px',
                      }}
                      src={DexcomIcon}
                    />
                    <div css={{marginLeft: '8px'}}>
                      <div css={{display: 'flex', alignItems: 'center', marginBottom: '4px'}}>
                        <p
                          css={{
                            fontSize: fontSize.h3,
                            marginRight: '8px',
                          }}
                        >
                          {onboardingStatus.setupDexcom.length}
                        </p>
                        <p
                          css={{
                            fontSize: fontSize.h6,
                            color: color.textIcon.light,
                          }}
                        >{`/ ${activatedParticipantList.length}`}</p>
                      </div>
                      <p css={{fontSize: fontSize.h7, color: color.textIcon.secondary}}>Dexcom Set Up</p>
                    </div>
                  </button>
                  {RIF(
                    hoveringDexcomSetupCountBtn,
                    <ToolTipHover
                      {...{
                        title: 'View participants list',
                        hoverTopPosition: '-70%',
                        pseudoTopPosition: '90%',
                        pseudoRightPosition: '50%',
                      }}
                    />,
                  )}
                </div>,
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  const adherenceOverviewCardsSection = () => {
    const projectOverviewProps: ProjectAdherenceOverviewProp = {
      projectId: projectId ?? '',
      finalDate: new Date(),
      dateDuration: 7,
      totalParticipantCount: onboardingStatus.activated.length,
      garminDeviceSpec,
      adherenceOverviewClickCallback: (
        dataType: AdherenceOverviewDataType,
        setupStatus: boolean,
        yymmddIndex: number,
        subDataType?: {
          label: string
          value: string
        },
      ) => {
        openParticipantList(dataType, yymmddIndex, subDataType)
      },
    }

    return (
      <div css={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        {/* left part of the section */}
        <div css={{width: 'calc(50% - 12px)'}}>
          {RIF(garminConnectEnabled, <GarminConnectAdherenceCard {...projectOverviewProps} />)}
          <TaskAdherenceCard {...{...projectOverviewProps, taskList}} />
        </div>
        {/* right part */}
        <div css={{width: 'calc(50% - 12px)'}}>
          {RIF(garminDeviceEnable, <GarminDirectDataAdherenceCard {...projectOverviewProps} />)}
          {RIF(dexcomDataEnabled, <DexcomAdherenceCard {...projectOverviewProps} />)}
          <div
            css={{
              width: '100%',
              padding: '24px',
              borderRadius: '5px',
              backgroundColor: color.surface.blue.light,
            }}
          >
            <div
              css={{
                display: 'flex',
                marginBottom: '16px',
              }}
            >
              <img width={20} css={{marginRight: '8px'}} src={MessageIcon} />
              <p
                css={{
                  fontWeight: fontWeight.thick,
                  fontSize: fontSize.h6,
                }}
              >
                Send Message to All Participant(s)
              </p>
            </div>
            <p
              css={{
                fontSize: fontSize.h7,
                color: color.textIcon.secondary,
                marginBottom: '16px',
              }}
            >
              Quickly communicate with all participants to share updates, give reminders, or provide important
              information.
            </p>
            <div
              css={{
                position: 'relative',
              }}
            >
              <ButtonReverse
                onClick={messageAllParticipant}
                bgColor={color.surface.blue.light}
                btnPadding='mediumSmall'
                children={
                  <span
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img css={{marginRight: '4px'}} width={18} src={EditIcon} />
                    <p
                      css={{
                        fontWeight: fontWeight.thick,
                        fontSize: fontSize.h6,
                        color: color.primary,
                      }}
                    >
                      Write Message
                    </p>
                  </span>
                }
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const adherenceOverview = () => {
    if (loadingComplete) {
      if (participantList.length === 0) {
        return noParticipantContent()
      } else {
        return (
          <>
            <div
              css={{
                width: 'calc(100% - 349px)',
                justifyContent: 'space-between',
              }}
            >
              {participantCounter()}
              {adherenceOverviewCardsSection()}
            </div>
          </>
        )
      }
    } else {
      return
    }
  }

  const noParticipantContent = () => {
    return (
      <>
        <div
          css={{
            width: 'calc(100% - 349px)',
            height: '78vh',
            minHeight: '430px',
            borderRadius: '3px',
            border: `1px dashed ${color.grey_160}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <img src={InviteParticipantBackground} width='290' height='170' />
          <div
            css={{
              fontSize: '20px',
              marginTop: '40px',
              marginBottom: '8px',
            }}
          >
            Invite participants to start collecting data.
          </div>
          <div>Add participants to your project by sending invite codes.</div>
          <Button onClick={() => navigate(`/participants/${projectId}`)} css={{marginTop: '30px'}}>
            Invite Participant
          </Button>
        </div>
      </>
    )
  }

  const formatCronString = (cronParseObj: any) => {
    const {dayOfWeek, hour, minute} = cronParseObj.fields

    const timeString = `${hour[0].toString().padStart(2, '0')}:${minute[0].toString().padStart(2, '0')}`
    const AMPM = hour[0] < 12 ? 'AM' : 'PM'

    // (0 or 7 is Sun) from 'cron-parser'
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const formattedDaysOfWeek = dayOfWeek.map((index: number) => daysOfWeek[index])

    let formattedString = 'Repeats'

    if (dayOfWeek.length >= 7) {
      formattedString += ` daily at ${timeString} ${AMPM}`
    } else if (dayOfWeek.length === 1) {
      formattedString += ` every ${formattedDaysOfWeek[0]} at ${timeString} ${AMPM}`
    } else {
      const lastDayOfWeek = formattedDaysOfWeek.pop()
      formattedString += ` every ${formattedDaysOfWeek.join(', ')} and ${lastDayOfWeek} at ${timeString} ${AMPM}`
    }
    return formattedString
  }

  const formatISOString = (dateString: string) => {
    const utcDate = new Date(dateString)
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000
    return new Date(utcDate.getTime() + timezoneOffset).toLocaleString(undefined, options)
  }

  const generateReminderString = (reminder: TaskReminder) => {
    if (reminder.type === 'cron') {
      const cronInterval = CronParser.parseExpression(reminder.value)
      return formatCronString(cronInterval)
    } else if (reminder.type === 'date') {
      return formatISOString(reminder.value)
    }
  }

  const handleSubmitSendingPushNotification = () => {
    doREQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION({
      setRequestId: setRequestSendPushNotificationId,
      payload: {
        participantIdList: sendingList,
        title: pushNotificationTitle,
        content: pushNotificationContent,
      },
    })
  }

  useEffect(() => {
    if (!requestSendPushNotificationComplete) return
    if (!requestSendPushNotificationComplete.success) return
    if (requestSendPushNotificationComplete.error) return
    setDisplayPopupSendNotification(false)
    setDisplayPopupSendNotificationSucceed(true)
  }, [requestSendPushNotificationComplete])

  return (
    <>
      {RIF(
        displaySummaryPage,
        <ProjectSummaryPage
          {...{
            setDisplaySummaryPage,
          }}
        />,
      )}

      {RIF(
        displayPopupSendNotification,
        <PopupSendNotification
          {...{
            sendingList,
            amountOfDisableNotification,
            closeAction: () => setDisplayPopupSendNotification(false),
            setPushNotificationTitle,
            pushNotificationTitle,
            setPushNotificationContent,
            pushNotificationContent,
            handleSubmitSendingPushNotification,
          }}
        />,
      )}

      {RIF(
        renderDataDownloadPage,
        <DataDownloadPage
          closeAction={() => {
            setRenderDataDownloadPage(false)
          }}
        />,
      )}

      {RIF(
        displayPopupSendNotificationSucceed,
        <PopupSendNotificationSucceed
          {...{
            messageTitle: pushNotificationTitle,
            messageContent: pushNotificationContent,
            closeAction: () => setDisplayPopupSendNotificationSucceed(false),
          }}
        />,
      )}

      {/* {RIF(
        displaySettingsPage,
        <ProjectSettings
          {...{
            projectName: project?.name,
            closeAction: setDisplaySettingsPage,
          }}
        />,
      )} */}

      <ProjectSwitchBar {...{setRenderDataDownloadPage}} projectPanel='Overview' />
      {/* page container */}
      <div
        css={{
          width: '100%',
          minHeight: 'calc(100vh - 106px)',
          backgroundColor: color.background,
          padding: '18px 30px 39px',
          position: 'relative',
        }}
      >
        {/* sidebar */}
        <SendNotificationParticipantSelector
          {...{
            participantList,
            unfold: renderParticipantSelector,
            setRenderParticipantSelector,
            setSendingList,
            setDisplayPopupSendNotification,
            dataCollectedParticipantList,
            noDataYetParticipantList,
            participantSelectorHeader,
            participantSelectorDate,
            participantSelectorDatatype,
          }}
        />
        {/* page content title */}
        <div
          css={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            fontWeight: fontWeight.bold,
            fontSize: '20px',
            marginBottom: '12px',
          }}
        >
          Overview
        </div>
        {/* page content */}
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {/* left part of page conte */}
          <div>
            <div
              css={{
                width: '320px',
                borderRadius: '5px',
                background: color.white,
                boxShadow: '0px 4px 12px 0px #D4D4D440',
                padding: '24px',
              }}
            >
              <p
                css={{
                  fontSize: fontSize.h6,
                  fontWeight: fontWeight.thick,
                  marginBottom: '16px',
                }}
              >
                {project?.name}
              </p>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '16px',
                }}
              >
                <img width={20} src={ParticipantGreyIcon} />
                <p
                  css={{
                    marginLeft: '8px',
                    color: color.textIcon.secondary,
                    fontSize: fontSize.h7,
                  }}
                >{`Total ${participantList.length} participant(s)`}</p>
              </div>
              <ButtonReverse
                children='View & Edit Project Details'
                btnPadding='small'
                onClick={() => setDisplaySummaryPage(true)}
                fontWeight={fontWeight.medium}
              />
              {RIF(
                garminDeviceTask?.garminDevice ||
                  movesenseDeviceEnabled ||
                  garminConnectEnabled ||
                  garminDeviceEnable ||
                  dexcomDataEnabled,
                <>
                  <p
                    css={{
                      marginTop: '24px',
                      marginBottom: '8px',
                      fontSize: fontSize.h7,
                      fontWeight: fontWeight.thick,
                    }}
                  >
                    Device
                  </p>
                  {RIF(
                    garminConnectEnabled || garminDeviceEnable,
                    <div
                      css={{
                        width: '100%',
                        height: '48px',
                        padding: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '5px',
                        border: `1px solid ${color.border._160}`,
                        marginBottom: '8px',
                      }}
                    >
                      <img height={32} src={GarminDeviceImg} />
                      <p
                        css={{
                          marginLeft: '8px',
                          fontSize: fontSize.h7,
                          fontWeight: fontWeight.thick,
                        }}
                      >
                        Garmin Device
                      </p>
                    </div>,
                  )}
                  {RIF(
                    movesenseDeviceEnabled,
                    <div
                      css={{
                        width: '100%',
                        height: '48px',
                        padding: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '5px',
                        border: `1px solid ${color.border._160}`,
                        marginBottom: '8px',
                      }}
                    >
                      <img height={32} src={MovesenseImg} />
                      <p
                        css={{
                          marginLeft: '8px',
                          fontSize: fontSize.h7,
                          fontWeight: fontWeight.thick,
                        }}
                      >
                        Movesense Device
                      </p>
                    </div>,
                  )}
                  {RIF(
                    dexcomDataEnabled,
                    <div
                      css={{
                        width: '100%',
                        height: '48px',
                        padding: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '5px',
                        border: `1px solid ${color.border._160}`,
                        marginBottom: '8px',
                      }}
                    >
                      <img height={32} src={DexcomImg} />
                      <p
                        css={{
                          marginLeft: '8px',
                          fontSize: fontSize.h7,
                          fontWeight: fontWeight.thick,
                        }}
                      >
                        Dexcom Device
                      </p>
                    </div>,
                  )}
                </>,
              )}
              <p
                css={{
                  marginTop: '24px',
                  marginBottom: '8px',
                  fontSize: fontSize.h7,
                  fontWeight: fontWeight.thick,
                }}
              >{`Total ${taskList.length} Tasks`}</p>
              <>
                {taskList?.map((item, index) => {
                  const taskContentName = getTaskContentName(item.type)
                  return (
                    <div
                      key={item?.id}
                      css={{
                        border: `1px solid ${color.grey_160}`,
                        borderRadius: '5px',
                        borderLeft: item.color ? `6px solid #${item.color}` : `6px solid ${colorTranslator(index)}`,
                        padding: '8px 8px 8px 16px',
                        marginBottom: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <img
                        width='20'
                        height='20'
                        src={
                          item.type === TaskType.Todo
                            ? ParticipantTaskIcon
                            : item.type === TaskType.Timer
                            ? TimerBlackIcon
                            : item.type === TaskType.Questionnaire
                            ? SurveyBlackIcon
                            : item.type === TaskType.StopwatchGarminStream
                            ? DeviceIcon
                            : MovesenseImg
                        }
                        css={{
                          transform:
                            item.type === TaskType.StopwatchMovesenseStream ||
                            item.type === TaskType.StopwatchMovesenseLogData
                              ? 'scale(1.2)'
                              : 'scale(1)',
                        }}
                      />
                      <div css={{marginLeft: '16px', width: '100%'}}>
                        <div css={{fontSize: fontSize.h7, fontWeight: fontWeight.thick}}>{item[taskContentName]?.name}</div>
                        <div css={{display: 'flex', alignItems: 'center'}}>
                          {RIF(item.scheduleRepeat, <img src={RepeatIcon} width='14' css={{marginRight: '3px'}} />)}
                          <span css={{fontSize: '12px', color: color.grey_400}}>{item.scheduleDescription}</span>
                        </div>
                        {RIF(
                          item.type === TaskType.StopwatchMovesenseStream ||
                            item.type === TaskType.StopwatchMovesenseLogData ||
                            item.type === TaskType.StopwatchGarminStream,
                          <div
                            css={{
                              background: color.background,
                              borderRadius: '5px',
                              padding: '4px 8px',
                              marginTop: '4px',
                              color: color.grey_600,
                              fontSize: fontSize.h8,
                            }}
                          >
                            {(item.type === TaskType.StopwatchMovesenseStream ||
                              item.type === TaskType.StopwatchMovesenseLogData) &&
                              convertMovesenseDescription(item[taskContentName])}
                            {item.type === TaskType.StopwatchGarminStream && convertGarminStreamDescription(item[taskContentName])}
                          </div>,
                        )}
                        {item.reminderList?.map((reminder) => (
                          <div
                            css={{
                              background: color.background,
                              borderRadius: '5px',
                              padding: '4px 8px',
                              marginTop: '8px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img src={BellIcon} width='16' css={{marginRight: '8px'}} />
                            <p
                              css={{
                                color: color.textIcon.secondary,
                                fontSize: fontSize.h8,
                              }}
                            >
                              {generateReminderString(reminder)}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                })}
              </>
            </div>
          </div>
          {/* right part of page content */}
          {adherenceOverview()}
        </div>
      </div>
    </>
  )
}
