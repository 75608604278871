import {useEffect, useState} from 'react'
import {useNavigate, useParams, Link} from 'react-router-dom'
import {find, head, uniq} from 'lodash'
import {
  createDispatchActions,
  selectTheme,
  selectAdherenceData,
  selectMethod,
  useSelectSettings,
  selectBatchData,
  selectProjectSettings,
} from '../../store'

import {
  DailyChartBlock,
  DataDownloadSection,
  ParticipantTagsContainer,
  CustomizationPopup,
  VisualizerSideBar,
  DataDownloadPage,
} from '..'

import {DateObject} from 'react-multi-date-picker'
import {ArrowLeftIcon, ProfileBlackIcon} from '../../asset/image'
import {RIF, _, useCurrentProjectState} from '../../lib'
import {IBatch, IProject, ITag, VisualizerGraphResolution} from '../../shared/db'
import {TaskType, ParticipantItem} from '../../model'
import {AttemptRequestParticipantStatus} from './request_project_data_hook'
import {ParticipantEntireDurationBoxPlotBlock} from '../organisms/participant_entire_duration_boxplot_block'
import {VisualizerDurationResolution} from '../../lib/chart_data/model/VisualizerDurationResolution'

export const DataVisualizerPage = () => {
  /* ------------------ req ------------------ */
  const {color, fontWeight, fontSize} = selectTheme()
  const {
    doREQUEST_PROJECT_FETCH, 
    doREQUEST_PROJECT_DATA_DIGEST, 
    doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH,
  }: any = createDispatchActions()

  const [requestId, setRequestId] = useState(null)
  const [requestProjectDataDigestId, setRequestProjectDataDigestId] = useState(null)
  const [requestProjectSettingListFetchId, setRequestProjectSettingListFetchId] = useState(null)
  const [renderCustomizationPopup, setRenderCustomizationPopup] = useState(false)
  // const [renderTimeseriesPage, setRenderTimeseriesPage] = useState<boolean>(false)
  const [resolution, setResolution] = useState<VisualizerGraphResolution | VisualizerDurationResolution>(
    VisualizerGraphResolution.Daily,
  )
  /* ------------------ reducer & store basic state ------------------ */
  const navigate = useNavigate()
  const {projectId, project} = useCurrentProjectState()
  const projectSettings = projectId ? (selectProjectSettings()[projectId] as any) : undefined // FIXME: temporary cast to any to avoid invalid key type warning
  const batchId = head(project?.batchList)?.id
  const batchData = selectBatchData() as Record<string, IBatch>
  const batch = batchId ? batchData[batchId] : undefined
  const participantList = (batch?.participantList ?? []).map(
    ({id, insignia, tagList}): ParticipantItem => ({
      id,
      insignia: insignia ?? '',
      tagList: tagList ?? [],
    }),
  )
  const method = selectMethod()
  const adherenceData = projectId ? selectAdherenceData()?.[projectId] : undefined
  const garminConnect = method?.garminConnectEnable ?? false
  const garminDeviceTask = find(method?.taskList, {type: TaskType.GarminDevice})

  const [currentParticipantId, setCurrentParticipantId] = useState(useParams().participantId || '')
  const [participantInsignia, setParticipantInsignia] = useState('')
  const [participantTagList, setParticipantTagList] = useState<ITag[]>([])
  const [participantAdherence, setParticipantAdherence] = useState<Record<string, any> | undefined>()
  const [renderDataDownloadPage, setRenderDataDownloadPage] = useState(false)
  const onParticipantClicked = (participantItem: ParticipantItem) => {
    navigate(`/data_visualization/${projectId}/${participantItem.id}`)
    setCurrentParticipantId(participantItem.id)
    setParticipantInsignia(participantItem.insignia)
    setParticipantTagList(participantItem.tagList)
  }

  AttemptRequestParticipantStatus({
    requestAdherence: {durationBeforeToday: 6},
  })

  useEffect(() => {
    if (currentParticipantId && adherenceData?.projectDataDigest) {
      setParticipantAdherence(adherenceData?.projectDataDigest?.participantDataDigestMap[currentParticipantId])
    }
  }, [currentParticipantId, adherenceData?.projectDataDigest])

  // const [participantOrder, setParticipantOrder] = useState<Record<string, string>>({
  //   value: 'participant',
  //   label: 'By Participant',
  // })
  // const [dataType, setDataType] = useState<Record<string, string>>({value: 'series', label: 'Time Series'})
  // const [dataType, setDataType] = useState<Record<string, string>>({value: 'daily', label: 'Daily Summary'})
  // const handleChangeParticipantOrder = (e: Record<string, string>) => {
  //   setParticipantOrder(e)
  // }

  // const handleChangeDataType = (e: Record<string, string>) => {
  //   setDataType(e)
  // }

  const handleCloseCustomizationPopup = () => {
    setRenderCustomizationPopup(false)
  }

  const handleRenderCustomizationPopup = () => {
    setRenderCustomizationPopup(true)
  }

  // const handleCloseTimeseriesPage = () => {
  //   setRenderTimeseriesPage(false)
  //   setGraphDateFromAdherenceTable(null)
  // }

  // const handleRenderTimeseriesPage = () => {
  //   setRenderTimeseriesPage(true)
  // }

  // const participantOrderOption = [
  //   {value: 'participant', label: 'By Participant'},
  //   {value: 'group', label: 'By Group'},
  // ]

  // const dataTypeOption = [
  //   {value: 'daily', label: 'Daily Summary'},
  //   {value: 'series', label: 'Time Series'},
  // ]

  /* ------------------ default effect ------------------ */
  useEffect(() => {
    if (projectId && !project) {
      doREQUEST_PROJECT_FETCH({
        setRequestId,
        payload: {
          projectId,
        },
      })

      if (!projectSettings) {
        doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH({
          setRequestId: setRequestProjectSettingListFetchId,
          payload: {},
        })
      }
    }
  }, [projectId])

  // const [selectedDate, setSelectedDate] = useState<DateObject>(new DateObject())

  // useEffect(() => {
  //   setGraphDate(parseInt(selectedDate.format('YYMMDD')))
  // }, [selectedDate])

  const [dateRange, setDateRange] = useState([new DateObject().subtract(7, 'days'), new DateObject()])
  const graphDate = parseInt(new DateObject().format('YYMMDD'))
  // const [graphDateFromAdherenceTable, setGraphDateFromAdherenceTable] = useState<number | null>(null)
  const [graphDateRange, setGraphDateRange] = useState<number[]>(
    dateRange.map((dateObject) => parseInt(dateObject.format('YYMMDD'))),
  )
  const {betaEnabled} = useSelectSettings()

  useEffect(() => {
    // important to make it only works after already initialzied
    if (adherenceData?.projectDataDigest) {
      const currentDigestCoveredIndex = adherenceData?.projectDataDigest?.coveredYYMMIndex
      const indexList = uniq(graphDateRange.map((date: number) => Math.floor(date / 100))).filter((yymmIndex) => {
        if (currentDigestCoveredIndex && currentDigestCoveredIndex.includes(yymmIndex)) {
          return false
        }
        return true
      })

      const requestTaskData = !(currentDigestCoveredIndex.length > 0)

      if (indexList.length > 0) {
        doREQUEST_PROJECT_DATA_DIGEST({
          setRequestId: setRequestProjectDataDigestId,
          payload: {
            projectId,
            yymmIndexList: indexList,
            requestTaskData,
            requestGarminData: garminConnect || garminDeviceTask !== undefined,
            requestDexcomData: method.dexcomIntegrationId !== null,
            garminTaskId: garminDeviceTask?.id,
          },
        })
      }
    }
  }, [graphDateRange])

  return RIF(
    betaEnabled,
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '104',
        width: '100vw',
        height: '100vh',
        background: color.background,
      }}
    >
      {RIF(
        renderDataDownloadPage,
        <DataDownloadPage
          {...{
            closeAction: () => {
              setRenderDataDownloadPage(false)
            },
          }}
        />,
      )}

      {/* {RIF(
        renderTimeseriesPage,
        <DataVisualizerTimeseriesPage
          {...{
            // batchId,
            participantList,
            allParticipantTagList,
            initGraphDate: graphDate,
            handleCloseTimeseriesPage,
            onParticipantClicked,
            participantInsignia,
            participantTagList,
            currentParticipantId,
            graphDateFromAdherenceTable,
          }}
        />,
      )} */}
      {RIF(
        renderCustomizationPopup,
        <CustomizationPopup
          {...{
            handleCloseCustomizationPopup,
            resolution,
          }}
        />,
      )}
      {/* title bar, Link */}
      <div
        css={{
          width: '100vw',
          height: '56px',
          boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.04)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: color.white,
          padding: '8px 32px 8px 16px',
          zIndex: 99,
          position: 'relative',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div
            css={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingRight: '8px',
              borderRight: `1px solid ${color.grey_100}`,
            }}
          >
            <Link
              css={{
                width: '28px',
                height: '28px',
                borderRadius: '5px',
                backgroundColor: color.white,
                backgroundImage: `url(${ArrowLeftIcon})`,
                backgroundPosition: 'center',
                backgroundSize: '20px',
                backgroundRepeat: 'no-repeat',
                ':hover': {
                  backgroundColor: color.hover,
                },
              }}
              to={`/adherence/${projectId}`}
            />
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '8px',
              height: '100%',
            }}
          >
            <p
              css={{
                fontWeight: fontWeight.thick,
                margin: '0 8px',
              }}
            >
              Data Visualization
            </p>

            <Link
              to={`/data_visualization_timeseries/${projectId}/${currentParticipantId}/${graphDate}`}
              css={{
                margin: '0 8px',
                fontWeight: fontWeight.thick,
                fontSize: fontSize.h7,
                borderRadius: '5px',
                border: `1px solid ${color.borderLight}`,
                backgroundColor: color.white,
                cursor: 'pointer',
                padding: '4px 16px',
                ':hover': {
                  background: color.grey_100,
                },
              }}
            >
              Time Series
            </Link>
            {/* <Select
              value={participantOrder}
              onChange={handleChangeParticipantOrder}
              options={participantOrderOption}
              css={{
                margin: '0 8px',
                width: '160px',
                fontSize: fontSize.h7,
              }}
            />
            <Select
              value={dataType}
              onChange={handleChangeDataType}
              options={dataTypeOption}
              css={{
                margin: '0 8px',
                width: '160px',
                fontSize: fontSize.h7,
              }}
            />
            <div
              css={{
                position: 'relative',
                margin: '0 8px',
              }}
            >
              <DatePicker
                value={dataType.value === 'daily' ? dateRange : selectedDate}
                onChange={(dates) => {
                  if (dataType.value === 'daily') {
                    if (isArray(dates) && dates.length === 2) {
                      setDateRange(dates)
                      setGraphDateRange(dates.map((date) => parseInt(date.format('YYMMDD'))))
                    }
                  } else {
                    if (dates instanceof DateObject) {
                      setSelectedDate(dates)
                    }
                  }
                }}
                range={dataType.value === 'daily'}
                rangeHover={dataType.value === 'daily'}
                format='MMM D, YYYY'
                maxDate={new Date()}
                style={{
                  padding: '9px 16px 9px 32px',
                  width: '200px',
                  height: '38px',
                  fontWeight: fontWeight.medium,
                  fontSize: fontSize.h7,
                  border: `1px solid ${color.grey_160}`,
                  color: 'black',
                  cursor: 'pointer',
                }}
              />
              <img
                src={CalendarGreyIcon}
                width={16}
                css={{
                  position: 'absolute',
                  top: '12px',
                  left: '12px',
                }}
              />
            </div>
            <button
              onClick={handleRenderCustomizationPopup}
              css={{
                height: '38px',
                width: '38px',
                margin: '0 8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff',
                border: '1px solid',
                borderColor: color.grey_160,
                cursor: 'pointer',
                borderRadius: '5px',
                ':hover': {
                  borderColor: `${darken(color.grey_160, 10)}`,
                  background: color.hover,
                },
              }}
            >
              <img src={CustomizeVisualizerIcon} width={20} />
            </button>*/}
          </div>
        </div>
        <DataDownloadSection />
      </div>
      {/* page container */}
      <div css={{display: 'flex'}}>
        <VisualizerSideBar
          {...{
            handleRenderCustomizationPopup,
            participantList,
            // allParticipantTagList,
            onParticipantClicked,
            setDateRange,
            setGraphDateRange,
            // setSelectedDate,
            dateRange,
            // selectedDate,
            currentParticipantId,
            resolution,
            setResolution,
            // setRenderTimeseriesPage,
          }}
        />

        {/* <ParticipantList
          {...{
            participantList,
            allParticipantTagList,
            onParticipantClicked,
          }}
        /> */}
        <div
          css={{
            height: 'calc(100vh - 56px)',
            width: 'calc(100% - 216px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              padding: '24px 32px',
            }}
          >
            <img src={ProfileBlackIcon} width={24} />
            <p
              css={{
                fontSize: fontSize.h3,
                fontWeight: fontWeight.thick,
              }}
            >
              {participantInsignia}
            </p>
            <ParticipantTagsContainer tags={participantTagList} showTrailingTagIcon={true} />
          </div>
          {RIF(
            resolution == VisualizerGraphResolution.Daily || resolution == VisualizerGraphResolution.Weekly,
            <DailyChartBlock
              {...{
                participantId: currentParticipantId,
                dataDateRange: graphDateRange,
                participantAdherence,
                resolution,
                // setGraphDateFromAdherenceTable,
                // setRenderTimeseriesPage,
              }}
            />,
          )}

          {RIF(
            resolution == VisualizerDurationResolution.EntireDuration,
            <ParticipantEntireDurationBoxPlotBlock
              {...{
                participantId: currentParticipantId,
                dataDateRange: graphDateRange,
              }}
            />,
          )}
        </div>
      </div>
    </div>,
  )
}
