import {useState, useEffect, useMemo} from 'react'
import {
  createDispatchActions,
  selectRequestComplete,
  selectMethod,
  selectAdherenceData,
  selectProjectSettings,
  selectParticipantDailyData,
} from '../../store'
import {useParams} from 'react-router-dom'
import {
  t,
  HeartRateType,
  StressType,
  AdherenceDataTransformer,
  DailySummaryAdherenceData,
  CaloriesType,
  StressValueType,
  _,
  ArrayDescriptionType,
  useCurrentProjectState,
} from '../../lib'
import {GarminConnectHookType} from '../../shared/api'
import {VisualizerGraphResolution, VisualizerGraphDataType} from '../../shared/db'
import {
  GarminDailySummaryAdherenceChart,
  GarminDailySummaryStepChart,
  // GarminDailySummaryStressChart,
  GarminDailySummaryHeartRateChart,
  GarminDailySleepChart,
  GarminDailySummaryStresscCombinationChart,
  GarminDailySummaryCaloriesChart,
  GarminDailySummaryWearTimeChart,
} from '..'
import {
  DailyChartData,
  DailyChartDataWithType,
  DailyCompositeChartData,
  DailyTwoValueChartData,
  TaskStateType,
  TaskType,
  Adherence,
} from '../../model'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {VisualizerDurationResolution} from '../../lib/chart_data/model/VisualizerDurationResolution'
import {DailyDataType, DbContent} from '../../store/db/db_data_setter'
import {DailyArrayDescriptionChart} from '../charts/template/daily_array_description_chart'
import {GarminDeviceDailySummary} from '../../shared/mongo'
import {getDailyDataFromDb} from '../../store/db/db_data_getter'
import {GarminConnectDailySleepDbData, GarminConnectDailySummaryDbData} from '../../store/db/model'

export interface DailyChartBlockProps {
  participantId: string
  dataDateRange: number[]
  participantAdherence?: Record<string, any>
  resolution: VisualizerGraphResolution | VisualizerDurationResolution
  // setGraphDateFromAdherenceTable: Dispatch<SetStateAction<number | null>>
  // setRenderTimeseriesPage: Dispatch<SetStateAction<boolean>>
}

const DailyGarminConnectDataTypeList = [
  VisualizerGraphDataType.GarminConnectHeartRate,
  VisualizerGraphDataType.GarminConnectStress,
  VisualizerGraphDataType.GarminConnectSteps,
  VisualizerGraphDataType.GarminConnectCalories,
  VisualizerGraphDataType.GarminDeviceWearingTime,
]

const DailyGarminDireceDataTypeList = [
  VisualizerGraphDataType.GarminDirectSteps,
  VisualizerGraphDataType.GarminDirectHeartRate,
  VisualizerGraphDataType.GarminDirectSpO2,
  VisualizerGraphDataType.GarminDirectStress,
]

export const DailyChartBlock = (props: DailyChartBlockProps) => {
  const {
    participantId,
    dataDateRange,
    resolution,
    // setGraphDateFromAdherenceTable,
    // setRenderTimeseriesPage,
  } = props
  /* ------------------ req ------------------ */
  const {
    doREQUEST_VISUALIZER_DAILY_SUMMARY_DATA_FETCH,
    doREQUEST_PARTICIPANT_GARMIN_DIRECT_DAILY_SUMMARY,
    doREQUEST_ANALYTIC_CORRELATION_INDIVIDUAL,
  }: any = createDispatchActions()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)

  /* ------------------ reducer & store basic state ------------------ */
  const method = selectMethod()

  const taskList: TaskStateType[] = useMemo(() => {
    return method?.taskList?.filter((i) => i.enabled) || []
  }, [method])
  
  const {projectId} = useCurrentProjectState()
  const projectSettings = projectId ? (selectProjectSettings()[projectId] as any) : undefined // FIXME: temporary cast to any to avoid invalid key type warning
  const projectAdherence = selectAdherenceData()?.[projectId ?? ''] ?? {}
  const adherenceList = projectAdherence.adherenceList ?? []

  const defaultVisualizerSidebarSetting = {
    Daily: [
      {type: VisualizerGraphDataType.GarminConnectHeartRate, index: 0, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminConnectStress, index: 1, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminConnectSteps, index: 2, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminConnectSleepStaging, index: 3, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminConnectCalories, index: 4, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminDeviceWearingTime, index: 5, id: t.uuid()},
    ],
    MultiDay: [
      {type: VisualizerGraphDataType.GarminConnectHeartRate, index: 0, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminConnectStress, index: 1, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminConnectSteps, index: 2, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminConnectSleepStaging, index: 3, id: t.uuid()},
    ],
    Weekly: [
      {type: VisualizerGraphDataType.GarminConnectHeartRate, index: 0, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminConnectStress, index: 1, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminConnectSteps, index: 2, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminConnectSleepStaging, index: 3, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminConnectCalories, index: 4, id: t.uuid()},
      {type: VisualizerGraphDataType.GarminDeviceWearingTime, index: 5, id: t.uuid()},
    ],
    Monthly: [],
    TimeSeries: [],
    EntireDuration: [],
  }
  const [visualizerSidebarSetting, setVisualizerSidebarSetting] = useState<
    {type: VisualizerGraphDataType; index: number; id: string}[]
  >([])

  useEffect(() => {
    if (!resolution) return
    if (!projectSettings?.visualizerSidebarSetting?.[resolution]) {
      return setVisualizerSidebarSetting(defaultVisualizerSidebarSetting[resolution])
    }
    setVisualizerSidebarSetting(
      projectSettings.visualizerSidebarSetting[resolution].map((item: {type: string; index: number}) => ({
        ...item,
        id: t.uuid(),
      })),
    )
  }, [projectSettings, resolution])

  // const mockDailyStepData: DailyChartData[] = [
  //   {yymmdd: 231015, value: 3000},
  //   {yymmdd: 231016, value: 4000},
  //   {yymmdd: 231017, value: 1253},
  //   {yymmdd: 231018, value: 3632},
  //   {yymmdd: 231019, value: 5323},
  //   {yymmdd: 231020, value: 6000},
  //   {yymmdd: 231021, value: 4500},
  // ]

  // const mockDailyHrData: DailyChartDataWithType<HeartRateType>[] = [
  //   {yymmdd: 231015, value: 100, type: HeartRateType.Max},
  //   {yymmdd: 231015, value: 60, type: HeartRateType.Min},
  //   {yymmdd: 231015, value: 50, type: HeartRateType.Resting},
  //   {yymmdd: 231015, value: 70, type: HeartRateType.Avg},

  //   {yymmdd: 231016, value: 120, type: HeartRateType.Max},
  //   {yymmdd: 231016, value: 30, type: HeartRateType.Min},
  //   {yymmdd: 231016, value: 56, type: HeartRateType.Resting},
  //   {yymmdd: 231016, value: 76, type: HeartRateType.Avg},

  //   {yymmdd: 231017, value: 160, type: HeartRateType.Max},
  //   {yymmdd: 231017, value: 68, type: HeartRateType.Min},
  //   {yymmdd: 231017, value: 57, type: HeartRateType.Resting},
  //   {yymmdd: 231017, value: 79, type: HeartRateType.Avg},

  //   {yymmdd: 231018, type: HeartRateType.Max},
  //   {yymmdd: 231018, type: HeartRateType.Min},
  //   {yymmdd: 231018, type: HeartRateType.Resting},
  //   {yymmdd: 231018, type: HeartRateType.Avg},

  //   {yymmdd: 231019, value: 150, type: HeartRateType.Max},
  //   {yymmdd: 231019, value: 80, type: HeartRateType.Min},
  //   {yymmdd: 231019, value: 30, type: HeartRateType.Resting},
  //   {yymmdd: 231019, value: 72, type: HeartRateType.Avg},

  //   {yymmdd: 231020, value: 100, type: HeartRateType.Max},
  //   {yymmdd: 231020, value: 60, type: HeartRateType.Min},
  //   {yymmdd: 231020, value: 50, type: HeartRateType.Resting},
  //   {yymmdd: 231020, value: 70, type: HeartRateType.Avg},
  // ]

  // const mockDailyStressData: DailyChartDataWithType<StressType>[] = [
  //   {yymmdd: 231015, value: 50, type: StressType.High},
  //   {yymmdd: 231015, value: 60, type: StressType.Medium},
  //   {yymmdd: 231015, value: 100, type: StressType.Low},

  //   {yymmdd: 231016, value: 56, type: StressType.High},
  //   {yymmdd: 231016, value: 120, type: StressType.Medium},
  //   {yymmdd: 231016, value: 30, type: StressType.Low},

  //   {yymmdd: 231017, value: 57, type: StressType.High},
  //   {yymmdd: 231017, value: 160, type: StressType.Medium},
  //   {yymmdd: 231017, value: 68, type: StressType.Low},

  //   {yymmdd: 231018, type: StressType.High},
  //   {yymmdd: 231018, type: StressType.Medium},
  //   {yymmdd: 231018, type: StressType.Low},

  //   {yymmdd: 231019, value: 30, type: StressType.High},
  //   {yymmdd: 231019, value: 150, type: StressType.Medium},
  //   {yymmdd: 231019, value: 80, type: StressType.Low},

  //   {yymmdd: 231020, value: 100, type: StressType.Low},
  //   {yymmdd: 231020, value: 60, type: StressType.Medium},
  //   {yymmdd: 231020, value: 50, type: StressType.Low},
  // ]

  // const mockSleepData: DailyTwoValueChartData[] = [
  //   {yymmdd: 231015, value: 3000, value2: 80},
  //   {yymmdd: 231016, value: 4000, value2: 76},
  //   {yymmdd: 231017, value: 1253, value2: 90},
  //   {yymmdd: 231018, value: 3632, value2: 82},
  //   {yymmdd: 231019, value: 5323, value2: 59},
  //   {yymmdd: 231020, value: 6000, value2: 60},
  //   {yymmdd: 231021, value: 4500, value2: 45},
  // ]

  const emptyData = () =>
    t.yymmddRange(dataDateRange[0], dataDateRange[1]).map((yymmdd) => {
      return {
        yymmdd,
      }
    })
  const [dailyWearTimeDataList, setDailyWearTimeDataList] = useState<DailyChartData[]>(emptyData())
  const [dailyStepDataList, setDailyStepDataList] = useState<DailyChartData[]>(emptyData())
  const [dailyHrDataList, setDailyHrDataList] = useState<DailyChartDataWithType<HeartRateType>[]>(emptyData())
  // const [dailyStressDataList, setDailyStressDataList] = useState<DailyChartDataWithType<StressType>[]>(emptyData())
  const [dailyStressCombinationDataList, setDailyStressCombinationDataList] = useState<{
    data: DailyChartDataWithType<StressType>[]
    data2: DailyChartDataWithType<StressValueType>[]
  }>({data: emptyData(), data2: emptyData()})
  const [dailySleepDataList, setDailySleepDataList] = useState<DailyTwoValueChartData[]>(emptyData())
  const [dailyCaloriesDataList, setDailyCaloriesDataList] = useState<DailyChartDataWithType<CaloriesType>[]>(
    emptyData(),
  )
  const [dailyAdherenceData, setDailyAdherenceData] = useState<DailySummaryAdherenceData>({
    dehydratedTaskList: [],
    dailySummaryAdherenceList: [],
  })

  const participantDailyData = selectParticipantDailyData(participantId)
  const [garminDirectStepData, setGarminDirectStepData] = useState<DailyChartData[]>(emptyData())
  const [garminDirectHrData, setGarminDirectHrData] = useState<DailyChartDataWithType<ArrayDescriptionType>[]>(
    emptyData(),
  )
  const [garminDirectStressData, setGarminDirectStressData] = useState<DailyChartDataWithType<ArrayDescriptionType>[]>(
    emptyData(),
  )
  const [garminDirectPulseOxData, setGarminDirectPulseOxData] = useState<
    DailyChartDataWithType<ArrayDescriptionType>[]
  >(emptyData())

  interface SourceDataScope {
    garminDirectDailySummary: boolean
    garminConnectDailySummary: boolean
    garminConnectDailySleep: boolean
  }

  const sourceDataScope = useMemo<SourceDataScope>(() => {
    if (visualizerSidebarSetting) {
      const garminDirectDataTypeSelected = visualizerSidebarSetting.some((setting) => {
        return DailyGarminDireceDataTypeList.includes(setting.type)
      })

      const garminConnectDataTypeSelected = visualizerSidebarSetting.some((setting) => {
        return DailyGarminConnectDataTypeList.includes(setting.type)
      })

      const garminConnectSleepDataSelected = visualizerSidebarSetting.some((setting) => {
        return setting.type == VisualizerGraphDataType.GarminConnectSleepStaging
      })

      return {
        garminDirectDailySummary: garminDirectDataTypeSelected,
        garminConnectDailySummary: garminConnectDataTypeSelected,
        garminConnectDailySleep: garminConnectSleepDataSelected,
      }
    }
    return {
      garminDirectDailySummary: false,
      garminConnectDailySummary: false,
      garminConnectDailySleep: false,
    }
  }, [visualizerSidebarSetting])

  /* ------------------ default effect ------------------ */
  useEffect(() => {
    if (participantId.length > 0 && sourceDataScope) {
      const [yymmddIndexStart, yymmddIndexEnd] = dataDateRange

      // fetch Garmin connect daily summary data
      if (sourceDataScope.garminConnectDailySummary) {
        fetchGarminConnectDailySummary(DailyDataType.GarminConnectDailySummary, yymmddIndexStart, yymmddIndexEnd)
      }

      // fetch Garmin connect daily sleep data
      if (sourceDataScope.garminConnectDailySleep) {
        fetchGarminConnectDailySummary(DailyDataType.GarminConnectDailySleep, yymmddIndexStart, yymmddIndexEnd)
      }

      // fetch Garmin direct data
      if (sourceDataScope.garminDirectDailySummary) {
        fetchGarminDirectDailySummary(yymmddIndexStart, yymmddIndexEnd)
      }
    }
  }, [participantId, dataDateRange, sourceDataScope])

  const fetchGarminConnectDailySummary = (
    dateType: DailyDataType,
    yymmddIndexStart: number,
    yymmddIndexEnd: number,
  ) => {
    const fetchedIndexSet = participantDailyData?.[dateType]
      ? new Set(Object.keys(participantDailyData?.[dateType]).map((yymmdd) => +yymmdd))
      : new Set()
    const yymmddIndexToFetch = t
      .yymmddRange(yymmddIndexStart, yymmddIndexEnd)
      .filter((yymmdd) => !fetchedIndexSet.has(yymmdd))
      .sort((a, b) => a - b)

    const fetchDataType =
      dateType == DailyDataType.GarminConnectDailySummary
        ? GarminConnectHookType.HealthDailies
        : GarminConnectHookType.HealthSleeps

    if (yymmddIndexToFetch.length) {
      doREQUEST_VISUALIZER_DAILY_SUMMARY_DATA_FETCH({
        setRequestId,
        payload: {
          participantId: participantId,
          yymmddIndexStart: yymmddIndexToFetch[0],
          yymmddIndexEnd: yymmddIndexToFetch[yymmddIndexToFetch.length - 1],
          dataType: fetchDataType,
        },
      })
    }
  }

  const fetchGarminDirectDailySummary = (yymmddIndexStart: number, yymmddIndexEnd: number) => {
    const fetchedGarminDirectIndexSet = participantDailyData?.[DailyDataType.GarminDirectDailySummary]
      ? new Set(Object.keys(participantDailyData?.[DailyDataType.GarminDirectDailySummary]).map((yymmdd) => +yymmdd))
      : new Set()
    const garminDirectDateToFetch = t
      .yymmddRange(yymmddIndexStart, yymmddIndexEnd)
      .filter((yymmdd) => !fetchedGarminDirectIndexSet.has(yymmdd))
    if (garminDirectDateToFetch.length) {
      doREQUEST_PARTICIPANT_GARMIN_DIRECT_DAILY_SUMMARY({
        setRequestId,
        payload: {
          participantId: participantId,
          yymmddIndexStart: garminDirectDateToFetch[0],
          yymmddIndexEnd: garminDirectDateToFetch[garminDirectDateToFetch.length - 1],
        },
      })
    }
  }

  useEffect(() => {
    if(!projectAdherence || !method) return 
    const participantAdherence = _.find(projectAdherence.adherenceList, ['participantId', participantId])
    const taskDataDates = props.participantAdherence?.taskDataDates
    const taskList: TaskStateType[] = method?.taskList?.filter((i) => i.enabled) || []
    const garminConnectEnabled: boolean = method?.garminConnectEnable

    const adherenceDataTransformer = new AdherenceDataTransformer(
      taskDataDates,
      taskList,
      participantAdherence as Adherence,
      garminConnectEnabled,
    )
    adherenceDataTransformer.toDailySummary(dataDateRange).then((data) => {
      setDailyAdherenceData(data)
    })
  }, [projectAdherence, participantId, dataDateRange, method])

  useEffect(() => {
    const [yymmddIndexStart, yymmddIndexEnd] = dataDateRange
    const yymmddIndexList = t.yymmddRange(yymmddIndexStart, yymmddIndexEnd)

    if (sourceDataScope.garminDirectDailySummary) {
      fetchGarminDirectDailySummaryFromDbToPlot(participantId, yymmddIndexList)
    }

    if (sourceDataScope.garminConnectDailySummary) {
      fetchGarminConnectDailySummaryFromDbToPlot(participantId, yymmddIndexList)
    }

    if (sourceDataScope.garminConnectDailySleep) {
      fetchGarminConnectDailySleepFromDbToPlot(participantId, yymmddIndexList)
    }
  }, [participantDailyData, dataDateRange, visualizerSidebarSetting])

  const fetchGarminDirectDailySummaryFromDbToPlot = async (participantId: string, yymmddIndexList: number[]) => {
    const yymmddIndexDbContent = await getYymmddIndexDbContent<GarminDeviceDailySummary>(
      DailyDataType.GarminDirectDailySummary,
      participantId,
      yymmddIndexList,
    )
    visualizerSidebarSetting.forEach((setting) => {
      if (setting.type === VisualizerGraphDataType.GarminDirectSteps) {
        plotGarminDirectSteps(yymmddIndexDbContent)
      } else if (setting.type === VisualizerGraphDataType.GarminDirectHeartRate) {
        plotGarminDirectHeartRate(yymmddIndexDbContent)
      } else if (setting.type === VisualizerGraphDataType.GarminDirectStress) {
        plotGarminDirectStress(yymmddIndexDbContent)
      } else if (setting.type === VisualizerGraphDataType.GarminDirectSpO2) {
        plotGarminDirectPulseOx(yymmddIndexDbContent)
      }
    })
  }

  const fetchGarminConnectDailySummaryFromDbToPlot = async (participantId: string, yymmddIndexList: number[]) => {
    const yymmddIndexDbContent = await getYymmddIndexDbContent<GarminConnectDailySummaryDbData>(
      DailyDataType.GarminConnectDailySummary,
      participantId,
      yymmddIndexList,
    )
    visualizerSidebarSetting.forEach((setting) => {
      if (setting.type === VisualizerGraphDataType.GarminConnectSteps) {
        plotGarminConnectSteps(yymmddIndexDbContent)
      } else if (setting.type === VisualizerGraphDataType.GarminConnectHeartRate) {
        plotGarminConnectHeartRate(yymmddIndexDbContent)
      } else if (setting.type === VisualizerGraphDataType.GarminConnectStress) {
        plotGarminConnectStress(yymmddIndexDbContent)
      } else if (setting.type === VisualizerGraphDataType.GarminConnectCalories) {
        plotGarminConnectCalories(yymmddIndexDbContent)
      } else if (setting.type === VisualizerGraphDataType.GarminDeviceWearingTime) {
        plotGarminConnectWearTime(yymmddIndexDbContent)
      }
    })
  }

  const fetchGarminConnectDailySleepFromDbToPlot = async (participantId: string, yymmddIndexList: number[]) => {
    const yymmddIndexDbContent = await getYymmddIndexDbContent<GarminConnectDailySleepDbData>(
      DailyDataType.GarminConnectDailySleep,
      participantId,
      yymmddIndexList,
    )
    plotGarminConnectSleep(yymmddIndexDbContent)
  }

  interface YymmddIndexDbContent<T> {
    yymmdd: number
    dbContent: DbContent<DailyDataType, T> | undefined
  }

  async function getYymmddIndexDbContent<T>(dataType: DailyDataType, participantId: string, yymmddIndexList: number[]) {
    const garminDailyDataMap = participantDailyData?.[dataType]
    if (garminDailyDataMap) {
      const promises = yymmddIndexList.map(async (yymmdd) => {
        if (garminDailyDataMap[yymmdd]) {
          const dbContent = await getDailyDataFromDb<T>(participantId, dataType, yymmdd)
          return {yymmdd, dbContent}
        }
        return {yymmdd, dbContent: undefined}
      })

      const promiseResult = await Promise.all(promises)
      return promiseResult.sort((a, b) => {
        return a.yymmdd - b.yymmdd
      })
    }
    return yymmddIndexList.map((yymmdd) => {
      return {yymmdd, dbContent: undefined}
    })
  }

  const processData = <T, U>(
    yymmddIndexDbContentList: YymmddIndexDbContent<T>[],
    processDbContent: (dbContent: T | undefined, accumulators: U) => U,
    finalizeData: (
      accumulators: U,
      currentYymmddInexStart: number,
    ) => (DailyChartData | DailyChartDataWithType | DailyTwoValueChartData)[],
  ) => {
    const daysToAverage = resolution == VisualizerGraphResolution.Daily ? 1 : 7
    const results: (DailyChartData | DailyChartDataWithType | DailyTwoValueChartData)[] = []
    const lastYymmdd = yymmddIndexDbContentList[yymmddIndexDbContentList.length - 1].yymmdd
    let dayIndex = 0
    let yymmddInexStart = 0
    let accumulators: U = {} as U

    for (const {yymmdd, dbContent} of yymmddIndexDbContentList) {
      dayIndex++
      accumulators = processDbContent(dbContent?.data, accumulators)
      if (dayIndex === 1) {
        yymmddInexStart = yymmdd
      }
      if (dayIndex === daysToAverage || yymmdd === lastYymmdd) {
        results.push(...finalizeData(accumulators, yymmddInexStart))
        accumulators = {} as U
        dayIndex = 0
      }
    }

    return results
  }

  const plotGarminConnectSteps = (
    yymmddIndexDbContentList: YymmddIndexDbContent<GarminConnectDailySummaryDbData>[],
  ) => {
    const results = processData(
      yymmddIndexDbContentList,
      (dbContent, accumulators: {stepSum: number; dataSumIndex: number}) => {
        if (dbContent) {
          accumulators.stepSum = (accumulators.stepSum || 0) + dbContent.steps
          accumulators.dataSumIndex = (accumulators.dataSumIndex || 0) + 1
        }
        return accumulators
      },
      (accumulators, currentYymmddInexStart) => {
        const stepData: DailyChartData = {yymmdd: currentYymmddInexStart}
        if (accumulators.stepSum) {
          stepData.value = Math.floor(accumulators.stepSum / accumulators.dataSumIndex)
        }
        return [stepData]
      },
    )
    setDailyStepDataList(results as DailyChartData[])
  }

  const plotGarminConnectCalories = (
    yymmddIndexDbContentList: YymmddIndexDbContent<GarminConnectDailySummaryDbData>[],
  ) => {
    const results = processData(
      yymmddIndexDbContentList,
      (dbContent, accumulators: {calorySum: number; dataSumIndex: number}) => {
        if (dbContent) {
          accumulators.calorySum = (accumulators.calorySum || 0) + dbContent.calories
          accumulators.dataSumIndex = (accumulators.dataSumIndex || 0) + 1
        }
        return accumulators
      },
      (accumulators, currentYymmddInexStart) => {
        const caloriesData: DailyChartData = {yymmdd: currentYymmddInexStart}
        if (accumulators.calorySum) {
          caloriesData.value = Math.floor(accumulators.calorySum / accumulators.dataSumIndex)
        }
        return [caloriesData]
      },
    )
    setDailyCaloriesDataList(results as DailyChartData[])
  }

  const plotGarminConnectWearTime = (
    yymmddIndexDbContentList: YymmddIndexDbContent<GarminConnectDailySummaryDbData>[],
  ) => {
    const results = processData(
      yymmddIndexDbContentList,
      (dbContent, accumulators: {wearTimeSum: number; dataSumIndex: number}) => {
        if (dbContent && dbContent.heartRate?.sampleCount) {
          accumulators.wearTimeSum = (accumulators.wearTimeSum || 0) + dbContent.heartRate.sampleCount / 240
          accumulators.dataSumIndex = (accumulators.dataSumIndex || 0) + 1
        }
        return accumulators
      },
      (accumulators, currentYymmddInexStart) => {
        const wearTimeData: DailyChartData = {yymmdd: currentYymmddInexStart}
        if (accumulators.wearTimeSum) {
          wearTimeData.value = +(accumulators.wearTimeSum / accumulators.dataSumIndex).toFixed(2)
        }
        return [wearTimeData]
      },
    )
    setDailyWearTimeDataList(results as DailyChartData[])
  }

  const plotGarminConnectHeartRate = (
    yymmddIndexDbContentList: YymmddIndexDbContent<GarminConnectDailySummaryDbData>[],
  ) => {
    const results = processData(
      yymmddIndexDbContentList,
      (
        dbContent,
        accumulators: {maxSum: number; minSum: number; avgSum: number; restSum: number; dataSumIndex: number},
      ) => {
        if (dbContent && dbContent.heartRate) {
          accumulators.maxSum = (accumulators.maxSum || 0) + dbContent.heartRate.max
          accumulators.minSum = (accumulators.minSum || 0) + dbContent.heartRate.min
          accumulators.avgSum = (accumulators.avgSum || 0) + dbContent.heartRate.avg
          accumulators.restSum = (accumulators.restSum || 0) + dbContent.heartRate.resting
          accumulators.dataSumIndex = (accumulators.dataSumIndex || 0) + 1
        }
        return accumulators
      },
      (accumulators, currentYymmddInexStart) => {
        const hrData: DailyChartDataWithType<HeartRateType>[] = []
        if (accumulators.dataSumIndex) {
          hrData.push({
            yymmdd: currentYymmddInexStart,
            type: HeartRateType.Max,
            value: Math.floor(accumulators.maxSum / accumulators.dataSumIndex),
          })
          hrData.push({
            yymmdd: currentYymmddInexStart,
            type: HeartRateType.Min,
            value: Math.floor(accumulators.minSum / accumulators.dataSumIndex),
          })
          hrData.push({
            yymmdd: currentYymmddInexStart,
            type: HeartRateType.Avg,
            value: Math.floor(accumulators.avgSum / accumulators.dataSumIndex),
          })
          hrData.push({
            yymmdd: currentYymmddInexStart,
            type: HeartRateType.Resting,
            value: Math.floor(accumulators.restSum / accumulators.dataSumIndex),
          })
        } else {
          hrData.push({
            yymmdd: currentYymmddInexStart,
          })
        }
        return hrData
      },
    )
    setDailyHrDataList(results as DailyChartDataWithType<HeartRateType>[])
  }

  const plotGarminConnectStress = (
    yymmddIndexDbContentList: YymmddIndexDbContent<GarminConnectDailySummaryDbData>[],
  ) => {
    const durationResults = processData(
      yymmddIndexDbContentList,
      (
        dbContent,
        accumulators: {
          highDurationSum: number
          mediumDurationSum: number
          lowDurationSum: number
          dataSumIndex: number
        },
      ) => {
        if (dbContent && dbContent.stress) {
          accumulators.highDurationSum = (accumulators.highDurationSum || 0) + dbContent.stress.durationHigh
          accumulators.mediumDurationSum = (accumulators.mediumDurationSum || 0) + dbContent.stress.durationMed
          accumulators.lowDurationSum = (accumulators.lowDurationSum || 0) + dbContent.stress.durationLow
          accumulators.dataSumIndex = (accumulators.dataSumIndex || 0) + 1
        }
        return accumulators
      },
      (accumulators, currentYymmddInexStart) => {
        const durationData: DailyChartDataWithType<StressType>[] = []
        if (accumulators.dataSumIndex) {
          durationData.push({
            yymmdd: currentYymmddInexStart,
            type: StressType.High,
            value: accumulators.highDurationSum,
          })
          durationData.push({
            yymmdd: currentYymmddInexStart,
            type: StressType.Medium,
            value: accumulators.mediumDurationSum,
          })
          durationData.push({
            yymmdd: currentYymmddInexStart,
            type: StressType.Low,
            value: accumulators.lowDurationSum,
          })
        } else {
          durationData.push({
            yymmdd: currentYymmddInexStart,
          })
        }
        return durationData
      },
    )

    const valueResults = processData(
      yymmddIndexDbContentList,
      (
        dbContent,
        accumulators: {
          maxSum: number
          avgSum: number
          dataSumIndex: number
        },
      ) => {
        if (dbContent && dbContent.stress) {
          accumulators.maxSum = (accumulators.maxSum || 0) + dbContent.stress.max
          accumulators.avgSum = (accumulators.avgSum || 0) + dbContent.stress.avg
          accumulators.dataSumIndex = (accumulators.dataSumIndex || 0) + 1
        }
        return accumulators
      },
      (accumulators, currentYymmddInexStart) => {
        const stressValueData: DailyChartDataWithType<StressValueType>[] = []
        if (accumulators.dataSumIndex) {
          stressValueData.push({
            yymmdd: currentYymmddInexStart,
            type: StressValueType.Max,
            value: Math.floor(accumulators.maxSum / accumulators.dataSumIndex),
          })
          stressValueData.push({
            yymmdd: currentYymmddInexStart,
            type: StressValueType.Avg,
            value: Math.floor(accumulators.avgSum / accumulators.dataSumIndex),
          })
        } else {
          stressValueData.push({
            yymmdd: currentYymmddInexStart,
          })
        }
        return stressValueData
      },
    )
    setDailyStressCombinationDataList({
      data: durationResults as DailyChartDataWithType<StressType>[],
      data2: valueResults as DailyChartDataWithType<StressValueType>[],
    })
  }

  const plotGarminConnectSleep = (yymmddIndexDbContentList: YymmddIndexDbContent<GarminConnectDailySleepDbData>[]) => {
    const results = processData(
      yymmddIndexDbContentList,
      (
        dbContent,
        accumulators: {durationSum: number; durationSumIndex: number; scoresSum: number; scoreSumIndex: number},
      ) => {
        if (dbContent) {
          accumulators.durationSum = (accumulators.durationSum || 0) + dbContent.duration
          accumulators.durationSumIndex = (accumulators.durationSumIndex || 0) + 1
        }

        if (dbContent && dbContent.score) {
          accumulators.scoresSum = (accumulators.scoresSum || 0) + dbContent.score
          accumulators.scoreSumIndex = (accumulators.scoreSumIndex || 0) + 1
        }
        return accumulators
      },
      (accumulators, currentYymmddInexStart) => {
        const sleepData: DailyTwoValueChartData[] = []
        if (accumulators.durationSum) {
          sleepData.push({
            yymmdd: currentYymmddInexStart,
            value: +(accumulators.durationSum / accumulators.durationSumIndex).toFixed(1),
            value2: Math.floor(accumulators.scoresSum / accumulators.scoreSumIndex),
          })
        } else {
          sleepData.push({
            yymmdd: currentYymmddInexStart,
          })
        }
        return sleepData
      },
    )

    setDailySleepDataList(results as DailyTwoValueChartData[])
  }

  const plotGarminDirectSteps = (yymmddIndexDbContentList: YymmddIndexDbContent<GarminDeviceDailySummary>[]) => {
    const results = processData(
      yymmddIndexDbContentList,
      (dbContent, accumulators: {stepSum: number; dataSumIndex: number}) => {
        if (dbContent && dbContent.step) {
          accumulators.stepSum = (accumulators.stepSum || 0) + dbContent.step?.total
          accumulators.dataSumIndex = (accumulators.dataSumIndex || 0) + 1
        }
        return accumulators
      },
      (accumulators, currentYymmddInexStart) => {
        const stepData: DailyChartData = {yymmdd: currentYymmddInexStart}
        if (accumulators.stepSum) {
          stepData.value = Math.floor(accumulators.stepSum / accumulators.dataSumIndex)
        }
        return [stepData]
      },
    )
    setGarminDirectStepData(results as DailyChartData[])
  }

  const convertGarminDeviceDailySummaryArrayDescriptionToPlotData = (
    yymmddIndexDbContentList: YymmddIndexDbContent<GarminDeviceDailySummary>[],
    dataName: 'heartRate' | 'pulseOx' | 'stress',
  ): DailyChartDataWithType<ArrayDescriptionType>[] => {
    return processData(
      yymmddIndexDbContentList,
      (dbContent, accumulators: {maxSum: number; minSum: number; meanSum: number; dataSumIndex: number}) => {
        if (dbContent) {
          const data = dbContent[dataName]
          if (data) {
            accumulators.maxSum = (accumulators.maxSum || 0) + data.max
            accumulators.minSum = (accumulators.minSum || 0) + data.min
            accumulators.meanSum = (accumulators.meanSum || 0) + data.mean
            accumulators.dataSumIndex = (accumulators.dataSumIndex || 0) + 1
          }
        }
        return accumulators
      },
      (accumulators, currentYymmddInexStart) => {
        const plotData: DailyChartDataWithType<ArrayDescriptionType>[] = []
        if (accumulators.dataSumIndex) {
          plotData.push({
            yymmdd: currentYymmddInexStart,
            type: ArrayDescriptionType.Max,
            value: Math.floor(accumulators.maxSum / accumulators.dataSumIndex),
          })
          plotData.push({
            yymmdd: currentYymmddInexStart,
            type: ArrayDescriptionType.Min,
            value: Math.floor(accumulators.minSum / accumulators.dataSumIndex),
          })
          plotData.push({
            yymmdd: currentYymmddInexStart,
            type: ArrayDescriptionType.Mean,
            value: Math.floor(accumulators.meanSum / accumulators.dataSumIndex),
          })
        } else {
          plotData.push({
            yymmdd: currentYymmddInexStart,
          })
        }
        return plotData
      },
    ) as DailyChartDataWithType<ArrayDescriptionType>[]
  }

  const plotGarminDirectHeartRate = (yymmddIndexDbContentList: YymmddIndexDbContent<GarminDeviceDailySummary>[]) => {
    const results = convertGarminDeviceDailySummaryArrayDescriptionToPlotData(yymmddIndexDbContentList, 'heartRate')
    setGarminDirectHrData(results)
  }

  const plotGarminDirectStress = (yymmddIndexDbContentList: YymmddIndexDbContent<GarminDeviceDailySummary>[]) => {
    const results = convertGarminDeviceDailySummaryArrayDescriptionToPlotData(yymmddIndexDbContentList, 'stress')
    setGarminDirectStressData(results)
  }

  const plotGarminDirectPulseOx = (yymmddIndexDbContentList: YymmddIndexDbContent<GarminDeviceDailySummary>[]) => {
    const results = convertGarminDeviceDailySummaryArrayDescriptionToPlotData(yymmddIndexDbContentList, 'pulseOx')
    setGarminDirectPulseOxData(results)
  }

  return (
    <div
      css={{
        flex: 1,
        padding: '0px 32px 60px',
      }}
    >
      <Scrollbars autoHide={true} autoHideTimeout={200}>
        <GarminDailySummaryAdherenceChart
          {...{
            height: `${
              dailyAdherenceData.dehydratedTaskList.length
                ? 107 + dailyAdherenceData.dehydratedTaskList.length * 32
                : 300
            }px`,
            width: '100%',
            data: dailyAdherenceData,
            adherenceList,
          }}
        />
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {visualizerSidebarSetting.map(({type}) => {
            switch (type) {
              case VisualizerGraphDataType.GarminConnectHeartRate:
                return (
                  <GarminDailySummaryHeartRateChart
                    key={VisualizerGraphDataType.GarminConnectHeartRate}
                    {...{
                      height: '300px',
                      width: 'calc(50% - 12px)',
                      data: dailyHrDataList,
                    }}
                  />
                )
              case VisualizerGraphDataType.GarminConnectStress:
                return (
                  <GarminDailySummaryStresscCombinationChart
                    key={VisualizerGraphDataType.GarminConnectStress}
                    {...{
                      height: '300px',
                      width: 'calc(50% - 12px)',
                      data: dailyStressCombinationDataList.data,
                      data2: dailyStressCombinationDataList.data2,
                    }}
                  />
                )
              case VisualizerGraphDataType.GarminConnectSteps:
                return (
                  <GarminDailySummaryStepChart
                    key={VisualizerGraphDataType.GarminConnectSteps}
                    {...{
                      height: '300px',
                      width: 'calc(50% - 12px)',
                      data: dailyStepDataList,
                    }}
                  />
                )
              case VisualizerGraphDataType.GarminConnectSleepStaging:
                return (
                  <GarminDailySleepChart
                    key={VisualizerGraphDataType.GarminConnectSleepStaging}
                    {...{
                      height: '300px',
                      width: 'calc(50% - 12px)',
                      data: dailySleepDataList,
                    }}
                  />
                )
              case VisualizerGraphDataType.GarminConnectCalories:
                return (
                  <GarminDailySummaryCaloriesChart
                    key={VisualizerGraphDataType.GarminConnectCalories}
                    {...{
                      height: '300px',
                      width: 'calc(50% - 12px)',
                      data: dailyCaloriesDataList,
                    }}
                  />
                )
              case VisualizerGraphDataType.GarminDeviceWearingTime:
                return (
                  <GarminDailySummaryWearTimeChart
                    key={VisualizerGraphDataType.GarminDeviceWearingTime}
                    {...{
                      height: '300px',
                      width: 'calc(50% - 12px)',
                      data: dailyWearTimeDataList,
                    }}
                  />
                )
              case VisualizerGraphDataType.GarminDirectSteps:
                return (
                  <GarminDailySummaryStepChart
                    key={VisualizerGraphDataType.GarminDirectSteps}
                    {...{
                      title: 'Garmin Direct Steps',
                      height: '300px',
                      width: 'calc(50% - 12px)',
                      data: garminDirectStepData,
                    }}
                  />
                )
              case VisualizerGraphDataType.GarminDirectHeartRate:
                return (
                  <DailyArrayDescriptionChart
                    key={VisualizerGraphDataType.GarminDirectHeartRate}
                    {...{
                      height: '300px',
                      width: 'calc(50% - 12px)',
                      data: garminDirectHrData,
                      title: 'Garmin Direct Heart Rate',
                      yAxisTitle: 'Heart Rate (bpm)',
                      yAxisMin: 30,
                    }}
                  />
                )
              case VisualizerGraphDataType.GarminDirectStress:
                return (
                  <DailyArrayDescriptionChart
                    key={VisualizerGraphDataType.GarminDirectStress}
                    {...{
                      height: '300px',
                      width: 'calc(50% - 12px)',
                      data: garminDirectStressData,
                      title: 'Garmin Direct stress',
                      yAxisTitle: 'Stress level',
                    }}
                  />
                )
              case VisualizerGraphDataType.GarminDirectSpO2:
                return (
                  <DailyArrayDescriptionChart
                    key={VisualizerGraphDataType.GarminDirectSpO2}
                    {...{
                      height: '300px',
                      width: 'calc(50% - 12px)',
                      data: garminDirectPulseOxData,
                      title: 'Garmin Direct Pulse Ox',
                      yAxisTitle: 'SpO2(%)',
                      yAxisMax: 105,
                      yAxisMin: 70,
                    }}
                  />
                )
              default:
                return
            }
          })}
        </div>
      </Scrollbars>
    </div>
  )
}
