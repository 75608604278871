import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doPROFILE_SET, doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PROFILE_CREATE = 'REQUEST_PROFILE_CREATE'
export const doREQUEST_PROFILE_CREATE = createDoRequestAction(REQUEST_PROFILE_CREATE)

export const requestProfileCreateActionCreators = {
  doREQUEST_PROFILE_CREATE,
}

export function* requestProfileCreate({payload}: any): SagaIterator {
  debug('saga*requestProfile')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        lastName: v.string().exist(),
        firstName: v.string().exist(),
        title: v.string().allow('').optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {lastName, firstName, title} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/profile-create`,
    accessToken,
    data: {
      lastName,
      firstName,
      title,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROFILE_CREATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/profile-create',
        ...result,
        error,
      }),
    )
  }

  yield put(doPROFILE_SET(result.payload))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* profileCreateSaga() {
  yield takeEvery(REQUEST_PROFILE_CREATE, requestProfileCreate)
}
