import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION = 'REQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION'
export const doREQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION = createDoRequestAction(
  REQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION,
)

export const requestParticipantListSendPushNotificationActionCreators = {
  doREQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION,
}
interface RequestParticipantListSendPushNotificationInput {
  type: string
  payload: {
    requestId: string
    participantIdList: string[]
    title: string
    content: string
  }
}

export function* requestParticipantListSendPushNotification(
  {payload, type}: RequestParticipantListSendPushNotificationInput
): SagaIterator {
  debug('saga*requestParticipantListSendPushNotification')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        participantIdList: v.array().exist(),
        title: v.string().exist(),
        content: v.string().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {participantIdList, title, content} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/participant-list-send-push-notification`,
    accessToken,
    data: {
      participantIdList,
      title,
      content,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/participant-list-send-push-notification',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* ParticipantListSendPushNotificationSaga() {
  yield takeEvery(
    REQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION, 
    requestParticipantListSendPushNotification
  )
}
