import { Dispatch, SetStateAction, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { 
  selectTheme,
  createDispatchActions,
  selectRequestComplete,
  selectWorkspaces,
} from "../../store"
import { ButtonCancel } from ".."
import {PlusBlueIcon} from '../../asset/image'
import { IProject, ProjectStatus } from "../../shared/db"
import { Scrollbars } from "react-custom-scrollbars-2"

interface PopupCreateProjectProps {
  setDisplayPopupCreateProject: Dispatch<SetStateAction<boolean>>
}

export const PopupCreateProject = (props: PopupCreateProjectProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {setDisplayPopupCreateProject} = props
  const {doREQUEST_PROJECT_CREATE}: any = createDispatchActions()
  const navigate = useNavigate()
  const workspacesState = selectWorkspaces()
  const currentWorkspace = workspacesState?.[workspacesState?.currentWorkspaceId]
  const {globalWorkspacesList} = workspacesState
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)

  const createNewProject = () => {
    doREQUEST_PROJECT_CREATE({
      setRequestId,
      payload: {
        workspaceId: currentWorkspace?.id,
        name: 'My Project'
      },
    })
  }

  const handleClose = () => {
    setDisplayPopupCreateProject(false)
  }

  useEffect(() => {
    if (!requestComplete) return
    if (!requestComplete?.success) return
    const projectId = requestComplete?.payload?.payload?.id
    if (projectId) return navigate(`/project_setup/${projectId}`)
  }, [requestComplete])

  return (
    <div css={{
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: color.surface.popup,
      zIndex: '103',
    }}>
      <div css={{
        padding: '24px',
        borderRadius: '5px',
        backgroundColor: 'white',
        width: '848px',
        height: '479px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <ButtonCancel onClick={handleClose}/>
        <p css={{
          marginTop: '16px',
          marginBottom: '24px',
          fontSize: fontSize.h5,
        }}>Get inspired to setup your project with our templates or start from scratch!</p>
        <Scrollbars 
          autoHide 
          autoHideTimeout={1000} 
          autoHideDuration={200}
        >
          <div css={{
            display: 'flex',
            height: 'calc(100% - 18px)',
            width: 'auto',
            paddingRight: '-16px',
          }}>
            <button onClick={createNewProject} css={{
              height: '100%',
              minWidth: '256px',
              marginRight: '16px',
              borderRadius: '5px',
              border: `1px dashed ${color.textIcon.link}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              backgroundColor: 'transparent',
              padding: '0 24px',
              cursor: 'pointer',
            }}>
              <img css={{marginBottom: '8px',}} src={PlusBlueIcon} width="24"/>
              <p css={{
                fontWeight: fontWeight.thick,
                fontSize: fontSize.h5,
                color: color.textIcon.link,
                marginBottom: '8px',
              }}>Start from scratch</p>
              <p css={{
                fontSize: fontSize.h7,
                color: color.textIcon.light,
              }}>Setup your project based on your research protocol.  </p>
            </button>
            {globalWorkspacesList.map(workspace => {
              return workspace.projectList
                .filter(project => project.status !== ProjectStatus.Deleted)
                .map(project => (
                  <TemplateProjectCard {...{
                    project,
                    handleClick: () => {return},
                  }}/>
                ))
            })}
          </div>
        </Scrollbars>
      </div>
    </div>
  )
}

interface TemplateProjectCardProps {
  project: IProject
}

const TemplateProjectCard = (props: TemplateProjectCardProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {project} = props
  const {doREQUEST_PROJECT_DUPLICATE}: any = createDispatchActions()
  const navigate = useNavigate()
  const workspacesState = selectWorkspaces()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)

  const duplicateTemplateProject = () => {
    if (!workspacesState?.currentWorkspaceId) return

    doREQUEST_PROJECT_DUPLICATE({
      setRequestId,
      payload: {
        projectId: project.id,
        projectName: project.name,
        workspaceId: workspacesState.currentWorkspaceId,
      },
    })
  }

  useEffect(() => {
    if (!requestComplete) return
    if (!requestComplete?.success) return
    const projectId = requestComplete?.payload?.payload?.id
    if (projectId) return navigate(`/project_setup/${projectId}`)
  }, [requestComplete])

  return (
    <button onClick={duplicateTemplateProject} css={{
      height: '100%',
      minWidth: '256px',
      marginRight: '16px',
      borderRadius: '5px',
      border: `1px solid ${color.border._80}`,
      backgroundColor: 'transparent',
      padding: '16px',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <div css={{
        width: '100%',
        display: 'flex',
      }}>
        <p css={{
          fontWeight: fontWeight.thick,
          fontSize: fontSize.h7,
          color: color.tag.blue.dark,
          padding: '4px 8px',
          borderRadius: '5px',
          backgroundColor: color.tag.blue.light,
          display: 'inline-block',
        }}>Template</p>
      </div>
      <div css={{
        width: '100%',
        height: '120px',
        backgroundColor: color.background
      }}/>
      <div>
        <p css={{
          marginBottom: '8px',
          alignText: 'center',
        }}>{project.name}</p>
        <p css={{
          fontSize: fontSize.h7,
          color: color.textIcon.light,
        }}>{project.description}</p>
      </div>
      <p css={{
        fontSize: fontSize.h7,
        fontWeight: fontWeight.thick,
        color: color.textIcon.link,
      }}>Use this Template</p>
    </button>
  )
}