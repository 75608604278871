import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'
import {doREQUEST_ERROR, doREQUEST_COMPLETE, doMETHOD_SET} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_METHOD_UPDATE = 'REQUEST_METHOD_UPDATE'
export const doREQUEST_METHOD_UPDATE = createDoRequestAction(REQUEST_METHOD_UPDATE)

export const requestMethodUpdateActionCreators = {
  doREQUEST_METHOD_UPDATE,
}

export function* requestMethodUpdate({payload}: any): SagaIterator {
  debug('saga*requestMethodUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        methodId: v.string().uuid().exist(),
        name: v.string().optional().allow('', null),
        description: v.string().optional().allow('', null),
        garminConnectEnable: v.boolean().optional(),
        garminDeviceEnable: v.boolean().optional(),
        garminStreamEnable: v.boolean().optional(),
        movesenseDeviceEnable: v.boolean().optional(),
        dexcomIntegrationId: v.string().uuid().allow(null).optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {
    methodId,
    name,
    description,
    garminConnectEnable,
    garminDeviceEnable,
    movesenseDeviceEnable,
    garminStreamEnable,
    dexcomIntegrationId,
  } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/method-update`,
    accessToken,
    data: {
      methodId,
      name,
      description,
      garminConnectEnable,
      garminDeviceEnable,
      garminStreamEnable,
      movesenseDeviceEnable,
      dexcomIntegrationId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_METHOD_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/method-update',
        ...result,
        error,
      }),
    )
  }

  yield put(doMETHOD_SET(result.payload))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
  // }
}

export function* methodUpdateSaga() {
  yield takeEvery(REQUEST_METHOD_UPDATE, requestMethodUpdate)
}
