import {useState, useEffect} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'

import {createDispatchActions, selectRequestComplete} from '../../store'

import {PopupConfirmation} from '..'

export const ProjectCompletePop = (props: any) => {
  const {closeAction} = props

  const {doREQUEST_PROJECT_STATUS_UPDATE}: any = createDispatchActions()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)
  const navigate = useNavigate()
  const location = useLocation()
  const projectId = location.pathname.slice(-36)

  const handleCompleteProject = () => {
    doREQUEST_PROJECT_STATUS_UPDATE({
      setRequestId,
      payload: {
        projectId: projectId,
        status: 'completed',
      },
    })
  }

  useEffect(() => {
    if (!requestComplete) return
    if (!requestComplete?.success) return alert(requestComplete?.payload.error.message)
    navigate('/project')
  }, [requestComplete])

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '100',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PopupConfirmation
        {...props}
        method='Complete'
        name='Project'
        description='This will end data collection and mark project as complete.'
        term1='I understand that this will end data collection for participants and log them out of the project on the participant app.'
        term2='I understand that this action cannot be undone.I understand that this action cannot be undone.'
        buttonText='End Data Collection and Complete Project'
        closeAction={closeAction}
        doAction={handleCompleteProject}
      />
    </div>
  )
}
