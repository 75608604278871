import {useState, Dispatch, SetStateAction} from 'react'
import {selectTheme} from '../../store'
import {RIF, useCurrentWorkspaceAccountPlan, useCurrentWorkspaceState} from '../../lib'
import {
  ButtonReverse, 
  SaveBar, 
  UpgradeContactPop
} from '..'

interface PricingPageProps {
  setDisplayChangePlanPage: Dispatch<SetStateAction<boolean>>
}

export const PricingPage = (props: PricingPageProps) => {
  const {pad, color, fontWeight, fontSize} = selectTheme()

  const {setDisplayChangePlanPage} = props
  const [displayUpgradeContactPop, setDisplayUpgradeContactPop] = useState(false)
  const { workspace: currentWorkspace} = useCurrentWorkspaceState()
  const accountPlan = useCurrentWorkspaceAccountPlan()

  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 103,
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        background: color.background,
        padding: '60px',
      }}
    >
      <SaveBar
        {...{
          buttonText: 'Chat With Us',
          html: <div css={{marginRight: '15px'}}>Have questions about pricing or billing?</div>,
          formId: 'workspaceSettingForm',
          handleClickSave: () => setDisplayUpgradeContactPop(true),
          closeAction: setDisplayChangePlanPage,
        }}
      />

      {RIF(displayUpgradeContactPop, <UpgradeContactPop {...{setDisplayUpgradeContactPop}} />)}

      <div
        css={{
          fontSize: '26px',
          fontWeight: fontWeight.bold,
          margin: '50px auto 30px',
          width: 'fit-content',
          textAlign: 'center',
        }}
      >
        Choose a plan that fits your research
        <div css={{paddingTop: '8px', fontSize: '16px'}}>
          Collect the same powerful metrics in each plan. For more participants, support or for Analytics eligibility,
          upgrade any time
        </div>
      </div>

      <div
        css={{
          padding: `${pad.slightlyLarger} ${pad.widthPadding}`,
          borderRadius: '5px',
          background: color.grey_60,
          margin: '0 auto',
          fontWeight: fontWeight.bold,
          fontSize: fontSize.h5,
          width: 'fit-content',
        }}
      >
        The workspace you are looking at is: {currentWorkspace?.name}
      </div>

      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '1250px',
          margin: '30px auto',
        }}
      >
        <PlanBlock
          {...{
            plan: 'Free',
            accountPlan,
            setDisplayUpgradeContactPop,
          }}
        />
        <PlanBlock
          {...{
            plan: 'Basic',
            accountPlan,
            setDisplayUpgradeContactPop,
          }}
        />
        <PlanBlock
          {...{
            plan: 'Advanced',
            accountPlan,
            setDisplayUpgradeContactPop,
          }}
        />
      </div>

      <div css={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}>
        <a 
          href='https://www.labfront.com/pricing' 
          target='_blank'
          css={{
            padding: '16px 32px',
            margin: '0 auto',
            border: `1px solid ${color.primary}`,
            borderRadius: '5px',
            fontWeight: fontWeight.bold,
            color: color.primary,
          }}
        >Show full plan details</a>
      </div>

      <div css={{width: '950px', margin: '30px auto', fontSize: '18px'}}>
        Other services we offer to help your studies:
        <div css={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
          <ServicesBlock
            text='Labfront Analytics'
            content="Gain unique insights and a deeper understanding of your participants' physiological biomarkers."
            buttonText='Learn More'
            linkTo='https://www.labfront.com/analytics/report'
          />
          <ServicesBlock
            text='Developer Access to Data'
            content='Programmably access your secure Amazon (AWS) S3 bucket directly.'
            buttonText='Contact Us'
            linkTo='https://www.labfront.com/contact'
          />
        </div>
      </div>
    </div>
  )
}

interface PlanBlockProps {
  setDisplayUpgradeContactPop: Dispatch<SetStateAction<boolean>>
  plan: string
  accountPlan?: string
}

const PlanBlock = (props: PlanBlockProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const plan = props.plan
  const accountPlan = props.accountPlan
  // order to determine upgrade button show or not
  const upgradeLevel = plan === 'Free' ? 1 : plan === 'Basic' ? 2 : 3
  const CurrentUpgradeLevel = accountPlan === 'Free' ? 1 : accountPlan === 'Basic' ? 2 : 3

  return (
    <div
      css={{
        // height: '400px',
        width: '400px',
        background: color.white,
        borderRadius: '5px',
        boxShadow: '0px 4px 12px 0px #D4D4D440',
        padding: '30px 40px',
        border: plan === accountPlan ? `1px solid ${color.primary}` : 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '32px',
          }}
        >
          <div css={{fontSize: fontSize.h3, fontWeight: fontWeight.bold}}>
            {RIF(plan === 'Free', 'Tester')}
            {RIF(plan === 'Basic', 'Basic')}
            {RIF(plan === 'Advanced', 'Advanced')}
          </div>
          {RIF(plan === accountPlan, <p css={{fontSize: fontSize.h3, fontWeight: fontWeight.bold, color: color.primary}}>Current Plan</p>)}
        </div>
        <p
          css={{
            color: color.primary,
            fontSize: '40px',
            fontWeight: fontWeight.bold,
            marginBottom: '16px',
          }}
        >
          {RIF(plan === 'Free', 'Free')}
          {RIF(plan === 'Basic', '$500/year')}
          {RIF(plan === 'Advanced', '$1000/year')}
        </p>
        <div>
          {RIF(plan === 'Free', 'Get 5 test participant accounts and all settings enabled for comprehensive testing (Note: Some restrictions apply).')}
          {RIF(
            plan === 'Basic',
            <>
              <p css={{fontSize: fontSize.h6, color: color.grey_400, marginBottom: '10px'}}>
                Starting at: <span css={{fontWeight: fontWeight.thick, color: color.grey_400}}>20 Participants</span><br/>
                Additional participants: <span css={{fontWeight: fontWeight.thick, color: color.grey_400}}>$10/participant</span>
              </p>
              <p>
                Get single device integration, enabling data access from either Garmin's or Dexcom's apps.
              </p>
            </> 
          )}
          {RIF(
            plan === 'Advanced', 
            <>
              <p css={{fontSize: fontSize.h6, color: color.grey_400, marginBottom: '10px'}}>
                Starting at: <span css={{fontWeight: fontWeight.thick, color: color.grey_400}}>20 Participants</span><br/>
                Additional participants: <span css={{fontWeight: fontWeight.thick, color: color.grey_400}}>$20/participant</span>
              </p>
              <p>
                Get unlimited integrations and adapt data collection to your needs with flexible sampling rates, from 100 Hz accelerometer to beat-to-beat interval for HRV.
              </p>
            </>
          )}
        </div>
      </div>
      <div css={{
        width: '100%', 
        marginTop: '32px',
        height: '52px',
      }}>
        {RIF(
          plan !== accountPlan && CurrentUpgradeLevel < upgradeLevel,
          <ButtonReverse onClick={()=>{props.setDisplayUpgradeContactPop(true)}} btnPadding='xl'>Upgrade</ButtonReverse>
        )}
      </div>
    </div>
  )
}

interface ServicesBlockProps {
  buttonText: string
  linkTo: string
  text: string
  content: string
}

const ServicesBlock = (props: ServicesBlockProps) => {
  const {color, fontWeight} = selectTheme()
  const onClick = () => {
    window.open(props.linkTo, '_blank')
  }
  return (
    <div
      css={{
        width: '460px',
        background: color.white,
        borderRadius: '5px',
        boxShadow: '0px 4px 12px 0px #D4D4D440',
        padding: '30px',
        fontSize: '18px',
        color: color.primary,
        fontWeight: fontWeight.bold,
      }}
    >
      {props.text}
      <div css={{marginTop: '8px'}}>{props.content}</div>
      <ButtonReverse onClick={onClick} css={{marginTop: '20px'}}>
        {props.buttonText}
      </ButtonReverse>
    </div>
  )
}
