'use strict'
import {Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {
  createDispatchActions,
  selectCollaborators,
  selectMethod,
  selectProfile,
  selectRequestComplete,
  selectTheme,
  selectWorkspaces,
} from '../../store'

import {
  collaboratorTypeHasRole,
  loadSessionStorage,
  RIF,
  setSessionStorage,
  useCollaboratorTypeInProject,
  useCurrentWorkspaceAccountPlan,
  useCurrentWorkspaceState,
} from '../../lib'

import {
  Avatars,
  ButtonReverse,
  CalcSlotsPop,
  CollaboratorPage,
  DexcomIntegrationForm,
  PopupEditWorkspaceName,
  PricingPage,
  SaveBar,
  Tooltip,
} from '..'

import {WarningIcon} from '../../asset/image'
import {Collaborator, DexcomIntegration, Workspace} from '../../model'
import {WorkspaceCollaboratorRole} from '../../shared/db'

export interface WorkspaceSettingsProps {
  setIsWorkspaceSettingsPage: Dispatch<SetStateAction<boolean>>
  scrollToDexcom?: boolean
}

export const WorkspaceSettings = (props: WorkspaceSettingsProps) => {
  const {setIsWorkspaceSettingsPage, scrollToDexcom} = props

  // importing all states form root
  const {color, fontWeight} = selectTheme()
  const {
    doREQUEST_WORKSPACE_UPDATE,
    doREQUEST_WORKSPACE_COLLABORATOR_DELETE,
    doREQUEST_DEXCOM_INTEGRATION_LIST_FETCH,
  }: any = createDispatchActions()

  const profile = selectProfile()
  const methodState = selectMethod()
  const workspacesState = selectWorkspaces()
  const collaboratorsState = selectCollaborators()

  // extract data from state
  const ownWorkspace: Workspace = Object.values(workspacesState).find(
    (item: Workspace) => item.identityId === profile?.identityId,
  )
  const { workspaceId, workspace: currentWorkspace } = useCurrentWorkspaceState()
  const collaboratorType = useCollaboratorTypeInProject()
  const canUpdateWorkspace = collaboratorTypeHasRole({
    collaboratorType,
    workspaceRole: WorkspaceCollaboratorRole.WorkspaceUpdate
  })
  const currentWorkspaceCollaborators: {[id: string]: Collaborator} = collaboratorsState?.[workspaceId ?? '']
  const dexcomIntegrationListOfCurrentWorkspace = currentWorkspace?.dexcomIntegrationList

  // create request complete hook
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)
  const [reqeustDexcomIntegrationListFetchId, setReqeustDexcomIntegrationListFetchId] = useState(null)
  const reqeustDexcomIntegrationListFetchComplete = selectRequestComplete(reqeustDexcomIntegrationListFetchId)
  const [requestCollaboratorDeleteId, setRequestCollaboratorDeleteId] = useState(null)
  const requestCollaboratorDeleteComplete = selectRequestComplete(requestCollaboratorDeleteId)

  // create useState hook
  const [currentWorkspaceCollaborator, setCurrentWorkspaceCollaborator] = useState<Collaborator>()
  const [displayChangePlanPage, setDisplayChangePlanPage] = useState(false)
  const [displayCollaboratorsPage, setDisplayCollaboratorsPage] = useState(false)
  const [displayCalcSlotsPop, setDisplayCalcSlotsPop] = useState(false)
  const [displayDexcomIntegrationForm, setDisplayDexcomIntegrationForm] = useState(false)
  const [collaboratorList, setCollaboratorList] = useState<Collaborator[]>([])
  const [showEditWorkspaceNamePopup, setShowEditWorkspaceNamePopup] = useState(false)
  const [showLeaveWorkspacePopup, setShowLeaveWorkspacePopup] = useState(false)
  // const [userCollaboratorProfile, setUserCollaboratorProfile] = useState({})
  const [selectedDexcomIntegrationId, setSelectedDexcomIntegrationId] = useState<string | null | undefined>(methodState.dexcomIntegrationId)
  const [dexcomIntegrationList, setDexcomIntegrationList] = useState<DexcomIntegration[]>([])
  const accountPlan = useCurrentWorkspaceAccountPlan()

  // initialize
  const navigate = useNavigate()
  const loadSession = loadSessionStorage()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (currentWorkspaceCollaborators) {
      setCurrentWorkspaceCollaborator(
        Object.values(currentWorkspaceCollaborators)
          .find((item: Collaborator) => item.identityId === profile?.identityId),
      )
    }
  }, [currentWorkspaceCollaborators])

  const participantsAmount: number | undefined = (() => {
      switch (accountPlan) {
      case 'Free':
      return 5
      case 'Basic':
      return 20
      case 'Advanced':
      return 50
      default:
      return undefined
      }
  })()

  useEffect(() => {
    if (collaboratorsState[workspaceId ?? '']) {
      const tempCollaboratorList: Collaborator[] = Object.values(collaboratorsState[workspaceId ?? ''])
      const owner: Collaborator = {
        id: currentWorkspace?.ownerProfile?.id ?? '',
        identityId: currentWorkspace?.ownerProfile?.identityId,
        workspaceId: workspaceId ?? '',
        type: 'owner',
        profile: {...currentWorkspace?.ownerProfile}
      }
      // const user = tempCollaboratorList.find((collaborator: Collaborator) => {
      //   return collaborator.profile?.email === profile.email
      // })
      // if (user) setUserCollaboratorProfile(user)

      tempCollaboratorList.splice(0, 0, owner)
      setCollaboratorList(tempCollaboratorList)
    }
  }, [collaboratorsState])

  useEffect(() => {
    if (!workspaceId) return
    doREQUEST_DEXCOM_INTEGRATION_LIST_FETCH({
      setRequestId: setReqeustDexcomIntegrationListFetchId,
      payload: {
        workspaceId
      }
    })
  }, [workspaceId])

  useEffect(() => {
    if (!dexcomIntegrationListOfCurrentWorkspace) return
    setDexcomIntegrationList(dexcomIntegrationListOfCurrentWorkspace)
  }, [dexcomIntegrationListOfCurrentWorkspace])

  // private functions
  const saveChangeToWorkspaceName = (newWorkspaceName: string) => {
    doREQUEST_WORKSPACE_UPDATE({
      setRequestId,
      payload: {
        workspaceId,
        name: newWorkspaceName,
      },
    })
    setShowEditWorkspaceNamePopup(false)
  }

  const handleLeaveWorkspace = () => {
    doREQUEST_WORKSPACE_COLLABORATOR_DELETE({
      setRequestId: setRequestCollaboratorDeleteId,
      payload: {
        workspaceCollaboratorId: currentWorkspaceCollaborator?.id,
        workspaceId: currentWorkspace?.id,
      },
    })
    setShowLeaveWorkspacePopup(false)
  }

  useEffect(() => {
    if (!requestCollaboratorDeleteComplete?.success) return
    setSessionStorage({currentWorkspaceId: ownWorkspace?.id})
    navigate(0)
  }, [requestCollaboratorDeleteComplete])

  useEffect(() => {
    if (scrollToDexcom) {
      ref.current?.scrollIntoView({block: "start",behavior: 'smooth'})
    }
  }, [])

  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 102,
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        background: color.background,
        padding: '60px',
      }}
    >
      {RIF(displayDexcomIntegrationForm,
        <DexcomIntegrationForm {...{
          setDisplayDexcomIntegrationForm,
        }}/>
      )}
      {/* {RIF(
        showLeaveWorkspacePopup,
        <RemoveCollaboratorPopup
          {...{
            collaborator: userCollaboratorProfile,
            setShowLeaveWorkspacePopup,
            removeAction: handleLeaveWorkspace,
          }}
        />,
      )} */}
      {RIF(
        showEditWorkspaceNamePopup,
        <PopupEditWorkspaceName
          closeAction={() => setShowEditWorkspaceNamePopup(false)}
          saveAction={saveChangeToWorkspaceName}
          workspaceName={currentWorkspace?.name}
        />,
      )}

      {RIF(displayCalcSlotsPop, <CalcSlotsPop {...{setDisplayCalcSlotsPop}} />)}

      {RIF(
        displayChangePlanPage,
        <PricingPage
          {...{
            setDisplayChangePlanPage,
          }}
        />,
      )}

      {RIF(
        displayCollaboratorsPage,
        <CollaboratorPage
          {...{
            displayCollaboratorsPage,
            setDisplayCollaboratorsPage,
          }}
        />,
      )}

      <div>
        <SaveBar closeAction={setIsWorkspaceSettingsPage} hideButton={true} />
        {/* page container */}
        <div
          css={{
            fontSize: '20px',
            fontWeight: fontWeight.bold,
            margin: '50px auto',
            width: '600px',
          }}
        >
          Workspace Settings
          {/* About block */}
          <SettingsBlock
            title='About'
            // showBtnText={role !== 'viewer'}
            showBtnText={canUpdateWorkspace}
            btnText='Edit'
            btnTextAction={() => setShowEditWorkspaceNamePopup(true)}
          >
            <p css={{color: color.grey_400, marginTop: '19px', marginBottom: '8px'}}>Workspace Name</p>
            <p>{currentWorkspace?.name}</p>
          </SettingsBlock>
          {/* Plan Details block */}
          <SettingsBlock
            {...{
              title: 'Plan Details',
              btnText: 'Change Plan',
              showBtnText: canUpdateWorkspace,
              btnTextAction: () => setDisplayChangePlanPage(true),
            }}
          >
            <div css={{display: 'flex', marginTop: '20px'}}>
              <div>
                <div css={{color: color.grey_400, marginBottom: '8px'}}>Current Plan</div>
                <div>{accountPlan}</div>
              </div>
            </div>
          </SettingsBlock>
          {/* Participant Summary block */}
          {RIF(accountPlan && participantsAmount,
              <SettingsBlock
                {...{
                  title: 'Participant Summary',
                  btnText: accountPlan === 'Advanced' ? 'Buy More Participant Slots' : '',
                  btnTextAction: () => setDisplayCalcSlotsPop(true),
                }}>
                <div
                  css={{
                    color: color.grey_400,
                    marginTop: '10px',
                  }}>
                  In the {accountPlan} Plan, you can add a total of <span>{participantsAmount}</span> participants.
                </div>
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: `1px solid ${color.grey_100}`,
                    padding: '15px 0',
                    marginTop: '15px',
                  }}>
                  <div>Total participants added</div>
                  <div css={{fontWeight: fontWeight.bold, fontSize: '16px'}}>{loadSession?.totalParticipants}</div>
                </div>
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '15px',
                  }}>
                  <div>Number of participants you can currently add</div>
                  <div css={{fontWeight: fontWeight.bold, fontSize: '16px'}}>{currentWorkspace?.participantLimit}</div>
                </div>
              </SettingsBlock>)}
          {/* Collaborators block */}
          <SettingsBlock
            btnTextAction={() => setDisplayCollaboratorsPage(true)}
            title='Collaborators'
            btnText='Manage Collaborators'
            showBtnText={true}
          >
            <div css={{color: color.grey_400, marginTop: '10px'}}>
              Total <span>{collaboratorList.length}</span> Collaborators.
            </div>
            <Avatars css={{marginTop: '20px', marginLeft: '0'}} {...{users: collaboratorList}} />
          </SettingsBlock>
          {/* Dexcom integration block */}
          <SettingsBlock
            btnTextAction={() => setDisplayDexcomIntegrationForm(true)}
            title='Dexcom Integration'
            btnText='Request New Project Integration'
            showBtnText={true}
          >
            <p css={{
              fontWeight: fontWeight.medium,
              color: color.grey_400,
              marginBottom: '24px',
            }}>
              Dexcom requires approval before it can be used in your project.
              <br/><br/>
              Note 1: Creating the request does not automatically add it into your project. You&#39;ll still need to add the integration into your specific project.
              <br/><br/>
              Note 2: Contact us at hello@labfront.com if you need to edit or delete an application
            </p>
            <div>
              {dexcomIntegrationList?.map((dexcomIntegration: DexcomIntegration, index: number) => (
                <DexcomAppInfoCard {...{
                  key: index,
                  selectedDexcomIntegrationId,
                  setSelectedDexcomIntegrationId,
                  ...dexcomIntegration,
                }}/>
              ))}
            </div>
          </SettingsBlock>
          {/* Leave this Workspace block */}
          {RIF(
            collaboratorType !== 'owner',
            <SettingsBlock title='Leave this Workspace'>
              <div css={{color: color.grey_400, marginTop: '10px'}}>
                You will no longer have access to this workspace after leaving.
              </div>
              <ButtonReverse
                onClick={() => setShowLeaveWorkspacePopup(true)}
                btnColor='warning'
                btnPadding='large'
                css={{marginTop: '15px'}}
              >
                Leave Workspace
              </ButtonReverse>
            </SettingsBlock>,
          )}
        </div>
      </div>
    </div>
  )
}

interface SettingsBlockProps {
  title: string
  btnText?: string
  btnTextAction?: () => void
  showBtnText?: boolean,
  children?: ReactElement | ReactElement[]
}

const SettingsBlock = (props: SettingsBlockProps) => {
  const {color, fontWeight} = selectTheme()
  const {title, btnText, btnTextAction, showBtnText, ...rest} = props
  return (
    <div
      css={{
        borderRadius: '5px',
        boxShadow: '0px 4px 12px 0px #D4D4D440',
        background: color.white,
        marginTop: '25px',
        padding: '30px',
      }}
    >
      <div css={{display: 'flex', justifyContent: 'space-between', alignItems: 'start'}}>
        <div css={{fontSize: '16px', fontWeight: fontWeight.bold}}>{title}</div>
        {RIF(
          showBtnText,
          <div onClick={btnTextAction} css={{color: color.primary, fontWeight: fontWeight.bold, cursor: 'pointer'}}>
            {btnText}
          </div>,
        )}
      </div>
      {/* pass HTML here */}
      <div {...rest} />
    </div>
  )
}

const DexcomAppInfoCard = (props: DexcomIntegration) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {
    name, 
    description, 
    investigator, 
    organization, 
    countries, 
    dexcomApp,
  } = props

  const TOOLTIP_CONTENT_IF_CLIENT_ID_APPROVED = 'This is your unique key for secure integration with Dexcom.'
  const TOOLTIP_CONTENT_IF_CLIENT_ID_NOT_APPROVED = 'Your application is waiting for Dexcom approval. Please check your email for update information. Contact us at hello@labfront.com if you have any other questions.'

  return (
    <div 
      css={{
        width: '100%',
        padding: '16px',
        borderRadius: '5px',
        border: `1px solid ${color.grey_160}`,
        marginTop: '8px',
        background: '#fff',
        outline: 'none',
        textAlign: 'start',
      }}
    >
      <div css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <p css={{
          fontWeight: fontWeight.thick,
          marginBottom: '8px',
        }}>
          {name}
        </p>
      </div>
      <p css={{
        fontWeight: fontWeight.medium,
        fontSize: fontSize.h7,
        color: color.grey_600,
        marginBottom: '16px',
      }}>
        {description}
      </p>
      <p css={{
        fontWeight: fontWeight.medium,
        fontSize: fontSize.h7,
        color: color.grey_400,
        marginBottom: '16px',
      }}>
        PI: {investigator}<br/>
        Organization: {organization}<br/>
        Countries: {countries}<br/>
      </p>
      {RIF(dexcomApp?.clientId,
        <div css={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <p css={{
            color: color.highlightGreen,
            fontWeight: fontWeight.medium,
          }}>Approved -&nbsp;</p>
          <p css={{
            fontWeight: fontWeight.medium,
            color: color.grey_600,
            marginRight: '4px',
          }}>{dexcomApp?.clientId}</p>
          <Tooltip content={TOOLTIP_CONTENT_IF_CLIENT_ID_APPROVED}/>
        </div>
      )}
      {RIF(!dexcomApp?.clientId,
        <div css={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <img src={WarningIcon} width={16}/>
          <p css={{
            fontWeight: fontWeight.medium, 
            color: color.warning,
            margin: '0 4px',
          }}>Awaiting approval from Dexcom</p>
          <Tooltip content={TOOLTIP_CONTENT_IF_CLIENT_ID_NOT_APPROVED}/>
        </div>
      )}
    </div>
  )
}
