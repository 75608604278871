import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  SagaIterator,
  assertPartialSchema,
  call,
} from '../../lib'

import {generateGarminConnectDbContentList, insertGarminConnectDataToDb} from '../db/db_data_setter'
import {
  doREQUEST_ERROR,
  doREQUEST_COMPLETE,
  doPARTICIPANT_GARMIN_TIMESERIES_DATA_SET,
  doPARTICIPANT_GARMIN_CONNECT_TIMELINE_DATA_SET,
} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_VISUALIZER_GARMIN_CONNECT_DATA_FETCH = 'REQUEST_VISUALIZER_GARMIN_CONNECT_DATA_FETCH'
export const doREQUEST_VISUALIZER_GARMIN_CONNECT_DATA_FETCH = createDoRequestAction(
  REQUEST_VISUALIZER_GARMIN_CONNECT_DATA_FETCH,
)

export const requestVisualizerGarminConnectDataFetchActionCreators = {
  doREQUEST_VISUALIZER_GARMIN_CONNECT_DATA_FETCH,
}

export function* requestVisualizerGarminConnectDataFetch({payload}: any): SagaIterator {
  debug('saga*requestVisualizerGarminConnectDataFetch')

  try {
    assertPartialSchema({
      payload,
      schema: v.object({
        participantId: v.string().uuid().required(),
        yymmddIndex: v.number().required().example(231011),
        dataTypeList: v.array().items(v.string().required()).optional().allow(null),
        projection: v.object().optional().allow(null),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {participantId, yymmddIndex, dataTypeList, projection} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/visualizer-daily-summary-data-fetch`,
    accessToken,
    data: {
      participantId,
      yymmddIndexStart: yymmddIndex,
      yymmddIndexEnd: yymmddIndex,
      dataTypeList,
      projection,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_VISUALIZER_GARMIN_CONNECT_DATA_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/visualizer-daily-summary-data-fetch',
        ...result,
        error,
      }),
    )
  }

  const dbContentList = yield call(async () => {
    return await generateGarminConnectDbContentList(result.payload)
  })

  yield put(
    doPARTICIPANT_GARMIN_CONNECT_TIMELINE_DATA_SET({
      dbContentList,
    }),
  )

  const dbSetReuslt = yield call(async () => {
    return await insertGarminConnectDataToDb(participantId, yymmddIndex, dataTypeList, dbContentList)
  })

  yield put(
    doPARTICIPANT_GARMIN_TIMESERIES_DATA_SET({
      participantId,
      yymmddIndex,
      dbOperationResultList: dbSetReuslt,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result.payload,
    }),
  )
}

export function* visualizeGarminConnectDataFetchSaga() {
  yield takeEvery(REQUEST_VISUALIZER_GARMIN_CONNECT_DATA_FETCH, requestVisualizerGarminConnectDataFetch)
}
