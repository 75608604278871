import {useState, useEffect, useMemo} from 'react'
import {selectRequestComplete, createDispatchActions} from '../../store'
import {_, RIF} from '../../lib'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {IndividualCorrelationMatrix} from '../charts/correlation_graph/correlation_matrix'
import {
  Variable,
  VariableDataType,
  IndividualCorrelationResponse,
  CorrelationResult,
} from '../../shared/analysis'
import {CorrelationVariableTypeDisplayNameMap, DataTypeDisplayNameMap} from '../../model'
export interface AnalysisMatrixBlockProps {
  participantId: string
  dataDateRange: number[]
  displayingVariableList: Variable[]
}

export const AnalysisMatrixBlock = (props: AnalysisMatrixBlockProps) => {
  const {participantId, dataDateRange, displayingVariableList} = props
  const {doREQUEST_ANALYTIC_CORRELATION_INDIVIDUAL}: any = createDispatchActions()
  /* ------------------ req ------------------ */
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)
  const [analysisGraphData, setAnalysisGraphData] = useState<CorrelationResult['items']>()
  const [analyticGraphResult, setAnalyticGraphResult] = useState<IndividualCorrelationResponse>()
  const [graphDateRange, setGraphDateRange] = useState<number[]>([0, 0])

  const getVariableCode = (index: number) => {
    const charCode = 'A'.charCodeAt(0)
    return `variable${String.fromCharCode(charCode + index)}`
  }

  useEffect(() => {
    if (_.isEqual(dataDateRange, graphDateRange)) return
    setGraphDateRange(dataDateRange)
  }, [dataDateRange])

  useMemo(() => {
    if (!participantId) return
    if (_.isEqual(dataDateRange, [0, 0])) return
    if (!displayingVariableList.length) return
    doREQUEST_ANALYTIC_CORRELATION_INDIVIDUAL({
      setRequestId,
      payload: {
        participantId,
        yymmddIndexStart: graphDateRange[0],
        yymmddIndexEnd: graphDateRange[1],
        variables: displayingVariableList.map((variable, index) => ({...variable, identifier: getVariableCode(index)})),
      },
    })
  }, [participantId, displayingVariableList, graphDateRange])

  useEffect(() => {
    if (!requestComplete) return
    if (!requestComplete.success) return
    if (requestComplete.error) return
    setAnalyticGraphResult(requestComplete.payload.payload)
  }, [requestComplete])

  const getDataTypeDisplayName = (dataType: keyof typeof DataTypeDisplayNameMap) => DataTypeDisplayNameMap[dataType]

  const getVariableTypeDisplayName = <T extends keyof typeof CorrelationVariableTypeDisplayNameMap>(
    dataType: T,
    variableType: keyof typeof CorrelationVariableTypeDisplayNameMap[T],
  ) => {
    return CorrelationVariableTypeDisplayNameMap[dataType][variableType]
  }

  const displayName = (identifier: string): string => {
    const displayingVariable = _.find(displayingVariableList, ['identifier', identifier])
    if (!displayingVariable) return 'Unknown'
    switch (displayingVariable.dataType) {
      case VariableDataType.Todo: {
        const {dataType, variableType, taskName} = displayingVariable
        const variableTypeDisplayName = getVariableTypeDisplayName(dataType, variableType)
        const displayNameComponents = [taskName, variableTypeDisplayName]
        if ('threshold' in displayingVariable && displayingVariable.threshold) {
          displayNameComponents.push(`- threshold: ${displayingVariable.threshold}`)
        }
        return displayNameComponents.join(' ')
      }
      case VariableDataType.Timer: {
        const {dataType, variableType, taskName} = displayingVariable
        const variableTypeDisplayName = getVariableTypeDisplayName(dataType, variableType)
        const displayNameComponents = [taskName, variableTypeDisplayName]
        if ('threshold' in displayingVariable && displayingVariable.threshold) {
          displayNameComponents.push(`- threshold: ${displayingVariable.threshold}`)
        }
        return displayNameComponents.join(' ')
      }
      case VariableDataType.GarminConnectSleep: {
        const {dataType, variableType} = displayingVariable
        return [getDataTypeDisplayName(dataType), getVariableTypeDisplayName(dataType, variableType)].join(' ')
      }
      case VariableDataType.GarminConnectActivity: {
        const {dataType, variableType} = displayingVariable
        return [getDataTypeDisplayName(dataType), getVariableTypeDisplayName(dataType, variableType)].join(' ')
      }
      case VariableDataType.GarminConnectHeartRate: {
        const {dataType, variableType} = displayingVariable
        return [getDataTypeDisplayName(dataType), getVariableTypeDisplayName(dataType, variableType)].join(' ')
      }
      case VariableDataType.GarminConnectStress: {
        const {dataType, variableType} = displayingVariable
        return [getDataTypeDisplayName(dataType), getVariableTypeDisplayName(dataType, variableType)].join(' ')
      }
      case VariableDataType.GarminDeviceHrv: {
        const {dataType, variableType} = displayingVariable
        return [getDataTypeDisplayName(dataType), getVariableTypeDisplayName(dataType, variableType)].join(' ')
      }
      case VariableDataType.GarminDeviceActigraphySleep: {
        const {dataType, variableType} = displayingVariable
        return [getDataTypeDisplayName(dataType), getVariableTypeDisplayName(dataType, variableType)].join(' ')
      }
    }
  }

  useEffect(() => {
    if (analyticGraphResult?.result?.items?.length) {
      setAnalysisGraphData(analyticGraphResult.result.items)
    }
  }, [analyticGraphResult])

  return (
    <div
      css={{
        flex: 1,
        padding: '0px 32px 60px',
      }}
    >
      <Scrollbars autoHide={true} autoHideTimeout={200}>
        {RIF(analysisGraphData, (items) => (
          <IndividualCorrelationMatrix
            {...{
              items,
              displayName,
            }}
          ></IndividualCorrelationMatrix>
        ))}
      </Scrollbars>
    </div>
  )
}
