import {
  API_URL,
  SagaIterator,
  assertTotalSchema,
  call,
  createDoRequestAction,
  debug,
  put,
  request,
  takeEvery,
  v,
} from '../../lib'
import {doPROJECT_SETTINGS_DELETE, doREQUEST_COMPLETE, doREQUEST_ERROR} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_IDENTITY_PROJECT_SETTINGS_DELETE = 'REQUEST_IDENTITY_PROJECT_SETTINGS_DELETE'
export const doREQUEST_IDENTITY_PROJECT_SETTINGS_DELETE = createDoRequestAction(
  REQUEST_IDENTITY_PROJECT_SETTINGS_DELETE,
)

export const requestIdentityProjectSettingsDeleteActionCreators = {
  doREQUEST_IDENTITY_PROJECT_SETTINGS_DELETE,
}

interface Payload {
  type: string
  payload: {
    requestId: string
    identityProjectSettingsIds: string[]
  }
}

export function* requestIdentityProjectSettingsDelete({payload}: Payload): SagaIterator {
  debug('saga*requestIdentityProjectSettingsDelete')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        identityProjectSettingsIds: v.array().items(v.string().uuid().exist()).exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {identityProjectSettingsIds} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'POST',
    url: `${API_URL}/v1/web/identity-project-settings-delete`,
    accessToken,
    data: {
      identityProjectSettingsIds,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_IDENTITY_PROJECT_SETTINGS_DELETE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/identity-project-settings-delete',
        ...result,
        error,
      }),
    )
  }

  yield put(doPROJECT_SETTINGS_DELETE({identityProjectSettingsIds}))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* identityProjectSettingsDeleteSaga() {
  yield takeEvery(REQUEST_IDENTITY_PROJECT_SETTINGS_DELETE, requestIdentityProjectSettingsDelete)
}
