import {Dispatch, SetStateAction, useEffect, useMemo, useRef, useState} from 'react'
import ApexChart from 'react-apexcharts'
import {ChevronDownBlueIcon, ChevronUpBlueIcon, GarminGIcon} from '../../asset/image'
import {GarminConnectIcon, DexcomIcon} from '../../asset/image'
import {
  selectMethod,
  ParticipantTaskTimelineObject,
  selectParticipantTaskTimelineData,
  TaskTimelineElement,
  createDispatchActions,
  TaskTimelineData,
  selectTheme,
  TimelineElementTaskType,
  selectProjectSettings,
} from '../../store'
import {TaskStateType, TaskType, ThirdPartyTaskType} from '../../model'
import _ from 'lodash'
import {RIF, timeConvert, TimeConvertType} from '../../lib'
import {useParams} from 'react-router-dom'
import {DateObject} from 'react-multi-date-picker'
import 'toolcool-range-slider/dist/plugins/tcrs-moving-tooltip.min.js'
import 'toolcool-range-slider'
import {RangeSlider} from 'toolcool-range-slider'
import {ApexOptions} from 'apexcharts'
import {VisualizerGraphDataType, VisualizerGraphSetting} from '../../shared/db'
import {CommonTaskDataMarker} from './template/time_series_data_chart'
import {GraphDataTypeMap} from './utils/utils'
import {GarminDeviceLogDataType} from '../../shared/mongo'

export interface TaskTimeLineChartProps {
  participantId?: string
  selectedYYMMDDIndex: number
  setSelectedDate: Dispatch<SetStateAction<DateObject>>
  setTimeSeriesChartDateRange: Dispatch<SetStateAction<[Date, Date] | undefined>>
  setLoadDataYYMMDDIndex: Dispatch<SetStateAction<number[]>>
  setCommonTaskDataMarkerList: Dispatch<SetStateAction<CommonTaskDataMarker[]>>
  attempLoadStreamDataCallback: (timeRange: [number, number], eventList: StreamTaskEvent[]) => void
}
interface TaskTimeLinePlotData {
  series: TimelineSeries
  points: TimelinePoints
}

type TimelineSeries = {x: string; y: number[]; fillColor?: string; goals?: object}[]
type TimelinePoints = {
  taskId: string
  x: number
  y: any
  marker?: {
    show: boolean
    size: number
    fillColor: string
    strokeColor: string
    offsetY: number
  }
  image?: object
  mouseEnter?: any
  mouseLeave?: any
}[]

interface TaskLabelItem {
  id: string
  type?: TaskType | ThirdPartyTaskType
  color: string
}

export interface StreamTaskEvent {
  completionId: string
  startTime: number
  endTime: number
  timeOffset: number
}

const CHART_ID = 'TimeSeriesTaskTimeLineChart'

const parseYYMMDDIndexToUtcDate = (yymmddIndex: number): Date => {
  const year = 2000 + Math.trunc(yymmddIndex / 10000)
  const month = Math.trunc((yymmddIndex % 10000) / 100)
  const day = Math.trunc(yymmddIndex % 100)
  return new Date(Date.UTC(year, month - 1, day))
}

export const convertDateToYYMMDDIndex = (date: Date): number => {
  const year = date.getUTCFullYear() % 100 // take 2 digits
  const month = date.getUTCMonth() + 1
  const day = date.getUTCDate()
  return year * 10000 + month * 100 + day
}

const taskTimeLineRange = 86400000 // 24 hours
const defaultTimeSeriesChartRangeClockTime: [number, number] = [0, 4] // 0:00 to 4:00
const defaultTimeSeriesChartRangeClockTimeOffset: [number, number] = [
  defaultTimeSeriesChartRangeClockTime[0] * 60,
  defaultTimeSeriesChartRangeClockTime[1] * 60,
]
const defaultTimeSeriesChartRangeClockTimeLabel: [string, string] = [
  `${defaultTimeSeriesChartRangeClockTime[0]}:00`,
  `${defaultTimeSeriesChartRangeClockTime[1]}:00`,
]

const garminDailyDataTypeSet = new Set<string>()
Object.values(GarminDeviceLogDataType).forEach((value) => {
  garminDailyDataTypeSet.add(value)
})

const bannerHeight = 44
const taskTimelineChartBottomPadding = -26
const taskRowHeight = 32

export const TaskTimeLineChart = (props: TaskTimeLineChartProps) => {
  const {
    participantId,
    selectedYYMMDDIndex,
    setSelectedDate,
    setTimeSeriesChartDateRange,
    setLoadDataYYMMDDIndex,
    setCommonTaskDataMarkerList,
    attempLoadStreamDataCallback,
  } = props

  const {fontWeight, color} = selectTheme()
  const {projectId} = useParams()
  const projectSettings = projectId ? (selectProjectSettings()[projectId] as any) : undefined // FIXME: temporary cast to any to avoid invalid key type warning

  const {
    doREQUEST_PARTICIPANT_TASK_DATA_TIMELINE_FETCH,
    doREQUEST_VISUALIZER_DEXCOM_DATA_FETCH,
  }: // doREQUEST_METHOD_FETCH,
  any = createDispatchActions()
  // const [requestId, setRequestId] = useState(null)
  const [requestTaskTimelineDataId, setRequestTaskTimelinDataId] = useState(null)
  const [requestDexcomDataId, setRequestDexcomDataId] = useState(null)

  const GarminConnectTimeLineId = 'GarminConnectTimeLine'
  // basic config base on task
  // const methodId = project?.methodList?.[0].id
  const method = selectMethod()
  const garminConnectEnabled: boolean = method?.garminConnectEnable
  const garminDeviceTask: TaskStateType | undefined = _.find(method?.taskList.reverse(), {type: TaskType.GarminDevice})
  const garminTaskId = garminDeviceTask?.id
  const dexcomIntegrationId = method?.dexcomIntegrationId

  const selectedGarminDataTypeList = useMemo<string[]>(() => {
    const result: string[] = []
    projectSettings?.visualizerSidebarSetting.TimeSeries?.forEach((item: VisualizerGraphSetting) => {
      const graphType = GraphDataTypeMap[item.type]
      if (graphType) {
        if (garminDailyDataTypeSet.has(graphType)) {
          result.push(graphType)
        }
      }
      return result
    })
    return result
  }, [projectSettings?.visualizerSidebarSetting])

  const selectedGarminConnectDataTypeList = useMemo<VisualizerGraphDataType[]>(() => {
    if (garminConnectEnabled) {
      const result: VisualizerGraphDataType[] = []
      projectSettings?.visualizerSidebarSetting.TimeSeries?.forEach((item: VisualizerGraphSetting) => {
        if (
          [
            VisualizerGraphDataType.GarminConnectHeartRate,
            VisualizerGraphDataType.GarminConnectStress,
            VisualizerGraphDataType.GarminConnectBodyBattery,
            VisualizerGraphDataType.GarminConnectSteps,
          ].includes(item.type)
        ) {
          result.push(item.type)
        }
      })
      return result.length ? result : [VisualizerGraphDataType.GarminConnectHeartRate]
    } else {
      return []
    }
  }, [projectSettings?.visualizerSidebarSetting])

  const taskLabelList: TaskLabelItem[] = (() => {
    const initTaskLabelList: TaskStateType[] = []
    const baseSeries: TaskLabelItem[] = method.taskList
      .filter((task) => {
        return task.enabled
      })
      .reduce((acc, task) => {
        // filter duplicate garmin device task
        if (task.type == TaskType.GarminDevice) {
          let existGarminTaskStateIndex = -1
          for (let i = 0; i < acc.length; i++) {
            if (acc[i].type === TaskType.GarminDevice) {
              existGarminTaskStateIndex = i
              break
            }
          }

          if (existGarminTaskStateIndex > -1) {
            if (acc[existGarminTaskStateIndex].createdAt < task.createdAt) {
              acc[existGarminTaskStateIndex] = task
            }
          } else {
            acc.push(task)
          }
        } else {
          acc.push(task)
        }
        return acc
      }, initTaskLabelList)
      .map((task) => {
        return {id: task.id, type: task.type, color: `#${task.color}`}
      })
      .sort((a, b) => {
        return a.type == TaskType.GarminDevice
          ? -1
          : a.type == TaskType.StopwatchGarminStream && b.type !== TaskType.GarminDevice
          ? -1
          : (a.type == TaskType.StopwatchMovesenseStream || a.type == TaskType.StopwatchMovesenseLogData) &&
            b.type !== TaskType.GarminDevice
          ? -1
          : 1
      })

    if (garminConnectEnabled) {
      baseSeries.unshift({id: GarminConnectTimeLineId, type: ThirdPartyTaskType.GarminConnect, color: `blue`})
    }

    if (dexcomIntegrationId) {
      // always set dexcom task at the top
      baseSeries.unshift({id: dexcomIntegrationId, type: ThirdPartyTaskType.Dexcom, color: `${color.dexcomGreen}`})
    }
    return baseSeries
  })()

  const [shrinkTasks, setShrinkTasks] = useState<boolean>(false)
  const currentTaskLabelList = useMemo<TaskLabelItem[]>(() => {
    if (shrinkTasks) {
      return taskLabelList.filter((taskLabel) => {
        return (
          taskLabel.type === ThirdPartyTaskType.Dexcom ||
          taskLabel.type === TaskType.GarminDevice ||
          taskLabel.type === ThirdPartyTaskType.GarminConnect
        )
      })
    }
    return taskLabelList
  }, [shrinkTasks, taskLabelList.length])

  // need empty data to show every task row, so every series data should base on this empty data object
  const baseSeries = useMemo<TimelineSeries>(() => {
    return currentTaskLabelList.map((item) => {
      return {x: item.id, y: []}
    })
  }, [currentTaskLabelList])

  const taskColorMapper: Record<string, string> = method.taskList.reduce((acc, task) => {
    return {
      ...acc,
      [task.id]: `#${task.color}`,
    }
  }, {})

  const getApexTimeLineChartHeightBaseOnTaskCount = (): number => {
    return currentTaskLabelList.length * taskRowHeight + bannerHeight
  }

  const [apexTimeLineChartHeight, setApexTimeLineChartHeight] = useState<number>(
    getApexTimeLineChartHeightBaseOnTaskCount(),
  )
  const [timeTicksSize, setTimeTicksSize] = useState<number>(24)
  // timeline chart property
  const taskTimelineState: ParticipantTaskTimelineObject = selectParticipantTaskTimelineData()
  const [graphStartDate, setGraphStartDate] = useState<Date>(parseYYMMDDIndexToUtcDate(selectedYYMMDDIndex))
  const [graphTimeOffset, setGraphTimeOffset] = useState<number>(0)
  const [graphTimeRange, setGraphTimeRange] = useState<[number, number]>([
    graphStartDate.getTime(),
    graphStartDate.getTime() + taskTimeLineRange,
  ])
  const [currentPlotData, setCurrentPlotData] = useState<TaskTimeLinePlotData>()
  const getPlotDataYYMMDDIndexes = (): number[] => {
    const prevDate = new Date(graphStartDate)
    prevDate.setDate(graphStartDate.getDate() - 1)
    const prevYYMMDDIndex = convertDateToYYMMDDIndex(prevDate)
    const nextDate = new Date(graphStartDate)
    nextDate.setDate(graphStartDate.getDate() + 1)
    const nextYYMMDDIndex = convertDateToYYMMDDIndex(nextDate)
    return [prevYYMMDDIndex, convertDateToYYMMDDIndex(graphStartDate), nextYYMMDDIndex]
  }
  const [plotDataYYMMDDIndexes, setPlotDataYYMMDDIndexes] = useState<number[]>(getPlotDataYYMMDDIndexes)
  const [selectedPlotDateRange, setSelectedPlotDateRange] = useState<[number, number]>()
  const currentStreamTaskEvents = useMemo<StreamTaskEvent[]>(() => {
    if (participantId) {
      const result: StreamTaskEvent[] = []
      plotDataYYMMDDIndexes.forEach((yymmddIndex) => {
        const taskTimelineData = taskTimelineState[participantId]?.[yymmddIndex]
        if (taskTimelineData?.commonTask) {
          for (const takskTimelineElement of Object.values(taskTimelineData.commonTask)) {
            takskTimelineElement.forEach((element) => {
              if (
                element.taskType === TaskType.StopwatchMovesenseStream ||
                element.taskType === TaskType.StopwatchMovesenseLogData ||
                element.taskType === TaskType.StopwatchGarminStream
              ) {
                if (element.localEndTimestamp && element.completionId && element.timeOffset) {
                  result.push({
                    startTime: element.localStartTimestamp,
                    endTime: element.localEndTimestamp,
                    timeOffset: element.timeOffset,
                    completionId: element.completionId,
                  })
                } else {
                  throw Error('Wrong data instance for stream task event')
                }
              }
            })
          }
        }
      })
      return result
    }

    return []
  }, [taskTimelineState, participantId, plotDataYYMMDDIndexes])

  // timeSeries chart control property
  const timeSeriesRangeSliderRef = useRef<RangeSlider>(null)
  const timeSeriesRangeSliderMouseDownRef = useRef<boolean>(false)
  const [timeSeriesChartPlotRangeOffset, setTimeSeriesChartPlotRangeOffset] = useState<[number, number]>(
    defaultTimeSeriesChartRangeClockTimeOffset,
  )
  const sliderLastValue = useRef<[string, string]>(defaultTimeSeriesChartRangeClockTimeLabel)
  const [showMovingTooltip, setShowMovingTooltip] = useState<boolean>(false)
  const mousePositionRef = useRef<{x: number; y: number}>()
  const taskPointTooltipContentRef = useRef<Record<string, {taskName: string; duration: string}>>()
  const [currentTaskPointTimeId, setCurrentTaskPointTimeId] = useState<number>(0)

  const getSliderData = (): string[] => {
    return [...Array(1440).keys()].map((v) => {
      const nextDateTimestamp = graphTimeRange[0] + v * 60000
      return timeConvert({
        time: nextDateTimestamp,
        type: TimeConvertType.localizedUtcCustomFormat,
        arg: 'H:mm',
      }) as string
    })
  }

  const convertTimeLabelsToOffsetPair = (timeLabelPair: [string, string]): [number, number] => {
    if (timeSeriesRangeSliderRef.current?.data) {
      const startOffset = timeSeriesRangeSliderRef.current.data.findIndex((label) => label == timeLabelPair[0])
      const endOffset = timeSeriesRangeSliderRef.current.data.findIndex((label) => label == timeLabelPair[1])
      return [startOffset, endOffset]
    }
    return timeSeriesChartPlotRangeOffset
  }

  const changeTimeSeriesPlotRangeBlock = (offsetPair: [number, number]) => {
    const timeSeriesPlotStartTime = graphTimeRange[0] + offsetPair[0] * 60000
    const timeSeriesPlotEndTime = graphTimeRange[0] + offsetPair[1] * 60000
    ApexCharts.exec(CHART_ID, 'updateOptions', {
      annotations: {
        xaxis: [
          {
            x: timeSeriesPlotStartTime,
            x2: timeSeriesPlotEndTime,
            ...xAxisMarkerConfiger,
          },
        ],
      },
    })
  }

  useEffect(() => {
    const slider = timeSeriesRangeSliderRef.current
    if (timeSeriesRangeSliderRef.current) {
      timeSeriesRangeSliderRef.current.data = getSliderData()
      timeSeriesRangeSliderRef.current.value1 = defaultTimeSeriesChartRangeClockTimeLabel[0]
      timeSeriesRangeSliderRef.current.value2 = defaultTimeSeriesChartRangeClockTimeLabel[1]
      timeSeriesRangeSliderRef.current.rangeDragging = true
    }

    const onChange = (evt: Event) => {
      const customEvent = evt as CustomEvent
      sliderLastValue.current = customEvent.detail.values

      // immediately change the plot range block, but this will cause interact delay
      // const offsetPair = convertTimeLabelsToOffsetPair(customEvent.detail.values)
      // changeTimeSeriesPlotRangeBlock(offsetPair)
    }
    slider?.addEventListener('change', onChange)

    const onMouseEnter = (evt: Event) => {
      setShowMovingTooltip(true)
    }
    slider?.addEventListener('mouseenter', onMouseEnter)

    const onMouseLeave = (evt: Event) => {
      if (!timeSeriesRangeSliderMouseDownRef.current) {
        setShowMovingTooltip(false)
      }
    }
    slider?.addEventListener('mouseleave', onMouseLeave)

    const onMouseDown = (evt: Event) => {
      timeSeriesRangeSliderMouseDownRef.current = true
      setShowMovingTooltip(true)
    }
    slider?.addEventListener('mousedown', onMouseDown)

    const handleDocumentMouseUp = (evt: Event) => {
      if (timeSeriesRangeSliderMouseDownRef.current) {
        timeSeriesRangeSliderMouseDownRef.current = false
        const timeSeriesChartPlotOffset = convertTimeLabelsToOffsetPair(sliderLastValue.current)
        setTimeSeriesChartPlotRangeOffset(timeSeriesChartPlotOffset)
        setShowMovingTooltip(false)
      }
    }
    document.addEventListener('mouseup', handleDocumentMouseUp)

    return () => {
      slider?.removeEventListener('change', onChange)
      slider?.removeEventListener('mouseenter', onMouseEnter)
      slider?.removeEventListener('mouseleave', onMouseLeave)
      slider?.removeEventListener('mousedown', onMouseDown)
      document.removeEventListener('mouseup', handleDocumentMouseUp)
    }
  }, [])

  const [containerWidth, setContainerWidth] = useState(1200)
  const [taskTimelineMaxHeght, setTaskTimelineMaxHeght] = useState<number>()
  const observedDiv = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (observedDiv.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (observedDiv.current && observedDiv.current?.offsetWidth !== containerWidth) {
          setContainerWidth(observedDiv.current.offsetWidth)
        }

        const parentDiv = observedDiv.current?.parentElement
        if (parentDiv) {
          const parentHeight = parentDiv.clientHeight
          const taskTimelineHeightLimit = Math.floor(parentHeight * 0.7)
          setTaskTimelineMaxHeght(taskTimelineHeightLimit)
        }
      })

      resizeObserver.observe(observedDiv.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [observedDiv.current])

  useEffect(() => {
    if (containerWidth < 1000) {
      if (timeTicksSize !== 12) {
        setTimeTicksSize(12)
      }
    } else {
      if (timeTicksSize !== 24) {
        setTimeTicksSize(24)
      }
    }
  }, [containerWidth])

  useEffect(() => {
    const selectedDate = parseYYMMDDIndexToUtcDate(selectedYYMMDDIndex)
    setGraphStartDate(selectedDate)
    const graphStartTime = selectedDate.getTime() + graphTimeOffset
    const graphEndTime = graphStartTime + taskTimeLineRange
    setGraphTimeRange([graphStartTime, graphEndTime])

    const timeSeriesPlotStartTime = graphStartTime + timeSeriesChartPlotRangeOffset[0] * 60000
    const timeSeriesPlotEndTime = graphStartTime + timeSeriesChartPlotRangeOffset[1] * 60000
    setSelectedPlotDateRange([timeSeriesPlotStartTime, timeSeriesPlotEndTime])
    // setTimeSeriesChartDateRange([new Date(timeSeriesPlotStartTime), new Date(timeSeriesPlotEndTime)])
  }, [selectedYYMMDDIndex])

  useEffect(() => {
    if (selectedPlotDateRange) {
      setTimeSeriesChartDateRange([new Date(selectedPlotDateRange[0]), new Date(selectedPlotDateRange[1])])
    }
  }, [selectedPlotDateRange])

  useEffect(() => {
    if (selectedPlotDateRange) {
      attemptLoadStreamTaskData(selectedPlotDateRange)
    }
  }, [selectedPlotDateRange, currentStreamTaskEvents])

  const attemptLoadStreamTaskData = (plotDateRange: [number, number]) => {
    const eventList: StreamTaskEvent[] = []
    currentStreamTaskEvents.forEach((taskEvent) => {
      const startsInsideRange = plotDateRange[0] >= taskEvent.startTime && plotDateRange[0] <= taskEvent.endTime
      const endsInsideRange = plotDateRange[1] >= taskEvent.startTime && plotDateRange[1] <= taskEvent.endTime
      const coversWholeRange = plotDateRange[0] <= taskEvent.startTime && plotDateRange[1] >= taskEvent.endTime
      if (startsInsideRange || endsInsideRange || coversWholeRange) {
        eventList.push(taskEvent)
      }
    })
    attempLoadStreamDataCallback(plotDateRange, eventList)
  }

  useEffect(() => {
    // attemp to change plot data dates
    const dataDateList = getPlotDataYYMMDDIndexes()
    for (const date of dataDateList) {
      if (!plotDataYYMMDDIndexes.includes(date)) {
        setPlotDataYYMMDDIndexes(dataDateList)
        break
      }
    }
  }, [graphStartDate])

  useEffect(() => {
    // change the slider time label
    if (timeSeriesRangeSliderRef.current) {
      timeSeriesRangeSliderRef.current.data = getSliderData()
    }

    // call load timeseries data disptach
    const graphStartDate = new Date(graphTimeRange[0])
    const loadDataYYMMDDIndex = [convertDateToYYMMDDIndex(graphStartDate)]
    if (graphTimeOffset != 0) {
      const nextDate = new Date(graphStartDate)
      nextDate.setDate(graphStartDate.getDate() + 1)
      const nextYYMMDDIndex = convertDateToYYMMDDIndex(nextDate)
      loadDataYYMMDDIndex.push(nextYYMMDDIndex)
    }
    setLoadDataYYMMDDIndex(loadDataYYMMDDIndex)
  }, [graphTimeRange])

  useEffect(() => {
    const graphStartTime = graphStartDate.getTime() + graphTimeOffset
    const timeSeriesPlotStartTime = graphStartTime + timeSeriesChartPlotRangeOffset[0] * 60000
    const timeSeriesPlotEndTime = graphStartTime + timeSeriesChartPlotRangeOffset[1] * 60000
    setSelectedPlotDateRange([timeSeriesPlotStartTime, timeSeriesPlotEndTime])
    // setTimeSeriesChartDateRange([new Date(timeSeriesPlotStartTime), new Date(timeSeriesPlotEndTime)])
  }, [timeSeriesChartPlotRangeOffset])

  const changeGraphTimeOffset = (value: number) => {
    const newOffsetValue = graphTimeOffset + value
    if (graphTimeOffset >= 0 && newOffsetValue < 0) {
      const prevDate = new Date(graphStartDate)
      prevDate.setDate(graphStartDate.getDate() - 1)
      const shiftedTimeOffset = taskTimeLineRange + newOffsetValue

      setGraphTimeOffset(shiftedTimeOffset)
      setSelectedDate(new DateObject(prevDate).toUTC())
    } else if (newOffsetValue >= taskTimeLineRange) {
      const nextDate = new Date(graphStartDate)
      nextDate.setDate(graphStartDate.getDate() + 1)
      const shiftedTimeOffset = newOffsetValue - taskTimeLineRange

      setGraphTimeOffset(shiftedTimeOffset)
      setSelectedDate(new DateObject(nextDate).toUTC())
    } else {
      setGraphTimeOffset(newOffsetValue)
      const newStart = graphStartDate.getTime() + newOffsetValue
      setGraphTimeRange([newStart, newStart + taskTimeLineRange])

      const timeSeriesPlotStartTime = newStart + timeSeriesChartPlotRangeOffset[0] * 60000
      const timeSeriesPlotEndTime = newStart + timeSeriesChartPlotRangeOffset[1] * 60000
      setSelectedPlotDateRange([timeSeriesPlotStartTime, timeSeriesPlotEndTime])
      // setTimeSeriesChartDateRange([new Date(timeSeriesPlotStartTime), new Date(timeSeriesPlotEndTime)])
    }
  }

  const xAxisMarkerConfiger = {
    fillColor: '#E4F0FF',
    // opacity: 0.02,
    strokeDashArray: 0,
    borderColor: '#0F77F9',
  }

  const defaultAnnotations = {
    xaxis: [
      {
        x: graphTimeRange[0] + timeSeriesChartPlotRangeOffset[0] * 60000,
        x2: graphTimeRange[0] + timeSeriesChartPlotRangeOffset[1] * 60000,
        ...xAxisMarkerConfiger,
      },
    ],
    points: currentPlotData?.points || [],
  }

  const options: ApexOptions = {
    chart: {
      id: CHART_ID,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    annotations: defaultAnnotations,
    plotOptions: {
      bar: {
        barHeight: '26%',
        horizontal: true,
        distributed: true,
        dataLabels: {
          hideOverflowingLabels: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      min: graphTimeRange[0],
      max: graphTimeRange[1],
      position: 'top',
      tickAmount: timeTicksSize, //24
      labels: {
        offsetX: -6,
        hideOverlappingLabels: true,
        style: {
          colors: ['#9A9BA2'],
        },
        formatter: function (val: string, index: number) {
          if (index !== timeTicksSize) {
            // skip the end one because it will be trimmed on UI
            const timeLabel = timeConvert({
              time: +val,
              type: TimeConvertType.localizedUtcCustomFormat,
              arg: 'H:mm',
            }) as string
            return timeLabel
          }
          return ''
        },
      },
    },
    yaxis: {
      //we still need show set to true because annotation point markers depend on this setting
      show: true,
      labels: {
        formatter: function () {
          // return empty string to NOT SHOW the labels, instead using our own design
          return ''
        },
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: ['#f3f4f5', '#fff'],
        opacity: 0.5,
      },
      padding: {bottom: taskTimelineChartBottomPadding},
    },
  }

  const convertTimestampListToTimelineElementList = (
    taskType: TimelineElementTaskType,
    timestampList: number[],
  ): TaskTimelineElement[] => {
    const timeLineElementList = []
    for (let i = 0; i < timestampList.length - 1; i += 2) {
      timeLineElementList.push({
        taskType,
        localStartTimestamp: timestampList[i],
        localEndTimestamp: timestampList[i + 1],
      })
    }
    return timeLineElementList
  }

  useEffect(() => {
    // load task timeline data
    for (const yymmddIndex of plotDataYYMMDDIndexes) {
      if (participantId) {
        loadTaskTimeLineData(participantId, yymmddIndex)
      }
    }

    // load dexcom timeline data, timeSeries data included
    if (dexcomIntegrationId && participantId) {
      loadDexcomData(participantId, plotDataYYMMDDIndexes)
    }
  }, [participantId, plotDataYYMMDDIndexes])

  const loadTaskTimeLineData = (participantId: string, yymmddIndex: number) => {
    const currentTaskTimelineData = taskTimelineState[participantId]
    if (currentTaskTimelineData) {
      const taskTimelineDataOnDay = currentTaskTimelineData[yymmddIndex]?.commonTask
      if (taskTimelineDataOnDay !== undefined) {
        // data already exist in state, skip fetch from api
        return
      }
    }
    // console.log('loadTaskTimeLineData')
    doREQUEST_PARTICIPANT_TASK_DATA_TIMELINE_FETCH({
      setRequestId: setRequestTaskTimelinDataId,
      payload: {
        projectId: projectId,
        participantId,
        yymmddIndex,
      },
    })
  }

  const loadDexcomData = (participantId: string, yymmddIndexes: number[]) => {
    const currentTaskTimelineData = taskTimelineState[participantId]
    const yymmddIndexToFetch: number[] = []
    if (currentTaskTimelineData) {
      for (const yymmddIndex of yymmddIndexes) {
        const dexcomDataOnDay = currentTaskTimelineData?.[yymmddIndex]?.dexcomTask
        if (dexcomDataOnDay === undefined) {
          yymmddIndexToFetch.push(yymmddIndex)
        }
      }
    } else {
      yymmddIndexToFetch.push(...yymmddIndexes)
    }

    if (yymmddIndexToFetch.length > 0) {
      const projection = {
        participantId: 1,
        yymmddIndex: 1,
        dataType: 1,
        'egvs.displayTime': 1,
        'egvs.value': 1,
        events: 1,
      }

      doREQUEST_VISUALIZER_DEXCOM_DATA_FETCH({
        setRequestId: setRequestDexcomDataId,
        payload: {
          participantId,
          yymmddIndexes: yymmddIndexToFetch,
          projection,
        },
      })
    }
  }

  const convertTaskTimelineDataToTimelinePlotData = (taskTimelineData: TaskTimelineData) => {
    const timeLineSeries: TimelineSeries = []
    const timelinePoints: TimelinePoints = []
    const taskPointTooltipContentMap: Record<string, {taskName: string; duration: string}> = {}

    const firstTaskId = baseSeries[0]?.x
    if (taskTimelineData.commonTask) {
      for (const [taskId, timelineElement] of Object.entries(taskTimelineData.commonTask)) {
        const taskColor = taskColorMapper[taskId]
        for (const timestamp of timelineElement) {
          const startTimeString = timeConvert({
            time: timestamp.localStartTimestamp,
            type: TimeConvertType.localizedUtcCustomFormat,
            arg: 'H:mm',
          }) as string

          const pointTooltipContent = {
            taskName: mapCommonTaskIdToTaskName(taskId),
            duration: startTimeString,
          }

          if ('localEndTimestamp' in timestamp) {
            // add sereis
            timeLineSeries.push({
              x: shrinkTasks ? firstTaskId : taskId,
              y: [timestamp.localStartTimestamp, timestamp.localEndTimestamp as number],
              fillColor: `${taskColor}77`, //add transparent to task color
            })

            // append endTime to tooltipContent.duration
            const endTimeString = timeConvert({
              time: timestamp.localEndTimestamp!,
              type: TimeConvertType.localizedUtcCustomFormat,
              arg: 'H:mm',
            }) as string

            pointTooltipContent.duration = pointTooltipContent.duration + '-' + endTimeString
          }

          timelinePoints.push({
            taskId,
            y: shrinkTasks ? firstTaskId : taskId,
            x: timestamp.localStartTimestamp,
            marker: {
              show: true,
              size: 7,
              fillColor: taskColor,
              strokeColor: taskColor,
              offsetY: -1,
            },

            mouseLeave: (event: any) => {
              setCurrentTaskPointTimeId(0)
            },

            mouseEnter: (event: any) => {
              setCurrentTaskPointTimeId(timestamp.localStartTimestamp)
            },
          })

          taskPointTooltipContentMap[timestamp.localStartTimestamp] = pointTooltipContent
        }
      }
    }

    return {
      series: timeLineSeries,
      points: timelinePoints,
      taskPointTooltipContentMap,
    }
  }

  const convertTimestampListToTimelinePlotData = (
    taskId: string,
    taskType: TimelineElementTaskType,
    timestampList: number[],
    fillColor: string,
    newStartColor: string,
    startPointIcon: any,
  ) => {
    const timeLineSeries: TimelineSeries = []
    const timelinePoints: TimelinePoints = []
    const timelineElementList = convertTimestampListToTimelineElementList(taskType, timestampList)

    for (const timestamp of timelineElementList) {
      if ('localEndTimestamp' in timestamp) {
        timeLineSeries.push({
          x: taskId,
          y: [timestamp.localStartTimestamp, timestamp.localEndTimestamp as number],
          fillColor: `${fillColor}77`,
          goals: [
            {
              name: 'newStart',
              value: timestamp.localStartTimestamp,
              strokeWidth: 3,
              strokeColor: newStartColor,
            },
          ],
        })
      }
    }

    if (timelineElementList.length > 0) {
      const timestamp = timelineElementList[0]
      if (timestamp.localStartTimestamp >= graphTimeRange[0] && timestamp.localStartTimestamp <= graphTimeRange[1]) {
        timelinePoints.push({
          taskId,
          y: taskId,
          x: timestamp.localStartTimestamp,
          marker: {
            size: 0,
            show: false,
            fillColor: '#FFFFFF',
            strokeColor: '#FFFFFF',
            offsetY: -1,
          },
          image: {
            path: startPointIcon,
            width: 16,
            height: 16,
            offsetX: 0,
            offsetY: -1,
          },
        })
      }
    }

    return {
      series: timeLineSeries,
      points: timelinePoints,
    }
  }

  const getGarminTimelineDelegateType = (graphDataTypeList?: string[]) => {
    if (graphDataTypeList) {
      if (graphDataTypeList.includes(GarminDeviceLogDataType.GarminBBI)) {
        return GarminDeviceLogDataType.GarminBBI
      } else if (graphDataTypeList.includes(GarminDeviceLogDataType.GarminHeartRate)) {
        return GarminDeviceLogDataType.GarminHeartRate
      } else if (graphDataTypeList.includes(GarminDeviceLogDataType.GarminStress)) {
        return GarminDeviceLogDataType.GarminStress
      } else if (graphDataTypeList.includes(GarminDeviceLogDataType.GarminRespiration)) {
        return GarminDeviceLogDataType.GarminRespiration
      } else if (graphDataTypeList.includes(GarminDeviceLogDataType.GarminActigraphy)) {
        return GarminDeviceLogDataType.GarminActigraphy
      } else if (graphDataTypeList.includes(GarminDeviceLogDataType.GarminPulseOx)) {
        return GarminDeviceLogDataType.GarminPulseOx
      }
    }
    return GarminDeviceLogDataType.GarminStep
  }

  const getGarminConnectTimelineDelegateType = (graphDataTypeList?: VisualizerGraphDataType[]) => {
    // use GarminConnectHeartRate as Garmin Delegate type because it reflact wearing time
    return VisualizerGraphDataType.GarminConnectHeartRate

    // if (graphDataTypeList) {
    //   if (graphDataTypeList.includes(VisualizerGraphDataType.GarminConnectHeartRate)) {
    //     return VisualizerGraphDataType.GarminConnectHeartRate
    //   } else if (graphDataTypeList.includes(VisualizerGraphDataType.GarminConnectStress)) {
    //     return VisualizerGraphDataType.GarminConnectStress
    //   } else if (graphDataTypeList.includes(VisualizerGraphDataType.GarminConnectBodyBattery)) {
    //     return VisualizerGraphDataType.GarminConnectBodyBattery
    //   } else if (graphDataTypeList.includes(VisualizerGraphDataType.GarminConnectSteps)) {
    //     return VisualizerGraphDataType.GarminConnectSteps
    //   }
    // }
    // return
  }

  useEffect(() => {
    if (participantId) {
      const timeLineSeries: TimelineSeries = baseSeries.slice()
      const timelinePoints: TimelinePoints = []
      const taskDataMarkerList: CommonTaskDataMarker[] = []
      let allTaskPointTooltipContentMap: Record<string, {taskName: string; duration: string}> = {}
      let alreadyAddGarminStartPoint = false
      let alreadyAddDexcomStartPoint = false
      let alreadyAddGarminconnectStartPoint = false

      plotDataYYMMDDIndexes.forEach((yymmddIndex) => {
        const taskTimelineData = taskTimelineState[participantId]?.[yymmddIndex]
        if (taskTimelineData) {
          const garminTimelineDelegateType = getGarminTimelineDelegateType(selectedGarminDataTypeList)
          const garminTimelineData = taskTimelineData?.garminTask?.[garminTimelineDelegateType]
          if (garminTaskId && garminTimelineData) {
            const {series, points} = convertTimestampListToTimelinePlotData(
              garminTaskId,
              'garmin_logdata',
              garminTimelineData,
              '#777777',
              'black',
              GarminGIcon,
            )
            timeLineSeries.push(...series)
            if (!alreadyAddGarminStartPoint && points.length > 0) {
              timelinePoints.push(...points)
              alreadyAddGarminStartPoint = true
            }
          }

          const garminConnectTimelineData = taskTimelineData.garminConnect
          if (garminConnectEnabled && garminConnectTimelineData) {
            const garminConnectTimelineDelegateType = getGarminConnectTimelineDelegateType(
              selectedGarminConnectDataTypeList,
            )
            if (garminConnectTimelineDelegateType) {
              const garminConnectTimeLine = garminConnectTimelineData[garminConnectTimelineDelegateType]
              if (garminConnectTimeLine) {
                const {series, points} = convertTimestampListToTimelinePlotData(
                  GarminConnectTimeLineId,
                  'garmin_connect',
                  garminConnectTimeLine,
                  '#137EFF',
                  'black',
                  GarminConnectIcon,
                )
                timeLineSeries.push(...series)
                if (!alreadyAddGarminconnectStartPoint && points.length > 0) {
                  timelinePoints.push(...points)
                  alreadyAddGarminconnectStartPoint = true
                }
              }
            }
          }

          const dexcomTimelineData = taskTimelineData.dexcomTask
          if (dexcomIntegrationId && dexcomTimelineData) {
            const {series, points} = convertTimestampListToTimelinePlotData(
              dexcomIntegrationId,
              'dexcom_logdata',
              dexcomTimelineData,
              color.dexcomGreen,
              'black',
              DexcomIcon,
            )
            timeLineSeries.push(...series)
            if (!alreadyAddDexcomStartPoint && points.length > 0) {
              timelinePoints.push(...points)
              alreadyAddDexcomStartPoint = true
            }
          }

          const {series, points, taskPointTooltipContentMap} =
            convertTaskTimelineDataToTimelinePlotData(taskTimelineData)
          timeLineSeries.push(...series)
          timelinePoints.push(...points)
          allTaskPointTooltipContentMap = {...allTaskPointTooltipContentMap, ...taskPointTooltipContentMap}

          for (const point of points) {
            taskDataMarkerList.push({
              type: 'line',
              timestamp: [Math.floor(point.x / 1000)],
              color: point.marker?.fillColor ?? '#FF0000',
              text: mapCommonTaskIdToTaskName(point.taskId),
            })
          }
          for (const seriesData of series) {
            taskDataMarkerList.push({
              type: 'region',
              timestamp: [Math.floor(seriesData.y[0] / 1000), Math.floor(seriesData.y[1] / 1000)],
              color: seriesData.fillColor ?? '#B7B7B7',
            })
          }
        }
      })

      taskPointTooltipContentRef.current = allTaskPointTooltipContentMap
      // console.log(timeLineSeries)
      // console.log(timelinePoints)

      // setSeriesUpdateTime(new Date().getTime())
      setApexTimeLineChartHeight(getApexTimeLineChartHeightBaseOnTaskCount())
      setCurrentPlotData({
        series: timeLineSeries,
        points: timelinePoints,
      })

      setCommonTaskDataMarkerList(taskDataMarkerList)
    }
  }, [taskTimelineState, participantId, plotDataYYMMDDIndexes, selectedGarminDataTypeList, baseSeries])

  const taskLabelWidth = 150

  const graphTimeShiftButton = (text: string, shiftHours: number) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const tooltipMessage = shiftHours > 0 ? `next ${shiftHours} hours` : `previous ${-shiftHours} hours`

    return (
      <div
        css={{
          width: '22px',
          height: '22px',
          margin: '1px',
          position: 'relative',
        }}
      >
        <button
          onClick={() => changeGraphTimeOffset(shiftHours * 3600000)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          css={{
            width: '100%',
            height: '100%',
            borderRadius: '5px',
            backgroundColor: 'transparent',
            border: `1px solid #9A9BA2`,
            cursor: 'pointer',
            textAlign: 'center',
            color: '#9A9BA2',
            fontSize: 14,
          }}
        >
          {text}
        </button>
        {RIF(
          showTooltip,
          <div
            css={{
              zIndex: 100,
              width: 'max-content',
              background: 'rgba(0, 0, 0, 0.88)',
              borderRadius: '5px',
              color: color.white,
              fontSize: '14px',
              padding: '10px 8px',
              textAlign: 'center',
              position: 'absolute',
              top: '30px',
              bottom: 'auto',
              wordWrap: 'break-word',
              webkitHyphens: 'auto',
              mozHyphens: 'auto',
              msHyphens: 'auto',
              hyphens: 'auto',
              whiteSpace: 'pre-line',
              ':after': {
                position: 'absolute',
                top: '-23%',
                bottom: 'auto',
                right: '85%',
                content: '""',
                borderTop: 'none',
                borderBottom: '10px solid black',
                borderRight: '5px solid transparent',
                borderLeft: '5px solid transparent',
              },
            }}
          >
            {tooltipMessage}
          </div>,
        )}
      </div>
    )
  }

  const garphOffsetController = (
    <div
      css={{
        width: taskLabelWidth - 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#9A9BA2',
      }}
    >
      {graphTimeShiftButton('<', -3)}
      <div
        css={{
          color: '#9A9BA2',
          margin: '5px',
        }}
      >
        Timeline
      </div>
      {graphTimeShiftButton('>', 3)}
    </div>
  )

  const mapCommonTaskIdToTaskName = (taskId: string): string => {
    const task = method.taskList.filter((item) => item.id === taskId)?.[0]
    if (task) {
      switch (task.type) {
        case TaskType.Todo:
          return task.todo.name
        case TaskType.Timer:
          return task.timer.name
        case TaskType.Questionnaire:
          return task.questionnaire.name
        case TaskType.StopwatchMovesenseStream:
        case TaskType.StopwatchMovesenseLogData:
          return task.stopwatchMovesenseStream.name
        case TaskType.StopwatchGarminStream:
          return task.stopwatchGarminStream.name
      }
    }
    return ''
  }

  const convertTaskLableItemToTaskName = (taskLabel: TaskLabelItem): string => {
    if (taskLabel.type === 'garmin_device') {
      if (selectedGarminDataTypeList.length > 0) {
        const garminTimelineDelegateType = getGarminTimelineDelegateType(selectedGarminDataTypeList)
        const dataType = garminTimelineDelegateType.replace('garmin_', '')
        return `Grmin Device (${dataType})`
      } else {
        return 'Garmin Device'
      }
    } else if (taskLabel.type === 'garmin_connect') {
      return 'Garmin Connect'
    } else if (taskLabel.type === 'dexcom') {
      return 'Dexcom'
    } else {
      return mapCommonTaskIdToTaskName(taskLabel.id)
    }
  }

  const coloredCircle = (size: number, color: string) => {
    return (
      <div
        css={{
          display: 'flex',
          width: size,
          height: size,
          borderRadius: '50%',
          backgroundColor: color,
        }}
      ></div>
    )
  }

  const taskIcon = (task: TaskLabelItem) => {
    if (task.type === TaskType.GarminDevice) {
      return (
        <div>
          <img src={GarminGIcon} width='11' />
        </div>
      )
    } else if (task.type === ThirdPartyTaskType.GarminConnect) {
      return (
        <div>
          <img src={GarminConnectIcon} width='11' />
        </div>
      )
    } else if (task.type === ThirdPartyTaskType.Dexcom) {
      return (
        <div>
          <img src={DexcomIcon} width='11' />
        </div>
      )
    } else {
      return coloredCircle(11, task.color)
    }
  }

  const taskListLabel = (
    <>
      <div css={{textAlign: 'left'}}>
        {/* {taskLabelList.map((item, index) => ( */}
        {currentTaskLabelList.map((item, index) => (
          <div
            key={item.id}
            css={{
              border: `1px solid #EBEBEC`,
              background: index % 2 === 0 ? '#F7F8F8' : '#FFFFFF',
              height: `${taskRowHeight}px`,
              width: `${taskLabelWidth}px`,
              paddingLeft: '10px',
              display: 'flex',
              alignItems: 'center',
              gap: 5,
            }}
          >
            {taskIcon(item)}
            <div
              css={{
                width: '100%',
                font: 'DM Sans',
                fontSize: '12px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {RIF(shrinkTasks && index == 0, 'All tasks')}
              {RIF(!shrinkTasks || (shrinkTasks && index != 0), convertTaskLableItemToTaskName(item))}
            </div>
          </div>
        ))}
      </div>
    </>
  )

  const shrinkTaskButton = (
    <button
      onClick={() => setShrinkTasks(!shrinkTasks)}
      css={{
        width: 'max-content',
        height: '22px',
        borderRadius: '5px',
        backgroundColor: 'transparent',
        border: `1px #FFFFFF`,
        cursor: 'pointer',
        justifyContent: 'center',
        background: 'white',
        display: 'flex',
      }}
    >
      <img
        css={{
          margin: '5px',
        }}
        src={shrinkTasks ? ChevronDownBlueIcon : ChevronUpBlueIcon}
        width={14}
        height={14}
      />
      <div
        css={{
          width: 'max-content',
          height: '22px',
          cursor: 'pointer',
          marginTop: '3px',
          marginLeft: '10px',
          color: '#3455F4',
        }}
      >
        {shrinkTasks ? 'Expand' : 'Collapse'}
      </div>
    </button>
  )

  const timeSeriesDataRangeSlider = (
    <div>
      <tc-range-slider
        slider-width='100%'
        slider-height='0.9rem'
        pointer1-width='14px'
        pointer1-height='20px'
        pointer1-radius='5px'
        pointer1-bg='#3455F4'
        pointer1-bg-hover='#3455F4'
        pointer1-bg-focus='#3455F4'
        pointer1-border='#005CCD'
        pointer2-width='14px'
        pointer2-height='20px'
        pointer2-radius='5px'
        pointer2-bg='#3455F4'
        pointer2-bg-hover='#3455F4'
        pointer2-bg-focus='#3455F4'
        pointer2-border='#005CCD'
        slider-bg='#FFFFFF'
        slider-bg-hover='#F0F0F0'
        slider-bg-fill='#E4F0FF'
        keyboard-disabled='true'
        mousewheel-disabled='true'
        moving-tooltip={showMovingTooltip ? 'true' : 'false'}
        moving-tooltip-distance-to-pointer='30'
        moving-tooltip-width='35'
        moving-tooltip-height='30'
        moving-tooltip-bg='#3455F4'
        moving-tooltip-text-color='#efefef'
        ref={timeSeriesRangeSliderRef}
      />
    </div>
  )

  const plotDataSeries = currentPlotData?.series
    ? currentPlotData.series.length > 0
      ? [{data: currentPlotData.series}]
      : [{data: baseSeries}]
    : [{data: baseSeries}]

  const taskTimeLineChart = (
    <div>
      <div
        css={{
          width: `calc(100% - ${taskLabelWidth - 20}px)`,
          height: '100%',
          position: 'absolute',
          left: taskLabelWidth - 27,
        }}
      >
        <ApexChart
          {...{
            options: options,
            series: plotDataSeries,
            type: 'rangeBar',
            height: apexTimeLineChartHeight,
          }}
        />
      </div>
      <div
        css={{
          position: 'absolute',
          top: 7,
        }}
      >
        {garphOffsetController}
      </div>
      <div
        css={{
          position: 'absolute',
          top: 45,
        }}
      >
        {taskListLabel}
      </div>
      {/* mask for task point draw out of chart range */}
      <div
        css={{
          width: '17px',
          height: '100%',
          background: 'white',
          position: 'absolute',
          left: 'calc(100% - 17px)',
        }}
      />
    </div>
  )

  const [showScrollbar, setShowScrollbar] = useState(false)
  const taskTimelineDivRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const childDiv = taskTimelineDivRef.current

    const updateScrollbar = () => {
      if (childDiv && childDiv.parentElement) {
        if (!shrinkTasks) {
          childDiv.scrollTop = 0
        }

        if (taskTimelineMaxHeght) {
          const childHeight = childDiv.clientHeight
          setShowScrollbar(childHeight >= taskTimelineMaxHeght)
        }
      }
    }

    updateScrollbar()

    const observer = new ResizeObserver(updateScrollbar)
    if (childDiv) {
      observer.observe(childDiv)
    }

    return () => {
      observer.disconnect()
    }
  }, [taskTimelineMaxHeght])

  const taskTimelineChartHeight = useMemo<string>(() => {
    if (shrinkTasks) {
      return `${88 + (currentTaskLabelList.length - 1) * taskRowHeight}px`
    }
    const height = apexTimeLineChartHeight
    if (observedDiv.current && taskTimelineMaxHeght) {
      if (height > taskTimelineMaxHeght) {
        return `${taskTimelineMaxHeght}px`
      }
    }
    return `${height}px`
  }, [apexTimeLineChartHeight, taskTimelineMaxHeght, shrinkTasks])

  const sliderSection = (
    <div
      css={{
        width: '100%',
        height: '25px',
        background: 'white',
        transform: shrinkTasks || showScrollbar ? 'translateY(-10px)' : '',
      }}
    >
      <div
        css={{
          position: 'absolute',
        }}
      >
        {shrinkTaskButton}
      </div>

      <div
        css={{
          width: `calc(100% - ${taskLabelWidth - 20}px)`,
          position: 'absolute',
          left: taskLabelWidth - 27,
          paddingTop: 5,
          paddingLeft: 26,
          paddingRight: 10,
        }}
      >
        {timeSeriesDataRangeSlider}
      </div>
    </div>
  )

  return (
    <>
      <div
        ref={observedDiv}
        css={{
          background: 'white',
          width: '100%',
          height: 'max-content',
          position: 'relative',
        }}
        onMouseMove={(event) => {
          if (
            currentTaskPointTimeId !== 0 &&
            mousePositionRef.current &&
            (Math.abs(event.clientX - mousePositionRef.current.x) > 10 ||
              Math.abs(event.clientY - mousePositionRef.current.y) > 10)
          ) {
            setCurrentTaskPointTimeId(0)
          }

          mousePositionRef.current = {x: event.clientX, y: event.clientY}
        }}
      >
        <div
          ref={taskTimelineDivRef}
          css={{
            display: 'flex',
            width: '100%',
            height: taskTimelineChartHeight,
            position: 'relative',
            overflow: showScrollbar ? 'auto' : 'hidden',
          }}
        >
          {taskTimeLineChart}
        </div>

        {sliderSection}
      </div>

      {RIF(
        currentTaskPointTimeId !== 0,
        <div
          css={{
            zIndex: 100,
            width: 'max-content',
            maxWidth: 200,
            height: 50,
            background: 'rgba(0, 0, 0, 0.88)',
            borderRadius: '5px',
            color: color.white,
            fontSize: '14px',
            padding: '10px 8px',
            textAlign: 'center',
            position: 'absolute',
            top: mousePositionRef.current?.y
              ? mousePositionRef.current?.y - bannerHeight + taskTimelineChartBottomPadding
              : 0,
            left: mousePositionRef.current?.x,
            bottom: 'auto',
            transform: 'translateX(-50%)',
            wordWrap: 'break-word',
            webkitHyphens: 'auto',
            mozHyphens: 'auto',
            msHyphens: 'auto',
            hyphens: 'auto',
            whiteSpace: 'pre-wrap',
            ':after': {
              position: 'absolute',
              top: '100%',
              bottom: 'auto',
              left: '50%',
              transform: 'translateX(-50%)',
              content: '""',
              borderTop: '10px solid black',
              borderBottom: 'none',
              borderRight: '5px solid transparent',
              borderLeft: '5px solid transparent',
            },
          }}
        >
          <div
            css={{
              width: '100%',
              font: 'DM Sans',
              fontSize: '12px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: color.white,
            }}
          >
            {currentTaskPointTimeId ? taskPointTooltipContentRef.current?.[currentTaskPointTimeId].taskName : ''}
          </div>
          <div css={{color: color.white}}>
            {currentTaskPointTimeId ? taskPointTooltipContentRef.current?.[currentTaskPointTimeId].duration : ''}
          </div>
        </div>,
      )}
    </>
  )
}
