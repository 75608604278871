import {
  API_URL,
  SagaIterator,
  assertTotalSchema,
  call,
  createDoRequestAction,
  debug,
  put,
  request,
  takeEvery,
  v,
} from '../../lib'
import {
  doBATCH_PARTICIPANT_TAG_LIST_UPDATE,
  doPROJECT_TAG_LIST_SET,
  doREQUEST_COMPLETE,
  doREQUEST_ERROR,
} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PROJECT_TAG_INDEX_ORDER_UPDATE = 'REQUEST_PROJECT_TAG_INDEX_ORDER_UPDATE'
export const doREQUEST_PROJECT_TAG_INDEX_ORDER_UPDATE = createDoRequestAction(REQUEST_PROJECT_TAG_INDEX_ORDER_UPDATE)

export const requestProjectTagIndexOrderUpdateActionCreators = {
  doREQUEST_PROJECT_TAG_INDEX_ORDER_UPDATE,
}

export function* requestProjectTagIndexOrderUpdate({payload}: any): SagaIterator {
  debug('saga*requestProjectTagIndexOrderUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
        tagIds: v.array().items(v.string().uuid().exist()).exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {projectId, tagIds} = payload
  
  const accessToken = yield call(getToken)
  
  const result = yield request({
    method: 'POST',
    url: `${API_URL}/v1/web/project-tag-index-order-update`,
    accessToken,
    data: {
      projectId,
      tagIds,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_TAG_INDEX_ORDER_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-tag-index-order-update',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doPROJECT_TAG_LIST_SET({
      id: projectId,
      tagList: result.payload,
    }),
  )

  yield put(
    doBATCH_PARTICIPANT_TAG_LIST_UPDATE({
      projectId,
      tags: result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* projectTagIndexOrderUpdateSaga() {
  yield takeEvery(REQUEST_PROJECT_TAG_INDEX_ORDER_UPDATE, requestProjectTagIndexOrderUpdate)
}
