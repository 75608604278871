import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {createDispatchActions, selectRequestComplete, selectProjectData} from '../../store'
import {debug, RIF, useCurrentProjectState} from '../../lib'
import {PopupConfirmation, PopupSimple} from '..'
import {ParticipantStateType} from '../../model'

export interface ParticipantDeletePopPropsT {
  closeAction: Dispatch<SetStateAction<boolean>>
  participant?: ParticipantStateType
}

export const ParticipantDeletePop = (props: ParticipantDeletePopPropsT) => {
  const {closeAction, participant} = props

  const { project } = useCurrentProjectState()
  const batchId = project?.batchList?.[0]?.id

  const {doREQUEST_PARTICIPANT_DELETE}: any = createDispatchActions()

  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)  

  const [deleteAction, setDeleteAction] = useState('simple_delete')
  useEffect(() => {
    if (!participant) return
    if (participant.activated) return setDeleteAction('confirm_delete')
  }, [participant])

  const onSubmit = (data: any) => {
    debug('ParticipantPopup.onSubmit', {data})
    doREQUEST_PARTICIPANT_DELETE({
      setRequestId,
      payload: {
        participantId: participant?.id,
        batchId,
      },
    })
  }

  useEffect(() => {
    if (!requestComplete) return
    // don't need close action, because delete form reducer, the row will no longer exist
    // closeAction(false)
  }, [requestComplete])

  return (
    <div
      data-testid='test_delete_participant_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 102,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {RIF(
        deleteAction === 'simple_delete',
        <PopupSimple
          {...props}
          {...{
            method: 'Delete',
            name: `Participant "${participant?.insignia}"`,
            description: 'Are you sure you want to delete this participant?',
            buttonText: 'Delete Participant',
            doAction: onSubmit,
            closeAction,
            buttonColor: 'warning',
          }}
        />,
      )}

      {RIF(
        deleteAction === 'confirm_delete',
        <PopupConfirmation
          {...props}
          {...{
            method: 'Delete',
            name: 'Participant',
            id: participant?.insignia,
            description: 'Delete this participant will delete all of data collected. Are you sure you want to delete?',
            term1:
              'I understand that removing a participant stops their data collection and deletes previously collected data.',
            term2: 'I understand that deleted data cannot be restored.',
            buttonText: 'Delete Participant',
            doAction: onSubmit,
            closeAction,
          }}
        />,
      )}
    </div>
  )
}
