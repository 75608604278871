import {API_URL, v, put, debug, request, takeEvery, createDoRequestAction, assertTotalSchema, call} from '../../lib'

import {SagaIterator} from 'redux-saga'

import {doMETHOD_UPDATE_TASK, doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_TASK_TODO_UPDATE = 'REQUEST_TASK_TODO_UPDATE'
export const doREQUEST_TASK_TODO_UPDATE = createDoRequestAction(REQUEST_TASK_TODO_UPDATE)

export const requestTaskTodoUpdateActionCreators = {
  doREQUEST_TASK_TODO_UPDATE,
}

interface Payload {
  payload: {
    requestId: string
    name: string
    description: string
    scheduleRepeat: boolean
    scheduleDescription: string
    reminderList?: Record<string, string>[]
    taskId: string
    color: string
  }
}

export function* requestTaskTodoUpdate({payload}: Payload): SagaIterator {
  debug('saga*requestTaskTodoUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().optional(),
        name: v.string().optional(),
        description: v.string().optional(),
        scheduleRepeat: v.boolean().optional(),
        scheduleDescription: v.string().optional(),
        reminderList: v.array().optional(),
        taskId: v.string().uuid().exist(),
        color: v.string().optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {name, description, scheduleRepeat, scheduleDescription, reminderList, taskId, color} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/task-todo-update`,
    accessToken,
    data: {
      name,
      description,
      scheduleRepeat,
      scheduleDescription,
      reminderList,
      taskId,
      color,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_TASK_TODO_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/task-todo-update',
        ...result,
        error,
      }),
    )
  }

  delete result.payload.enabled
  yield put(doMETHOD_UPDATE_TASK(result.payload))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* taskTodoUpdateSaga() {
  yield takeEvery(REQUEST_TASK_TODO_UPDATE as any, requestTaskTodoUpdate)
}
