import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doMETHOD_TASK_LIST_UPDATE, doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_METHOD_TASK_INDEX_ORDER_UPDATE = 'REQUEST_METHOD_TASK_INDEX_ORDER_UPDATE'
export const doREQUEST_METHOD_TASK_INDEX_ORDER_UPDATE = createDoRequestAction(REQUEST_METHOD_TASK_INDEX_ORDER_UPDATE)

export const requestMethodTaskIndexOrderUpdateActionCreators = {
  doREQUEST_METHOD_TASK_INDEX_ORDER_UPDATE,
}

interface Payload {
  payload: {
    requestId: string
    methodId: string
    taskIdList: string[]
  }
}

export function* requestMethodTaskIndexOrderUpdate({payload}: Payload): SagaIterator {
  debug('saga*requestMethodTaskIndexOrderUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        methodId: v.string().uuid().exist(),
        taskIdList: v.array().items(v.string().uuid()),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {methodId, taskIdList} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/method-task-index-order-update`,
    accessToken,
    data: {
      methodId,
      taskIdList,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_METHOD_TASK_INDEX_ORDER_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/method-task-index-order-update',
        ...result,
        error,
      }),
    )
  }

  // yield put(doMETHOD_TASK_LIST_UPDATE(result.payload))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* methodTaskIndexOrderUpdateSaga() {
  yield takeEvery(REQUEST_METHOD_TASK_INDEX_ORDER_UPDATE as any, requestMethodTaskIndexOrderUpdate)
}
