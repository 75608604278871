import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doBATCH_UPDATE_PARTICIPANT} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PARTICIPANT_UPDATE = 'REQUEST_PARTICIPANT_UPDATE'
export const doREQUEST_PARTICIPANT_UPDATE = createDoRequestAction(REQUEST_PARTICIPANT_UPDATE)

export const requestParticipantUpdateActionCreators = {
  doREQUEST_PARTICIPANT_UPDATE,
}

export function* requestParticipantUpdate({payload}: any): SagaIterator {
  debug('saga*requestParticipantUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        participantId: v.string().uuid().exist(),
        firstName: v.string().optional().allow('', null),
        lastName: v.string().optional().allow('', null),
        email: v.string().email({tlds: {allow: false}}).optional().allow('', null),
        note: v.string().optional().allow('', null),
        insignia: v.string().optional().allow('', null),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {participantId, firstName, lastName, email, note, insignia} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/participant-update`,
    accessToken,
    data: {
      participantId,
      firstName,
      lastName,
      email,
      note,
      insignia,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/participant-update',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doBATCH_UPDATE_PARTICIPANT({
      batchId: result.payload.batchId,
      participant: result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* participantUpdateSaga() {
  yield takeEvery(REQUEST_PARTICIPANT_UPDATE, requestParticipantUpdate)
}
