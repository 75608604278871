import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'
import {doCOLLABORATOR_LIST_SET, doREQUEST_COMPLETE, doREQUEST_ERROR} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH = 'REQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH'
export const doREQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH = createDoRequestAction(
  REQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH,
)

export const requestWorkspaceCollaboratorListFetchActionCreators = {
  doREQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH,
}

export function* requestWorkspaceCollaboratorListFetch({payload}: any): SagaIterator {
  debug('saga*requestWorkspaceCollaboratorListFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().exist(),
        workspaceId: v.string().uuid().exist(),
        page: v.number().optional(),
        projectId: v.string().uuid().optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {
    workspaceId, 
    page, 
    projectId,
  } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/workspace-collaborator-list-fetch`,
    accessToken,
    data: {
      workspaceId,
      page,
      projectId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/workspace-collaborator-list-fetch',
        ...result,
        error,
      }),
    )
  }
  yield put(doCOLLABORATOR_LIST_SET({
    workspaceId,
    collaboratorList: result.payload,
  }))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* workspaceCollaboratorListFetchSaga() {
  yield takeEvery(REQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH, requestWorkspaceCollaboratorListFetch)
}
