import {
  API_URL,
  SagaIterator,
  assertTotalSchema,
  call,
  createDoRequestAction,
  debug,
  put,
  request,
  takeEvery,
  v,
} from '../../lib'
import {doBATCH_PARTICIPANT_TAG_LIST_SET, doREQUEST_COMPLETE, doREQUEST_ERROR} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PARTICIPANT_TAG_LIST_FETCH = 'REQUEST_PARTICIPANT_TAG_LIST_FETCH'
export const doREQUEST_PARTICIPANT_TAG_LIST_FETCH = createDoRequestAction(REQUEST_PARTICIPANT_TAG_LIST_FETCH)

export const requestParticipantTagListFetchActionCreators = {
  doREQUEST_PARTICIPANT_TAG_LIST_FETCH,
}

export function* requestParticipantTagListFetch({payload}: any): SagaIterator {
  debug('saga*requestParticipantTagListFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        batchId: v.string().uuid().exist(),
        participantId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {participantId, batchId} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'POST',
    url: `${API_URL}/v1/web/participant-tag-list-fetch`,
    accessToken,
    data: {participantId},
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_TAG_LIST_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/participant-tag-list-fetch',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doBATCH_PARTICIPANT_TAG_LIST_SET({
      participantId,
      batchId,
      tags: result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* participantTagListFetchSaga() {
  yield takeEvery(REQUEST_PARTICIPANT_TAG_LIST_FETCH, requestParticipantTagListFetch)
}
