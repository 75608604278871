import {
  useEffect, 
  useState,
} from 'react'
import {
  DragDropContext, 
  Droppable
} from 'react-beautiful-dnd'
import {
  selectTheme, 
  createDispatchActions, 
  selectMethod, 
  selectRequestComplete
} from '../../store'
import {
  v, 
  useForm, 
  RIF,
} from '../../lib'
import {Controller} from 'react-hook-form'
import {TodoBlackIcon, TimerBlackIcon} from '../../asset/image'
import {useNavigate, useParams} from 'react-router-dom'

import {
  Button,
  Input,
  Label,
  ButtonCancel,
  Textarea,
  RadioOption,
  TextLink,
  RadioGroup,
  TaskTag,
  LabelBox,
} from '../atoms'

const schema = v.object({
  name: v.string().max(50).required(),
  description: v.string().max(250).optional(),
  scheduleRepeat: v.any().required(),
  scheduleDescription: v.any().optional(),
})

export const AddTaskSetPopup = (props: any) => {
  const {
    doMETHOD_SET,
    doMETHOD_DELETE_TASK,
    doTASK_SETS_SET,
    doTASK_SETS_DELETE,
    doREQUEST_TASK_SET_CREATE,
    doREQUEST_TASK_SET_ADD_ITEM,
  }: any = createDispatchActions()

  const {color, fontWeight, pad} = selectTheme()
  const [nameLength, setNameLength] = useState(0)
  const [descriptionLength, setDescriptionLength] = useState(0)
  const [addingToTaskSetList, setAddingToTaskSetList] = useState<any[]>([])
  const [removingFromTaskSetList, setRemovingFromTaskSetList] = useState<any[]>([])
  const [requestId, setRequestId] = useState(null)
  const [isCreatingTaskSet, setIsCreatingTaskSet] = useState(false)
  const requestComplete = selectRequestComplete(requestId)

  const methodState = selectMethod()

  const {register, control, handleSubmit, formState, watch, setValue} = useForm({
    schema, 
    mode: 'onChange'
  })

  useEffect(() => {
    setNameLength(watch('name')?.length)
    setDescriptionLength(watch('description')?.length)
  }, [watch()])

  useEffect(() => {
    if (props.isEditing.id) {
      setValue('name', props.isEditing.taskSet.name)
      setValue('scheduleDescription', props.isEditing.scheduleDescription)
      setAddingToTaskSetList([...props.isEditing.taskListaskSet.itemList])
      setRemovingFromTaskSetList([])
    }
  }, [props.isEditing])

  useEffect(() => {
    if (!requestComplete) return
    if (!requestComplete?.success) return console.log(requestComplete?.payload.error.message)
    if (isCreatingTaskSet) addItemToTaskSet()
  }, [requestComplete])

  const onSubmit = (data: any) => {
    if (!props.isEditing.id) {
      createTaskSet(data)
    } else {
      updateTaskSet(data)
    }
  }

  const onError = (error: any) => {
    console.log('error', error)
  }

  const closePopup = () => {
    if (props.closePopup) props.closePopup()
  }

  // TODO @Will replace the doTASK_SETS_DELETE reducer with DELETE_TASK_SET saga
  const deleteTaskSet = () => {
    doTASK_SETS_DELETE({id: props.isEditing.id})
    doMETHOD_DELETE_TASK({id: props.isEditing.id})
    closePopup()
  }

  const addItemToTaskSet = () => {
    setIsCreatingTaskSet(false)
    const taskSetId = methodState.taskList[methodState.taskList.length - 1]
    addingToTaskSetList.map((taskId) => {
      doREQUEST_TASK_SET_ADD_ITEM({
        setRequestId,
        payload: {
          taskSetId: taskSetId,
          taskSetItemId: taskId,
        },
      })
    })
  }

  const createTaskSet = (data: any) => {
    setIsCreatingTaskSet(true)
    doREQUEST_TASK_SET_CREATE({
      setRequestId,
      payload: {
        name: data.name,
        methodId: props.isEditing.methodId,
        scheduleRepeat: data.scheduleRepeat,
        scheduleDescription: data.scheduleDescription,
        description: data.description,
      },
    })

    closePopup()
  }

  const updateTaskSet = (data: any) => {
    const newTaskSetState = {...props.isEditing}
    newTaskSetState.scheduleRepeat = data.scheduleRepeat
    newTaskSetState.scheduleDescription = data.scheduleDescription
    newTaskSetState.taskSet.name = data.name
    newTaskSetState.taskSet.description = data.description
    doTASK_SETS_SET({...newTaskSetState})

    const newMethodState = {...methodState}
    newMethodState.taskList = newMethodState.taskList.filter((taskId) => {
      return !newTaskSetState.taskList.includes(taskId)
    })
    newMethodState.taskList = [...newMethodState.taskList, ...removingFromTaskSetList]
    doMETHOD_SET({...newMethodState})

    closePopup()
  }

  const onDragEnd = (result: any) => {
    const {destination, source, draggableId} = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    if (destination.droppableId !== source.droppableId) {
      if (destination.droppableId === 'inTaskSet') {
        moveToTaskSet(draggableId)
      } else if (destination.droppableId === 'outOfTaskSet') {
        removeFromTaskSet(draggableId)
      }
    }
  }

  const moveToTaskSet = (taskId: string) => {
    const newAddingToTaskSetList = [...addingToTaskSetList]
    newAddingToTaskSetList.push(taskId)
    setAddingToTaskSetList([...newAddingToTaskSetList])

    const newRemovingFromTaskSetList = [...removingFromTaskSetList].filter(
      (tid) => {
        return tid !== taskId
      }
    )
    setRemovingFromTaskSetList([...newRemovingFromTaskSetList])
  }

  const removeFromTaskSet = (taskId: string) => {
    const newAddingToTaskSetList = [...addingToTaskSetList].filter(
      (tid) => {
        return tid !== taskId
      }
    )
    setAddingToTaskSetList([...newAddingToTaskSetList])

    const newRemovingFromTaskSetList: string[] = [...removingFromTaskSetList]
    newRemovingFromTaskSetList.push(taskId)
    setRemovingFromTaskSetList([...newRemovingFromTaskSetList])
  }

  return (
    <div></div>
    // <>
    //   {/* page container */}
    //   <div
    //     css={{
    //       position: 'fixed',
    //       top: '0',
    //       left: '0',
    //       width: '100vw',
    //       height: '100vh',
    //       overflowY: 'scroll',
    //       background: '#00000040',
    //       zIndex: 9999,
    //     }}
    //   >
    //     <main
    //       css={{
    //         margin: '50px auto',
    //         width: '910px',
    //         minHeight: '700px',
    //         background: color.white,
    //         borderRadius: '5px',
    //         borderShadow: '0px 4px 10px 0px #0000001A',
    //         display: 'flex',
    //       }}
    //     >
    //       {/* left */}
    //       <section
    //         css={{
    //           width: '30%',
    //           minHeight: '100%',
    //           background: color.green,
    //           padding: '50px',
    //           paddingRight: '30px',
    //           position: 'relative',
    //           display: 'flex',
    //           justifyContent: 'space-between',
    //           alignItems: 'center',
    //           flexDirection: 'column',
    //         }}
    //       >
    //         <ButtonCancel onClick={closePopup}/>

    //         {/* left top */}
    //         <div>
    //           <div css={{paddingTop: '30px', display: 'flex', alignItems: 'center'}}>
    //             <img src={TodoBlackIcon} width='20' />
    //             <span css={{fontSize: '20px', fontWeight: fontWeight.bold, marginLeft: '10px'}}>Add Task-Set</span>
    //           </div>

    //           <div
    //             css={{
    //               fontWeight: fontWeight.regular,
    //               marginTop: '20px',
    //             }}
    //           >
    //             A Task-Set groups a set of individual Tasks that should be completed together.
    //             <br />
    //             <br />
    //             Example: A Daily Breathing Exercise Task-Set that consists of a “Complete 5-minute Breathing Exercise”
    //             Task and “Answer Mood Questionnaire” Task
    //           </div>
    //         </div>

    //         {/* left bottom */}
    //         {/* <div css={{
    //           background: color.background,
    //           width: '215px',
    //           height: '150px',
    //           padding: '20px 15px',
    //         }}>
    //           <div>Tips</div>
    //           <div css={{
    //             fontWeight: fontWeight.regular,
    //             fontSize: '12px',
    //             margin: '8px 0 45px 0'}}>
    //             A Task-Set should only include tasks that should be completed together.
    //           </div>
    //           <div css={{color: color.primary}}>Learn More</div>
    //         </div> */}
    //       </section>

    //       {/* Right */}
    //       <form
    //         onSubmit={handleSubmit(onSubmit, onError)}
    //         css={{width: '70%', minHeight: '100%', background: color.background, padding: '80px 50px 30px'}}
    //       >
    //         <LabelBox
    //           {...{
    //             label: 'Name',
    //             required: true,
    //             leftChracters: isNaN(50 - nameLength) ? 50 : 50 - nameLength,
    //           }}
    //         >
    //           <Input
    //             {...register('name', {required: true})}
    //             value={props.isEditing.taskSet?.name}
    //             maxLength='50'
    //             placeholder='Exercise Set'
    //             css={{marginBottom: pad.medium}}
    //           />
    //         </LabelBox>

    //         <div css={{marginTop: '20px'}}>
    //           <LabelBox
    //             {...{
    //               label: 'Description',
    //               leftChracters: isNaN(250 - descriptionLength) ? 250 : 250 - descriptionLength,
    //             }}
    //           >
    //             <Controller
    //               control={control}
    //               name='description'
    //               defaultValue={props.isEditing.taskSet?.description}
    //               render={({field: {name, value, onChange}}) => (
    //                 <Textarea
    //                   {...{
    //                     name: name,
    //                     value: value,
    //                     onChange: onChange,
    //                   }}
    //                   placeholder='Complete the following list of exercises.'
    //                   rows='6'
    //                   maxLength='250'
    //                   css={{marginBottom: pad.medium}}
    //                 />
    //               )}
    //             />
    //           </LabelBox>
    //         </div>

    //         <div css={{marginTop: '20px'}}>
    //           <Controller
    //             control={control}
    //             name='scheduleRepeat'
    //             defaultValue={props.isEditing?.scheduleRepeat}
    //             render={({field: {name, value, onChange}}) => (
    //               <RadioGroup
    //                 {...{
    //                   name: name,
    //                   value: value,
    //                   onChange: onChange,
    //                 }}
    //               >
    //                 <Label>Schedule</Label>
    //                 <RadioOption defaultChecked={props.isEditing?.scheduleRepeat} value={false} label='One Time' />
    //                 <div css={{color: color.grey_400, margin: '5px 0 0 30px'}}>
    //                   This task is done once throughout the project.
    //                 </div>
    //                 {RIF(
    //                   !watch('scheduleRepeat'),
    //                   <Input
    //                     {...register('scheduleDescription')}
    //                     placeholder='Write the date when this task-set should be completed.'
    //                     css={{margin: '10px 0 0px 30px', width: 'calc(100% - 30px)'}}
    //                   />,
    //                 )}
    //                 <div css={{marginTop: pad.xl}}>
    //                   <RadioOption defaultChecked={props.isEditing?.scheduleRepeat} value={true} label='Repeated' />
    //                   <div css={{color: color.grey_400, margin: '5px 0 0 30px'}}>
    //                     This task is done again and again throughout the project.
    //                   </div>
    //                   {RIF(
    //                     watch('scheduleRepeat'),
    //                     <Input
    //                       {...register('scheduleDescription')}
    //                       placeholder='Hint text'
    //                       css={{margin: '10px 0 0 30px', width: 'calc(100% - 30px)'}}
    //                     />,
    //                   )}
    //                 </div>
    //               </RadioGroup>
    //             )}
    //           />
    //         </div>
    //         <DragDropContext onDragEnd={onDragEnd}>
    //           {/* task-set */}
    //           <section
    //             css={{
    //               borderTop: `1px solid ${color.grey_160}`,
    //               marginTop: '40px',
    //               paddingTop: '30px',
    //             }}
    //           >
    //             <div css={{fontWeight: fontWeight.bold, fontSize: '16px'}}>Tasks in this Task-Set</div>
    //             <div css={{margin: '10px 0'}}>0 task(s) total</div>
    //             <Droppable droppableId='inTaskSet'>
    //               {(provided) => {
    //                 return (
    //                   <div ref={provided.innerRef} {...provided.droppableProps}>
    //                     {RIF(
    //                       !addingToTaskSetList.length,
    //                       <div
    //                         css={{
    //                           marginBottom: pad.medium,
    //                           width: '100%',
    //                           height: '60px',
    //                           border: `1px dotted ${color.grey_400}`,
    //                           borderRadius: '5px',
    //                           display: 'flex',
    //                           alignItems: 'center',
    //                           justifyContent: 'center',
    //                           color: color.grey_400,
    //                         }}
    //                       >
    //                         You don't have any tasks in this task set yet.
    //                       </div>,
    //                     )}
    //                     {addingToTaskSetList.map((taskId, index) => {
    //                       const task = methodState.taskList.find((item) => item.id === taskId)
    //                       if (task) {
    //                         return (
    //                           <TaskTag
    //                             {...{
    //                               key: task.id,
    //                               task,
    //                               index,
    //                               addingToTaskSet: true,
    //                               addedToTaskSet: true,
    //                               removeFromTaskSet: removeFromTaskSet,
    //                             }}
    //                           />
    //                         )
    //                       }
    //                     })}
    //                     {provided.placeholder}
    //                   </div>
    //                 )
    //               }}
    //             </Droppable>
    //             <div css={{margin: '40px 0 15px 0'}}>Please move the task you want to this task-set.</div>
    //             <Droppable droppableId='outOfTaskSet'>
    //               {(provided) => {
    //                 return (
    //                   <div ref={provided.innerRef} {...provided.droppableProps} css={{minHeight: '3.5rem'}}>
    //                     {methodState.taskList.map((taskId, index) => {
    //                       if (!addingToTaskSetList.includes(taskId)) {
    //                         const task = methodState.taskList.find(
    //                           (item) => item.id === taskId
    //                         )
    //                         if (task) {
    //                           return (
    //                             <TaskTag
    //                               {...{
    //                                 index,
    //                                 key: task.id,
    //                                 task,
    //                                 addingToTaskSet: true,
    //                                 moveToTaskSet: moveToTaskSet,
    //                               }}
    //                             />
    //                           )
    //                         }
    //                       }
    //                     })}
    //                     {removingFromTaskSetList.map((taskId, index) => {
    //                       if (!methodState.taskList.includes(taskId) && !addingToTaskSetList.includes(taskId)) {
    //                         const task = methodState.taskList.find((item) => item.id === taskId)
    //                         if (task) {
    //                           return (
    //                             <TaskTag
    //                               {...{
    //                                 index,
    //                                 key: task.id,
    //                                 task,
    //                                 addingToTaskSet: true,
    //                                 moveToTaskSet: moveToTaskSet,
    //                               }}
    //                             />
    //                           )
    //                         }
    //                       }
    //                     })}
    //                     {provided.placeholder}
    //                   </div>
    //                 )
    //               }}
    //             </Droppable>
    //           </section>
    //         </DragDropContext>

    //         <div css={{display: 'flex', alignItems: 'center', justifyContent: 'end', marginTop: '50px'}}>
    //           {RIF(
    //             props.isEditing.id,
    //             <TextLink
    //               type='button'
    //               onClick={deleteTaskSet}
    //               css={{color: color.warning, marginRight: '40px'}}
    //               children='Delete'
    //             />,
    //           )}
    //           {RIF(
    //             !props.isEditing.id,
    //             <TextLink
    //               type='button'
    //               onClick={closePopup}
    //               css={{color: color.warning, marginRight: '40px'}}
    //               children='Discard'
    //             />,
    //           )}
    //           <Button type='submit'>Save</Button>
    //         </div>
    //       </form>
    //     </main>
    //   </div>
    // </>
  )
}
