import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doAUTH_SET, doSETTINGS_SET, doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'

export const REQUEST_IDENTITY_CREATE = 'REQUEST_IDENTITY_CREATE'
export const doREQUEST_IDENTITY_CREATE = createDoRequestAction(REQUEST_IDENTITY_CREATE)

// TODO: auto load doRequest* from saga
export const requestIdentityCreateActionCreators = {
  doREQUEST_IDENTITY_CREATE,
}

export function* requestIdentityCreate({payload}: any): SagaIterator {
  debug('saga*requestIdentityCreate')

  // assert payload makes sence
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        termAgreement: v.boolean().valid(true).exist(),
        email: v.string().exist(),
        password: v.string().min(8).exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  // make request api
  const {email, password} = payload

  // yields a generator that has a promise in its result
  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/identity-create`,
    data: {
      email,
      password,
    },
  })

  // handle error
  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_IDENTITY_CREATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/identity-create',
        ...result,
        error,
      }),
    )
  }

  const {settings, accessToken, refreshToken, accessTokenExpiresInSeconds, refreshTokenExpiresInSeconds} =
    result.payload

  // update state on success
  yield put(
    doAUTH_SET({
      accessToken,
      refreshToken,
      accessTokenExpiresInSeconds,
      refreshTokenExpiresInSeconds,
    }),
  )

  yield put(doSETTINGS_SET(settings))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* identityCreateSaga() {
  yield takeEvery(REQUEST_IDENTITY_CREATE, requestIdentityCreate)
}
