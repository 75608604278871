import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import { 
  doREQUEST_ERROR, 
  doREQUEST_COMPLETE,
  doWORKSPACE_DEXCOM_INTEGRATION_LIST_SET,
} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_DEXCOM_INTEGRATION_LIST_FETCH = 'REQUEST_DEXCOM_INTEGRATION_LIST_FETCH'
export const doREQUEST_DEXCOM_INTEGRATION_LIST_FETCH = createDoRequestAction(REQUEST_DEXCOM_INTEGRATION_LIST_FETCH)

export const requestDexcomIntegrationListFetchActionCreators = {
  doREQUEST_DEXCOM_INTEGRATION_LIST_FETCH,
}

export interface RequestDexcomIntegrationListFetchPayload {
  payload: {
    requestId: string
    workspaceId: string
  }
}

export function* requestDexcomIntegrationListFetch({payload}: RequestDexcomIntegrationListFetchPayload): SagaIterator {
  debug('saga*requestDexcomIntegrationListFetch')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        workspaceId: v.string().uuid().exist(),
      })
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      })
    )
  }

  const {
    workspaceId,
  } = payload

  const accessToken = yield call(getToken)
  
  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/dexcom-integration-list-fetch`,
    accessToken,
    data: {
      workspaceId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_DEXCOM_INTEGRATION_LIST_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/dexcom-integration-list-fetch',
        ...result,
        error,
      })
    )
  }

  yield put(
    doWORKSPACE_DEXCOM_INTEGRATION_LIST_SET({
      workspaceId: payload.workspaceId,
      dexcomIntegrationList: [...result.payload]
    })
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* dexcomIntegrationListFetchSaga() {
  yield takeEvery(REQUEST_DEXCOM_INTEGRATION_LIST_FETCH as any, requestDexcomIntegrationListFetch)
}