import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doMETHOD_ADD_TASK, doTEMP_TASK_DATA_REPLACE_TEMP_TASK} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_CREATE = 'REQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_CREATE'
export const doREQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_CREATE = createDoRequestAction(
  REQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_CREATE,
)

export const requestTaskStopwatchMovesenseStreamCreateActionCreators = {
  doREQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_CREATE,
}

interface Payload {
  payload: {
    requestId: string
    methodId: string
    color: string
    scheduleRepeat: boolean
    scheduleDescription: string
    reminderList?: Record<string, string>[]
    name: string
    description: string
    imuEnable: boolean
    imuHz: number
    accEnable: boolean
    accHz: number
    gyroscopeEnable: boolean
    gyroscopeHz: number
    magnetometerEnable: boolean
    magnetometerHz: number
    ecgEnable: boolean
    ecgHz: number
    rrEnable: boolean
    tempTaskId: string
    timeLimitSeconds: number
  }
}

export function* requestTaskStopwatchMovesenseStreamCreate({payload}: Payload): SagaIterator {
  debug('saga*requestTaskStopwatchMovesenseStreamCreate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().required(),
        methodId: v.string().uuid().required(),
        color: v.string().required(),
        scheduleRepeat: v.boolean(),
        scheduleDescription: v.string().optional(),
        reminderList: v.array().optional(),
        name: v.string().required(),
        description: v.string().optional(),
        imuEnable: v.boolean().required(),
        imuHz: v.number().valid(13, 26, 52, 104, 208).optional().allow(null),
        accEnable: v.boolean().required(),
        accHz: v.number().valid(13, 26, 52, 104, 208).optional().allow(null),
        gyroscopeEnable: v.boolean().required(),
        gyroscopeHz: v.number().valid(13, 26, 52, 104, 208).optional().allow(null),
        magnetometerEnable: v.boolean().required(),
        magnetometerHz: v.number().valid(13, 26, 52, 104, 208).optional().allow(null),
        ecgEnable: v.boolean().required(),
        ecgHz: v.number().valid(125, 128, 200, 250, 256, 500, 512).optional().allow(null),
        rrEnable: v.boolean().required(),
        tempTaskId: v.string().uuid().optional(),
        timeLimitSeconds: v.number().required(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {
    methodId,
    color,
    scheduleRepeat,
    scheduleDescription,
    reminderList,
    name,
    description,
    imuEnable,
    imuHz,
    accEnable,
    accHz,
    gyroscopeEnable,
    gyroscopeHz,
    magnetometerEnable,
    magnetometerHz,
    ecgEnable,
    ecgHz,
    rrEnable,
    tempTaskId,
    timeLimitSeconds,
  } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/task-stopwatch-movesense-stream-create`,
    accessToken,
    data: {
      methodId,
      color,
      scheduleRepeat,
      scheduleDescription,
      reminderList,
      name,
      description,
      imuEnable,
      imuHz,
      accEnable,
      accHz,
      gyroscopeEnable,
      gyroscopeHz,
      magnetometerEnable,
      magnetometerHz,
      ecgEnable,
      ecgHz,
      rrEnable,
      timeLimitSeconds,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_CREATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/task-stopwatch-movesense-stream-create',
        ...result,
        error,
      }),
    )
  }

  if (tempTaskId) {
    yield put(
      doTEMP_TASK_DATA_REPLACE_TEMP_TASK({
        tempTaskId,
        newId: result.payload.id,
      }),
    )
  } else {
    yield put(doMETHOD_ADD_TASK({...result.payload}))
  }

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* taskStopwatchMovesenseStreamCreateSaga() {
  yield takeEvery(REQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_CREATE as any, requestTaskStopwatchMovesenseStreamCreate)
}
