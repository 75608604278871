import {CSSProperties, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  collaboratorTypeHasRole,
  loadSessionStorage,
  RIF,
  sortBy,
  useCollaboratorTypeInProject,
  useCurrentProjectState,
  useCurrentWorkspaceAccountPlan,
  useCurrentWorkspaceState,
  useSearch,
} from '../../lib'
import {ParticipantSelected, ParticipantStateType} from '../../model'
import {selectBatchData, selectProfile, selectTheme, selectWorkspaces} from '../../store'

import {
  Button,
  ButtonReverse,
  CalcSlotsPop,
  DataDownloadPage,
  Input,
  ParticipantNotificationDiscardPop,
  ParticipantNotificationInfoPop,
  ParticipantNotificationPop,
  ParticipantPopup,
  ParticipantsPanelEntry,
  PricingPage,
  ProjectSwitchBar,
  ToolTipHover,
} from '..'

import {
  DoubleRightwardArrowIcon,
  ParticipantTutorialStepOneIcon,
  ParticipantTutorialStepThreeIcon,
  ParticipantTutorialStepTwoIcon,
  ProfileWhiteIcon,
  SearchIcon,
} from '../../asset/image'

import ParticipantsBackground from '../../asset/image/participants_background.png'
import {AttemptRequestParticipantStatus} from './request_project_data_hook'
import {ProjectCollaboratorRole, WorkspaceCollaboratorRole} from '../../shared/db'

export const ParticipantsPage = () => {
  const {color, pad, fontWeight, fontSize} = selectTheme()
  /* ------------------ reducer & store basic state ------------------ */
  const profile = selectProfile()
  const workspacesState = selectWorkspaces()
  const { projectId, project } = useCurrentProjectState()
  const { workspace: currentWorkspace } = useCurrentWorkspaceState()
  const collaboratorType = useCollaboratorTypeInProject()
  const canCreateParticipant = collaboratorTypeHasRole({
    collaboratorType,
    projectRole: ProjectCollaboratorRole.ParticipantCreate,
    workspaceRole: WorkspaceCollaboratorRole.ParticipantCreate
  })
  const canUpdateParticipant = collaboratorTypeHasRole({
    collaboratorType,
    projectRole: ProjectCollaboratorRole.ParticipantCreate,
    workspaceRole: WorkspaceCollaboratorRole.ParticipantCreate
  })
  const batchId: string | undefined = project?.batchList?.[0]?.id
  const navigate = useNavigate()
  const loadSession = loadSessionStorage()

  /* ------------------ participants state ------------------ */
  const participantList: ParticipantStateType[] = selectBatchData()?.[batchId ?? '']?.participantList || []
  const [displayParticipantList, setDisplayParticipantList] = useState<ParticipantStateType[]>([])
  // TODO: also search "note" content
  const {searchValue, searchResult, setSearchValue} = useSearch('insignia', participantList)
  const [displayParticipantPopup, setDisplayParticipantPopup] = useState(false)
  const [tooltipHoverState, setTooltipHoverState] = useState({upgradePlan: false})
  const [renderAddParticipantButtonTooltip, setRenderAddParticipantButtonTooltip] = useState(false)

  const [renderDataDownloadPage, setRenderDataDownloadPage] = useState(false)

  const [selectedList, setSelectedList] = useState<ParticipantSelected[]>([])
  const [isHeaderSelectBtnClicked, setIsHeaderSelectBtnClicked] = useState(false)

  // notification state
  const [notificationList, setNotificationList] = useState<ParticipantSelected[]>([])
  const [notificationInfo, setNotificationInfo] = useState<{[key: string]: any}>({})
  const [displayNotificationPop, setDisplayNotificationPop] = useState(false)
  const [displayNotificationDiscardPop, setDisplayNotificationDiscardPop] = useState(false)
  const [displayNotificationInfoPop, setDisplayNotificationInfoPop] = useState(false)

  // account state
  const accountPlan = useCurrentWorkspaceAccountPlan()

  AttemptRequestParticipantStatus({})

  const [displayCalcSlotsPop, setDisplayCalcSlotsPop] = useState(false)
  const [slotsLimit, setSlotsLimit] = useState(false)
  const [displayChangePlanPage, setDisplayChangePlanPage] = useState(false)

  /* ------------------ default effect ------------------ */
  // TODO: do this action in Navbar
  useEffect(() => {
    if (!profile?.id) navigate(`/account_setup?profile_initial_settings_page`)
  }, [])

  useEffect(() => {
    if (loadSession?.totalParticipants >= (currentWorkspace?.participantLimit ?? 0)) return setSlotsLimit(true)
    setSlotsLimit(false)
  }, [loadSession?.totalParticipants, workspacesState])

  // default participant list set
  useEffect(() => {
    const sortedSearchResult = searchResult?.sort(sortBy('createdAt'))
    setDisplayParticipantList(sortedSearchResult)
  }, [searchResult])

  /* ------------------ other effect ------------------ */
  useEffect(() => {
    let tempState = [...selectedList]
    displayParticipantList?.map((item) => {
      if (!item.activated) {
        tempState = tempState.filter((el) => el.participantId !== item.id)
      }
    })
    setNotificationList(tempState)
  }, [selectedList])

  return (
    <div>
      {RIF(
        displayChangePlanPage,
        <PricingPage
          {...{
            setDisplayChangePlanPage,
          }}
        />,
      )}

      <ProjectSwitchBar {...{setRenderDataDownloadPage}} projectPanel='Participants' />

      {RIF(
        displayParticipantPopup && projectId,
        <ParticipantPopup
          {...{
            method: 'Add',
            closeAction: setDisplayParticipantPopup,
            projectId,
            batchId,
            setSearchValue,
          }}
        />,
      )}

      {RIF(displayCalcSlotsPop, <CalcSlotsPop {...{setDisplayCalcSlotsPop}} />)}

      {RIF(
        displayNotificationPop,
        <ParticipantNotificationPop
          {...{
            notificationList,
            displayNotificationDiscardPop,
            setNotificationInfo,
            setDisplayNotificationPop,
            setDisplayNotificationDiscardPop,
            setDisplayNotificationInfoPop,
          }}
        />,
      )}

      {RIF(
        displayNotificationDiscardPop,
        <ParticipantNotificationDiscardPop
          {...{
            setDisplayNotificationDiscardPop,
            setDisplayNotificationPop,
          }}
        />,
      )}

      {RIF(
        displayNotificationInfoPop,
        <ParticipantNotificationInfoPop
          {...{
            notificationList,
            notificationInfo,
            closeAction: setDisplayNotificationInfoPop,
            setSelectedList,
          }}
        />,
      )}

      {RIF(
        renderDataDownloadPage,
        <DataDownloadPage {...{
          closeAction: () => {setRenderDataDownloadPage(false)}
        }}/>
      )}

      {/* page container */}
      <div
        style={{
          width: '100%',
          minHeight: 'calc(100vh - 106px)',
          backgroundColor: color.background,
          padding: '30px 30px 60px',
        }}
      >
        <div
          style={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <p style={{fontWeight: fontWeight.bold, fontSize: '20px'}}>Participants</p>
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{marginRight: pad.large}}>
              <span data-testid='participant_amount'>{currentWorkspace?.totalParticipants ?? 0}</span>/
              {currentWorkspace?.participantLimit} participants added
            </div>
            {RIF(
              !slotsLimit,
              <Button
                onClick={() => {
                  setDisplayParticipantPopup(true)
                }}
                css={{position: 'relative'}}
                btnPadding='medium'
                disabled={!canCreateParticipant}
                onMouseOver={() => setRenderAddParticipantButtonTooltip(true)}
                onMouseLeave={() => setRenderAddParticipantButtonTooltip(false)}
              >
                <>
                  <img src={ProfileWhiteIcon} width='12' style={{marginRight: '7px'}} />
                  Add Participant
                  {RIF(
                    renderAddParticipantButtonTooltip && !canCreateParticipant,
                    <ToolTipHover
                      {...{
                        tooltipWidth: '150px',
                        title: 'Viewers cannot add participants. If you want to add participants, please ask an admin to upgrade your role for this project.'
                      }}
                    />
                  )}
                </>
              </Button>,
            )}
            {RIF(
              slotsLimit,
              <div
                onMouseOver={() => setTooltipHoverState((prev) => ({...prev, upgradePlan: true}))}
                onMouseOut={() => setTooltipHoverState((prev) => ({...prev, upgradePlan: false}))}
              >
                <Button
                  css={{position: 'relative'}}
                  onClick={() => {
                    if (accountPlan !== 'Advanced') {
                      setDisplayChangePlanPage(true)
                    } else {
                      setDisplayCalcSlotsPop(true)
                    }
                  }}
                  btnColor='upgrade'
                  btnPadding='medium'
                >
                  <>
                    <img src={ProfileWhiteIcon} width='12' style={{marginRight: '7px'}} />
                    Add Participant
                    {RIF(
                      tooltipHoverState.upgradePlan,
                      <ToolTipHover
                        {...{
                          tooltipWidth: '150px',
                          title:
                            accountPlan === 'Advanced'
                              ? `Advanced plans have a 20 participant limit throughout all projects.   

                        To add additional participants please buy more participant slots.`
                              : accountPlan === 'Basic'
                              ? `Basic plans have a 20 participant limit. Please upgrade to add more participants.`
                              : `Free plans have a 5 participant limit. Please upgrade to add more participants.`,
                        }}
                      />,
                    )}
                  </>
                </Button>
              </div>,
            )}
          </div>
        </div>

        {/* participant tutorial */}
        {RIF(
          !!participantList?.length,
          <>
            <div
              style={{
                width: '100%',
                padding: '24px 24px 32px 32px',
                borderRadius: '5px',
                backgroundColor: 'rgba(154, 171, 255, 0.16)',
                position: 'relative',
                marginBottom: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  width: '6px',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: color.primary,
                }}
              />
              <div>
                <p
                  style={{
                    fontWeight: fontWeight.thick,
                    fontSize: fontSize.h5,
                    marginBottom: '8px',
                  }}
                >
                  Participant Setup Guide
                </p>
                <p
                  style={{
                    fontWeight: fontWeight.medium,
                    width: '264px',
                  }}
                >
                  Ask your participants to follow the steps below to join your project:
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <img style={{marginRight: '16px'}} width='70' src={ParticipantTutorialStepOneIcon} />
                <div>
                  <p
                    style={{
                      fontWeight: fontWeight.thick,
                      color: color.grey_600,
                      marginBottom: '8px',
                    }}
                  >
                    Step 1
                  </p>
                  <p
                    style={{
                      width: '11.429rem',
                      fontWeight: fontWeight.medium,
                    }}
                  >
                    Download the Labfront Companion app{' '}
                    <a
                      href={'https://www.labfront.com/download-app'}
                      target='_blank'
                      style={{
                        color: color.primary,
                        fontWeight: fontWeight.thick,
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      here
                    </a>
                  </p>
                </div>
              </div>
              <img width='24' src={DoubleRightwardArrowIcon} />
              <div
                style={{
                  display: 'flex',
                }}
              >
                <img style={{marginRight: '16px'}} width='70' src={ParticipantTutorialStepTwoIcon} />
                <div>
                  <p
                    style={{
                      fontWeight: fontWeight.thick,
                      color: color.grey_600,
                      marginBottom: '8px',
                    }}
                  >
                    Step 2
                  </p>
                  <p
                    style={{
                      width: '11.429rem',
                      fontWeight: fontWeight.medium,
                    }}
                  >
                    Enter the 6 character invite code into Labfront app
                  </p>
                </div>
              </div>
              <img width='24' src={DoubleRightwardArrowIcon} />
              <div
                style={{
                  display: 'flex',
                }}
              >
                <img style={{marginRight: '16px'}} width='70' src={ParticipantTutorialStepThreeIcon} />
                <div>
                  <p
                    style={{
                      fontWeight: fontWeight.thick,
                      color: color.grey_600,
                      marginBottom: '8px',
                    }}
                  >
                    Step 3
                  </p>
                  <p
                    style={{
                      width: '11.429rem',
                      fontWeight: fontWeight.medium,
                    }}
                  >
                    Follow the instructions to complete setup
                  </p>
                </div>
              </div>
            </div>
            <p
              style={{
                color: color.grey_600,
                fontWeight: fontWeight.medium,
                marginBottom: '53px',
              }}
            >
              Note: We recommend you try this yourself before inviting participants.
            </p>
          </>,
        )}

        {RIF(
          participantList?.length === 0,
          <div
            style={{
              width: '100%',
              height: 'calc(100vh - 200px)',
              display: 'flex',
              border: `1px dotted ${color.disabled}`,
              paddingTop: '170px',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img src={ParticipantsBackground} width='25%' />
            <div
              style={{
                fontSize: '18px',
                fontWeight: fontWeight.medium,
                marginTop: '25px',
              }}
            >
              No participants yet
            </div>
            <div
              style={{
                fontSize: '16px',
                fontWeight: fontWeight.medium,
                color: color.grey_400,
                marginTop: '12px',
              }}
            >
              Add a participant to start collecting data.
            </div>
          </div>,
        )}

        {RIF(
          participantList?.length > 0,
          <>
            {/* search field */}
            <div style={{marginBottom: pad.small, display: 'flex', alignItems: 'center'}}>
              <p style={{marginRight: pad.small}}>{participantList?.length} Participant(s)</p>
              <div
                style={
                  {
                    marginLeft: pad.medium,
                    width: '200px',
                    height: '38px',
                    border: `1px solid ${color.grey_160}`,
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    background: color.white,
                    justifyContent: 'space-between',
                    ':hover': {
                      border: `1px solid ${color.grey_400}`,
                      cursor: 'pointer',
                    },
                  } as CSSProperties
                }
              >
                <Input
                  value={searchValue}
                  onChange={(e: any) => setSearchValue(e.target.value)}
                  placeholder='Search Participants'
                  style={{
                    border: 'none',
                    background: 'inherit',
                    height: '100%',
                  }}
                />
                <img src={SearchIcon} width='16' style={{margin: '0 15px 0 5px'}} />
              </div>

              {RIF(
                Object.keys(selectedList).length > 0,
                <div
                  style={{marginLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
                >
                  <div style={{marginRight: '8px'}}>{Object.keys(selectedList).length} Selected</div>

                  <ButtonReverse
                    onClick={() => setDisplayNotificationPop(true)}
                    disabled={Object.keys(notificationList)?.length === 0 || !canUpdateParticipant}
                    btnPadding='medium'
                  >
                    <div css={{color: Object.keys(notificationList)?.length === 0 ? color.disabled : color.primary}}>
                      Send notification to active participants ({Object.keys(notificationList).length})
                    </div>
                  </ButtonReverse>
                </div>,
              )}
            </div>

            <main
              style={{
                width: '100%',
                margin: '20px 0',
                background: color.white,
                borderRadius: '5px 5px 0px 0px',
                border: `1px solid ${color.grey_100}`,
              }}
            >
              {/* top bar */}
              {/* <div style={{
                width: '100%',
                height: '52px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: pad.small}}>
                <div style={{
                  width: '80px',
                  height: '32px',
                  borderRadius: '5px',
                  border: `1px solid ${color.grey_400}`,
                  padding: `6px 12px`,
                  fontWeight: fontWeight.bold,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer'}}>
                  <img src={FilterIcon} width='15' height='10' style={{marginRight: '8px'}}/>
                  Filter
                </div>
              </div> */}

              <ParticipantsPanelEntry
                {...{
                  formHeader: true,
                  projectId,
                  isHeaderSelectBtnClicked,
                  setIsHeaderSelectBtnClicked,
                  selectedList,
                  setSelectedList,
                  displayParticipantList,
                }}
              />
              {displayParticipantList.map((participant) => {
                const index = displayParticipantList?.indexOf(participant) + 1
                return (
                  <ParticipantsPanelEntry
                    {...{
                      index,
                      projectId,
                      key: participant?.id,
                      participant,
                      formHeader: false,
                      selectedList,
                      setSelectedList,
                      isHeaderSelectBtnClicked,
                      setDisplayNotificationPop,
                      projectTagList: project?.tagList ?? [],
                    }}
                  />
                )
              })}
            </main>
          </>,
        )}
      </div>
    </div>
  )
}
