import { useEffect, useState, useRef, Dispatch, SetStateAction, } from 'react'
import {
  selectWorkspaces,
  selectProfile,
  selectTheme,
  createDispatchActions,
  selectRequestComplete,
} from '../../store'
import { 
  ToolTipHover,
  PricingPage,
  ProfileSettingsPage,
} from '..'
import { Workspace, AccountPlan } from '../../model'
import {
  RIF,
  _,
  setSessionStorage,
  useClickOutside,
  useCurrentWorkspaceAccountPlan,
} from '../../lib'
import { Link } from 'react-router-dom'
import {
  LabfrontLogo,
  StarIcon,
  GroupIcon,
  CollaborateIcon,
  ProfileImageDefault,
  QuestionMarkBlackIcon,
  ChevronDownIcon,
  StarBlueIcon,
  ThunderIcon,
  LogoutIcon,
  ProfileBlackIcon,
  NewsIcon,
  SmileBlackIcon,
} from '../../asset/image'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { CSSObject } from '@emotion/react'

export const WorkspaceSidebar = () => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {
    doCURRENT_WORKSPACE_SET,
    doREQUEST_WORKSPACE_FAVORITE_LIST_FETCH,
  }: any = createDispatchActions()
  const profile = selectProfile()
  const workspaceState = selectWorkspaces()

  const [expandWorkspaceList, setExpandWorkspaceList] = useState(true)
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)
  const ownWorkspace = _.find(
    Object.values(workspaceState),
    w => w?.ownerProfile?.email === profile?.email
  )
  
  const isValidUUID = (uuid: string) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    return uuidRegex.test(uuid)
  }

  const isAtFavorites = workspaceState?.currentWorkspaceId === 'favorites'
  const isAtSharedWithMe = workspaceState?.currentWorkspaceId === 'sharedWithMe'
  const [profileHovered, setProfileHovered] = useState(false)
  const [showProfileDropdown, setShowProfileDropdown] = useState(false)
  const [showLabfrontResourcesPopup, setShowLabfrontResourcesPopup] = useState(false)
  const [displayChangePlanPage, setDisplayChangePlanPage] = useState(false)
  const [isProfileSettingsPage, setIsProfileSettingsPage] = useState(false)
  const profileRef = useRef<HTMLDivElement>(null)
  const labfrontResourcesRef = useRef<HTMLDivElement>(null)
  useClickOutside(profileRef, () => setShowProfileDropdown(false))
  useClickOutside(labfrontResourcesRef, () => setShowLabfrontResourcesPopup(false))
  const handleClickProfile = () => {
    setProfileHovered(false)
    setShowProfileDropdown(true)
  }
  const accountPlan = useCurrentWorkspaceAccountPlan()

  const handleClickFavorites = () => {
    doCURRENT_WORKSPACE_SET({id: 'favorites'})
    setSessionStorage({currentWorkspaceId: 'favorites'})
  }

  const handleClickSharedWithMe = () => {
    doCURRENT_WORKSPACE_SET({id: 'sharedWithMe'})
    setSessionStorage({currentWorkspaceId: 'sharedWithMe'})
  }

  useEffect(() => {
    doREQUEST_WORKSPACE_FAVORITE_LIST_FETCH({
      setRequestId,
      payload: {}
    })
  }, [])

  // view logic
  // If they have favorite, show favorites tab
  // If have personal project, show personal workspace
  // If they have Shared, and NO personal project, show shared (shared workspace is higher priority than shared project)
  // If you have nothing, show personal workspace (empty)
  // useEffect(() => {
  //   if (Object.keys(workspaceState).length === 2) return
  //   if (!requestComplete) return 
  //   if (!requestComplete.success) return
  //   if (requestComplete.error) return
  //   const listWithoutDeletedProject = Object.values(projectData).filter((item: any) => item.status !== 'deleted')
  //   if (favoriteList.length) {
  //     doCURRENT_WORKSPACE_SET({id: 'favorites'})
  //     setSessionStorage({currentWorkspaceId: 'favorites'})
  //     return
  //   }
  //   const ownProjectList = listWithoutDeletedProject.filter((p: any) => p.workspaceId === ownWorkspace.id)
  //   if (ownProjectList.length) {
  //     doCURRENT_WORKSPACE_SET({id: ownWorkspace.id})
  //     setSessionStorage({currentWorkspaceId: ownWorkspace.id})
  //     return
  //   }
  //   let sharedWorkspace
  //   let sharedProjectWorkspace
  //   for (const wid of Object.keys(workspaceState).filter(key => checkIsValidUUID(key))) {
  //     if (workspaceState.roleInWorkspaces[wid]) {
  //       if (listWithoutDeletedProject.filter((p: any) => p.workspaceId === wid).length) {
  //         if (!sharedWorkspace && workspaceState.roleInWorkspaces[wid] !== 'project_collaborator') {
  //           sharedWorkspace = wid
  //         } else if (!sharedProjectWorkspace && workspaceState.roleInWorkspaces[wid] === 'project_collaborator') {
  //           sharedProjectWorkspace = wid
  //         }
  //       }
  //     }
  //   }
  //   if (sharedWorkspace) {
  //     doCURRENT_WORKSPACE_SET({id: sharedWorkspace})
  //     setSessionStorage({currentWorkspaceId: sharedWorkspace})
  //     return
  //   }
  //   if (sharedProjectWorkspace) {
  //     doCURRENT_WORKSPACE_SET({id: sharedProjectWorkspace})
  //     setSessionStorage({currentWorkspaceId: sharedProjectWorkspace})
  //     return
  //   }
  //   doCURRENT_WORKSPACE_SET({id: ownWorkspace.id})
  //   setSessionStorage({currentWorkspaceId: ownWorkspace.id  })
  // }, [requestComplete])

  return (
    <>
      {RIF(
        isProfileSettingsPage, 
        <ProfileSettingsPage {...{setIsProfileSettingsPage}} />
      )}
      {RIF(
        displayChangePlanPage,
        <PricingPage {...{setDisplayChangePlanPage,}} />
      )}
      <div css={{
        height: '100vh',
        width: '256px',
        position: 'fixed',
        top: 0,
        left: 0,
        padding: '32px 8px 16px',
        zIndex: '100',
        backgroundColor: color.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        {/* top area */}
        <div css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}>
          <div css={{
            width: '100%',
            borderBottom: `1px solid ${color.border._80}`,
            paddingBottom: '24px',
          }}>
            {/* labfront logo */}
            <Link to='/project' css={{
              display: 'flex',
              paddingLeft: '16px',
              marginBottom: '32px',
            }}>
              <img src={LabfrontLogo} height='21' css={{marginRight: '10px'}} />
            </Link>
            {/* favorite button */}
            <button onClick={handleClickFavorites} css={{
              width: '100%',
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              border: 'none',
              backgroundColor: isAtFavorites ? color.surface.blue.light : 'transparent',
              borderRadius: '5px',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: isAtFavorites ? color.surface.blue.light : color.surface.hover.white,
              }
            }}>
              <img width='16' src={isAtFavorites ? StarBlueIcon : StarIcon}/>
              <p css={{
                marginLeft: '8px',
                color: isAtFavorites ? color.primary : color.black,
                fontWeight: isAtFavorites ? fontWeight.thick : fontWeight.medium,
              }}>Favorites</p>
            </button>
            {/* shared with me button */}
            <button onClick={handleClickSharedWithMe} css={{
              width: '100%',
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              border: 'none',
              backgroundColor: isAtSharedWithMe ? color.surface.blue.light : 'transparent',
              borderRadius: '5px',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: isAtSharedWithMe ? color.surface.blue.light : color.surface.hover.white,
              }
            }}>
              <img width='16' src={isAtSharedWithMe ? CollaborateIcon : GroupIcon}/>
              <p css={{
                marginLeft: '8px',
                color: isAtSharedWithMe ? color.primary : color.black,
                fontWeight: isAtSharedWithMe ? fontWeight.thick : fontWeight.medium,
              }}>Shared with me</p>
            </button>
          </div>
          {/* workspace list */}
          <div css={{
            paddingTop: '12px',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}>
            {/* workspaces button */}
            <button onClick={()=>setExpandWorkspaceList(prev => !prev)} css={{
              padding: '4px 8px',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              border: 'none',
              backgroundColor: 'transparent',
              borderRadius: '5px',
              cursor: 'pointer',
              width: '100%',
              ':hover': {
                backgroundColor: color.surface.hover.white,
              }
            }}>
              <p css={{
                fontWeight: fontWeight.thick,
                fontSize: fontSize.h7,
                color: color.textIcon.light,
                marginRight: '4px',
              }}>Workspaces</p>
              <img css={{
                transform: expandWorkspaceList ? 'rotate(0)' : 'rotate(-90deg)',
                transition: 'transform .5s ease-in-out',
              }} width='20' src={ChevronDownIcon}/>
            </button>
            <div css={{
              flex: 1
            }}>
              <Scrollbars
                autoHide
                autoHideTimeout={800}
              >
                <WorkspaceButton workspace={ownWorkspace}/>
                <div css={{
                  maxHeight: expandWorkspaceList ? '9999px' : '0',
                  overflow: 'hidden',
                  width: '100%',
                  transition: 'max-height .5s ease-in-out',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                    {Object.entries(workspaceState)
                      .filter(([key]) => isValidUUID(key))
                      .filter(([key, value]) => 
                        value.id !== ownWorkspace?.id && 
                        workspaceState.roleInWorkspaces[value.id] !== 'project_collaborator'
                      )
                      .map(([key, value]) => 
                        <WorkspaceButton key={key} workspace={value}/>
                      )
                    }
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
        {/* bottom area */}
        <div css={{
          marginTop: '8px',
          width: '100%',
        }}>
          {/* profile button */}
          <div ref={profileRef} css={{
            position: 'relative'
          }}>
            <button
              onClick={handleClickProfile}
              onMouseOver={()=>setProfileHovered(true)}
              onMouseLeave={()=>setProfileHovered(false)}
              css={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                marginBottom: '24px',
                position: 'relative',
                borderRadius: '5px',
                width: '100%',
                ':hover': {
                  backgroundColor: color.surface.hover.white,
                }
              }}
            >
              <div css={{
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                marginRight: '8px',
                backgroundImage: `url(${profile?.imageUrl ?? ProfileImageDefault})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}/>
              <p css={{
                fontWeight: fontWeight.thick,
                fontSize: fontSize.h7,
              }}>{profile?.firstName ?? 'User'}</p>
              {RIF(
                profileHovered,
                // true,
                <ToolTipHover
                  {...{
                    title: profile?.firstName,
                    subtitle: profile?.email,
                    hoverRightPosition: '100%',
                    pseudoRightPosition: '50%',
                    hoverTopPosition: '-150%',
                    pseudoTopPosition: '100%',
                  }}
                />,
              )}
            </button>
            <ProfileDropDown
              {...{
                dropped: showProfileDropdown,
                setIsProfileSettingsPage,
                setDisplayChangePlanPage,
                accountPlan,
              }}
            />
          </div>
          {/* labfront resources button */}
          <div css={{position: 'relative'}} ref={labfrontResourcesRef}>
            {RIF(
              showLabfrontResourcesPopup,
              <div 
                css={{
                  padding: '8px 0',
                  borderRadius: '5px',
                  boxShadow: '0px 4px 10px 1px #68686840',
                  backgroundColor: color.white,
                  position: 'absolute',
                  bottom: '36px',
                  left: '8px',
                }}
              >
                <a href="https://youtu.be/UqBiEBV6KTY?si=xm4w9wk7Fu0vzJU5" target='_blank' css={{
                  width: '100%',
                  padding: '8px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  ':hover': {
                    backgroundColor: color.surface.hover.white,
                  },
                }}>
                  <img src={ProfileBlackIcon} width='20'/>
                  <p css={{marginLeft: '8px'}}>How it works video</p>
                </a>
                {/* <a href="/" target='_blank' css={{
                  width: '100%',
                  padding: '8px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: color.surface.hover.white,
                  },
                }}>
                  <img src={NewsIcon} width='20'/>
                  <p css={{marginLeft: '8px'}}>Sample Project</p>
                </a> */}
                <a href="https://help.labfront.com/getting-started" target='_blank' css={{
                  width: '100%',
                  padding: '8px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  ':hover': {
                    backgroundColor: color.surface.hover.white,
                  },
                }}>
                  <img src={NewsIcon} width='20'/>
                  <p css={{marginLeft: '8px'}}>Getting Started Guide</p>
                </a>
                <a href="https://help.labfront.com/" target='_blank' css={{
                  width: '100%',
                  padding: '8px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  ':hover': {
                    backgroundColor: color.surface.hover.white,
                  },
                }}>
                  <img src={QuestionMarkBlackIcon} width='20'/>
                  <p css={{marginLeft: '8px'}}>Help Center</p>
                </a>
                <a href="https://help.labfront.com/kb-tickets/new" target='_blank' css={{
                  width: '100%',
                  padding: '8px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  ':hover': {
                    backgroundColor: color.surface.hover.white,
                  },
                }}>
                  <img src={SmileBlackIcon} width='20'/>
                  <p css={{marginLeft: '8px'}}>Contact Us</p>
                </a>
              </div>
            )}
            <button onClick={()=>setShowLabfrontResourcesPopup(true)} css={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: 'transparent',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: color.surface.hover.white,
              }
            }}>
              <img src={QuestionMarkBlackIcon} width='20'/>
              <p css={{
                marginLeft: '8px',
                fontWeight: fontWeight.thick,
                fontSize: fontSize.h7,
              }}>Labfront Resources</p>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

interface WorkspaceButtonProps {
  workspace: Workspace | undefined
}

const WorkspaceButton = (props: WorkspaceButtonProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {workspace} = props
  const {doCURRENT_WORKSPACE_SET}: any = createDispatchActions()
  const workspaceState = selectWorkspaces()
  const profile = selectProfile()
  const isOwnWorkspace = workspace?.ownerProfile?.email === profile?.email
  const isCurrentWorkspace = workspace?.id === workspaceState?.currentWorkspaceId 

  const handleClick = () => {
    doCURRENT_WORKSPACE_SET({id: workspace?.id})
    setSessionStorage({currentWorkspaceId: workspace?.id})
  }

  return (
    <>
      {RIF(
        workspace,
        <button onClick={handleClick} css={{
          width: '100%',
          padding: '8px 16px', 
          border: 'none',
          cursor: 'pointer',
          marginBottom: '8px',
          borderRadius: '5px',
          backgroundColor: isCurrentWorkspace ? color.surface.blue.light : 'transparent',
          ':hover': {
            backgroundColor: isCurrentWorkspace ? color.surface.blue.light : color.surface.hover.white,
          }
        }}>
          <p css={{
            color: isCurrentWorkspace ? color.primary : color.black,
            fontWeight: isCurrentWorkspace ? fontWeight.thick : fontWeight.medium,
            marginBottom: '4px',
            textAlign: 'start',
          }}>{workspace?.name}</p>
          <p css={{
            color: color.textIcon.light,
            textAlign: 'start',
            fontSize: fontSize.h7,
          }}>{isOwnWorkspace ? 'Owned by you' : workspace?.ownerProfile?.email}</p>
        </button>
      )}
    </>
  )
}

interface ProfileDropDownProps {
  dropped: boolean
  accountPlan: AccountPlan | undefined
  setDisplayChangePlanPage: Dispatch<SetStateAction<boolean>>
  setIsProfileSettingsPage: Dispatch<SetStateAction<boolean>>
}

const ProfileDropDown = (props: ProfileDropDownProps) => {
  const {color} = selectTheme()
  const {dropped, setIsProfileSettingsPage, setDisplayChangePlanPage, accountPlan} = props
  const handleLogout = () => {
    window.sessionStorage.removeItem('labfront')
    window.localStorage.removeItem('labfront_project_content')
    window.location.reload()
  }

  return (
    <div
      css={{
        minWidth: '160px',
        background: color.white,
        border: `1px solid ${color.grey_160}`,
        borderRadius: '5px',
        position: 'absolute',
        bottom: '48px',
        left: '8px',
        display: `${dropped ? 'flex' : 'none'}`,
        flexDirection: 'column',
        justifyContent: 'space-between',
        cursor: 'auto',
        padding: '8px 0',
        boxShadow: '0px 4px 10px 1px #68686840',
      }}
    >
      <ProfileSelection
        onClick={() => setIsProfileSettingsPage(true)}
        css={{
          marginBottom: '8px',
        }}
        {...{
          img: ProfileBlackIcon,
          text: 'Profile Settings',
        }}
      />
      <ProfileSelection
        onClick={() => setDisplayChangePlanPage(true)}
        css={{
          marginBottom: '8px',
        }}
        {...{
          img: ThunderIcon,
          text: accountPlan === 'Advanced' ? 'View Plan' : 'Upgrade Plan',
        }}
      />
      <div css={{
        width: '100%',
        borderTop: `1px solid ${color.border._160}`,
      }}/>
      <ProfileSelection
        onClick={handleLogout}
        css={{
          marginTop: '8px',
        }}
        {...{
          img: LogoutIcon,
          text: 'Logout',
        }}
      />
    </div>
  )
}

const ProfileSelection = (
  props: {
    img: string
    text: string
    css?: CSSObject
    onClick: () => void
  }
) => {
  const {fontWeight, color} = selectTheme()
  const {img, text} = props
  return (
    <div
      {...props}
      css={{
        cursor: 'pointer',
        display: 'flex',
        padding: '8px 16px',
        alignItems: 'center',
        minWidth: 'fit-content',
        ':hover': {
          background: 'rgba(156, 158, 174, 0.05)',
        },
      }}
    >
      <img src={img} width='20' css={{marginRight: '8px'}} />
      <span
        css={{
          color: color.black,
          cursor: 'pointer',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </span>
    </div>
  )
}